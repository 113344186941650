import type {
    EPlatformRole,
    IElevatorSummary,
    IPlatformStatsAuth,
    IPlatformStatsElevators,
    IPlatformStatsServiceCompanies,
    IPlatformStatsSites,
    IResolvedPermissions,
    IServiceCompanyPendingRecord,
    IServiceCompanySummary,
    ISiteSummary,
    ITeamMembership,
    ITechnicianPendingRecord,
    ITechnicianProfile,
    IUpgradePackage,
    IUpgradePackageSummary,
    IUserMembership,
    IUserSummary
} from '@mcal/core';
import {EEntityType, EUpgradeState, PLATFORM_ID} from '@mcal/core';
import {createSlice} from '@reduxjs/toolkit';
import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {
    getPendingTechniciansAdmin,
    getTechniciansAdmin,
    getUsersAdmin,
    getUsersMemberships,
    listPendingTechniciansRecordsAdmin
} from '../../services/app-node-auth/users/users.thunks.js';
import {listElevators} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {
    cancelInvite,
    sendInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {getMileageDensity} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {
    deletePlatformTeam,
    deprecateUpgradePackage,
    getPlatformAuthStats,
    getPlatformElevatorsStats,
    getPlatformServiceCompaniesStats,
    getPlatformSitesStats,
    getPlatformTeam,
    getUpgradePackageAdmin,
    listUpgrades,
    promoteUpgradePackage,
    unpublishUpgradePackage,
    updatePlatformTeam,
    uploadUpgradePackage
} from '../../services/app-node-platform/platform/platform.thunks.js';
import {
    listPendingServiceCompanies,
    listServiceCompanies,
    listServiceCompaniesAdmin,
    listServiceCompaniesPendingRecords
} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {listSites} from '../../services/app-node-sites/sites/sites.thunks.js';
import {fulfilledRemoteUpdate} from '../../utils/fulfilled-remote-update/fulfilled-remote-update.js';
import {pendingRemoteUpdate} from '../../utils/pending-remote-update/pending-remote-update.js';
import {rejectedRemoteUpdate} from '../../utils/rejected-remote-update/rejected-remote-update.js';
import type {TInvites} from './platform.state.js';
import {initialState} from './platform.state.js';

const platformSlice = createSlice({
    name: 'platform',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // LIST SITES
        builder.addCase(listSites.pending, (state, {meta}) => {
            if (meta.arg.serviceCompanyId !== null) {
                return;
            }
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['sites']
            });
        });
        builder.addCase(listSites.fulfilled, (state, {payload, meta}) => {
            if (meta.arg.serviceCompanyId !== null) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'sites',
                        replacer: (data): ISiteSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listSites.rejected, (state, {meta, payload}) => {
            if (meta.arg.serviceCompanyId !== null) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['sites']
            });
        });

        // LIST SERVICE COMPANIES
        builder.addCase(listServiceCompanies.pending, (state, {meta}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['serviceCompanies']
            });
        });
        builder.addCase(
            listServiceCompanies.fulfilled,
            (state, {payload, meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompanies',
                            replacer: (data): IServiceCompanySummary[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listServiceCompanies.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanies']
                });
            }
        );

        // LIST SERVICE COMPANIES ADMIN
        builder.addCase(listServiceCompaniesAdmin.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['serviceCompanies']
            });
        });
        builder.addCase(
            listServiceCompaniesAdmin.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompanies',
                            replacer: (data): IServiceCompanySummary[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listServiceCompaniesAdmin.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompanies']
                });
            }
        );

        // LIST PENDING SERVICE COMPANIES
        builder.addCase(
            listPendingServiceCompanies.pending,
            (state, {meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['pendingServiceCompanies']
                });
            }
        );
        builder.addCase(
            listPendingServiceCompanies.fulfilled,
            (state, {payload, meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'pendingServiceCompanies',
                            replacer: (data): IServiceCompanySummary[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listPendingServiceCompanies.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['pendingServiceCompanies']
                });
            }
        );

        // LIST SERVICE COMPANIES PENDING RECORDS
        builder.addCase(
            listServiceCompaniesPendingRecords.pending,
            (state, {meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['serviceCompaniesPendingRecords']
                });
            }
        );
        builder.addCase(
            listServiceCompaniesPendingRecords.fulfilled,
            (state, {payload, meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompaniesPendingRecords',
                            replacer: (
                                data
                            ): IServiceCompanyPendingRecord[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listServiceCompaniesPendingRecords.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompaniesPendingRecords']
                });
            }
        );

        builder.addCase(listElevators.pending, (state, {meta}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevators']
            });
        });
        builder.addCase(listElevators.fulfilled, (state, {payload, meta}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'elevators',
                        replacer: (data): IElevatorSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listElevators.rejected, (state, {meta, payload}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['elevators']
            });
        });

        builder.addCase(getMileageDensity.pending, (state, {meta}) => {
            if (meta.arg.scope !== EEntityType.Platform) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['kpis']
            });
        });
        builder.addCase(
            getMileageDensity.fulfilled,
            (state, {payload, meta}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'kpis',
                            mutator: (arg, current): void => {
                                current.mileageDensity = arg;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getMileageDensity.rejected,
            (state, {meta, payload}) => {
                if (meta.arg.scope !== EEntityType.Platform) {
                    return;
                }

                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['kpis']
                });
            }
        );

        // PLATFORM TEAM
        builder.addCase(getPlatformTeam.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['team']
            });
        });
        builder.addCase(getPlatformTeam.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'team',
                        replacer: (
                            data
                        ): ITeamMembership<EEntityType.Platform>[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getPlatformTeam.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['team']
            });
        });

        // USER MEMBERSHIP HANDLING

        builder.addCase(getUsersMemberships.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['memberships']
            });
        });

        builder.addCase(
            getUsersMemberships.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'memberships',
                            replacer: (data): IUserMembership[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getUsersMemberships.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['memberships']
                });
            }
        );

        // HANDLING SEND INVITE
        builder.addCase(sendInvite.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'invites',
                        replacer: (data, current): TInvites => {
                            const now = Date.now();

                            const newInvites: TInvites = data.assignments.map(
                                (assignment) => {
                                    return {
                                        inviteId: '',
                                        userId: null,
                                        userEmail: assignment.userEmail,
                                        role: assignment.role as EPlatformRole,
                                        requestedAt: now
                                    };
                                }
                            );

                            return [...current, ...newInvites];
                        }
                    }
                ]
            });
        });
        builder.addCase(sendInvite.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });
        builder.addCase(sendInvite.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        // HANDLING CANCEL INVITE
        builder.addCase(cancelInvite.pending, (state, {meta}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['invites']
            });
        });
        builder.addCase(cancelInvite.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'invites',
                        replacer: (_, current): TInvites => {
                            return current.filter(
                                (invite) =>
                                    invite.inviteId !== meta.arg.inviteId
                            );
                        }
                    }
                ]
            });
        });
        builder.addCase(cancelInvite.rejected, (state, {meta, payload}) => {
            const isAffected = state.remotes.invites.current.some((invite) => {
                return invite.inviteId === meta.arg.inviteId;
            });

            if (!isAffected) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['invites']
            });
        });

        // UPDATE PLATFORM TEAM
        builder.addCase(updatePlatformTeam.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'team',
                        replacer: (
                            data,
                            current
                        ): ITeamMembership<EEntityType.Platform>[] => {
                            return current.map((member) => {
                                if (member.userId === data.userId) {
                                    return {
                                        ...member,
                                        role: data.role
                                    };
                                } else {
                                    return member;
                                }
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            updatePlatformTeam.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );
        builder.addCase(
            updatePlatformTeam.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        builder.addCase(deletePlatformTeam.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'team',
                        replacer: (
                            data,
                            current
                        ): ITeamMembership<EEntityType.Platform>[] => {
                            return current.filter((member) => {
                                return member.userId !== data.userId;
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            deletePlatformTeam.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );
        builder.addCase(
            deletePlatformTeam.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['team']
                });
            }
        );

        builder.addCase(getUsersAdmin.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['users']
            });
        });
        builder.addCase(getUsersAdmin.fulfilled, (state, {meta, payload}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'users',
                        replacer: (data): IUserSummary[] => {
                            return data.users;
                        }
                    }
                ]
            });
        });
        builder.addCase(getUsersAdmin.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['users']
            });
        });

        // LIST TECHNICIANS
        builder.addCase(getTechniciansAdmin.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['technicians']
            });
        });
        builder.addCase(
            getTechniciansAdmin.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'technicians',
                            replacer: (data): ITechnicianProfile[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getTechniciansAdmin.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['technicians']
                });
            }
        );

        // LIST PENDING TECHNICIANS LIST

        builder.addCase(getPendingTechniciansAdmin.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['pendingTechnicians']
            });
        });

        builder.addCase(
            getPendingTechniciansAdmin.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'pendingTechnicians',
                            replacer: (data): ITechnicianProfile[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getPendingTechniciansAdmin.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['pendingTechnicians']
                });
            }
        );

        // LIST PENDING TECHNICIANS RECORDS
        builder.addCase(
            listPendingTechniciansRecordsAdmin.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['techniciansPendingRecord']
                });
            }
        );
        builder.addCase(
            listPendingTechniciansRecordsAdmin.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'techniciansPendingRecord',
                            replacer: (data): ITechnicianPendingRecord[] => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            listPendingTechniciansRecordsAdmin.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['techniciansPendingRecord']
                });
            }
        );

        // LIST UPGRADES
        builder.addCase(listUpgrades.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['upgrades']
            });
        });
        builder.addCase(listUpgrades.fulfilled, (state, {payload, meta}) => {
            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'upgrades',
                        replacer: (data): IUpgradePackageSummary[] => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(listUpgrades.rejected, (state, {meta, payload}) => {
            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['upgrades']
            });
        });

        // getUpgradePackageAdmin
        builder.addCase(getUpgradePackageAdmin.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['upgrade']
            });
        });
        builder.addCase(
            getUpgradePackageAdmin.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'upgrade',
                            replacer: (data): IUpgradePackage => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getUpgradePackageAdmin.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrade']
                });
            }
        );

        // deprecateUpgradePackage
        builder.addCase(deprecateUpgradePackage.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'upgrades',
                        replacer: (_, current): IUpgradePackageSummary[] => {
                            const upgradePackageIndex = current.findIndex(
                                (upgrade) =>
                                    upgrade.upgradeId === meta.arg.upgradeId
                            );
                            if (upgradePackageIndex) {
                                const newPackageSummaries = [...current];
                                const newUpgradePackage =
                                    current[upgradePackageIndex];
                                newPackageSummaries[upgradePackageIndex] = {
                                    ...newUpgradePackage,
                                    status: EUpgradeState.Deprecated
                                };
                                return newPackageSummaries;
                            }
                            return current;
                        }
                    },
                    {
                        key: 'upgrade',
                        mutator: (_, current): void => {
                            current.status = EUpgradeState.Deprecated;
                        }
                    }
                ]
            });
        });
        builder.addCase(
            deprecateUpgradePackage.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrades', 'upgrade']
                });
            }
        );

        builder.addCase(
            deprecateUpgradePackage.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrades']
                });
            }
        );

        // unpublishUpgradePackage
        builder.addCase(unpublishUpgradePackage.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['upgrades']
            });
        });
        builder.addCase(
            unpublishUpgradePackage.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'upgrades',
                            replacer: (
                                _,
                                current
                            ): IUpgradePackageSummary[] => {
                                return current.filter(
                                    (upgrade) =>
                                        upgrade.upgradeId === meta.arg.upgradeId
                                );
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            unpublishUpgradePackage.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrades']
                });
            }
        );

        // Promote Upgrade Package
        builder.addCase(promoteUpgradePackage.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: [
                    {
                        key: 'upgrade',
                        replacer: (data, current): IUpgradePackage => {
                            if (data.upgradeId === current.upgradeId) {
                                return {
                                    ...current,
                                    ...meta.arg,
                                    status: EUpgradeState.Published
                                };
                            }

                            return current;
                        }
                    },
                    {
                        key: 'upgrades',
                        replacer: (
                            data,
                            currentData
                        ): IUpgradePackageSummary[] => {
                            return currentData.map((upgradeDevice) => {
                                if (
                                    upgradeDevice.upgradeId === data.upgradeId
                                ) {
                                    return {
                                        ...upgradeDevice,
                                        ...meta.arg,
                                        status: EUpgradeState.Published
                                    };
                                }

                                return upgradeDevice;
                            });
                        }
                    }
                ]
            });
        });
        builder.addCase(
            promoteUpgradePackage.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrade', 'upgrades']
                });
            }
        );
        builder.addCase(
            promoteUpgradePackage.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrade', 'upgrades']
                });
            }
        );

        // uploadUpgradePackage
        builder.addCase(uploadUpgradePackage.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['upgrades']
            });
        });
        builder.addCase(
            uploadUpgradePackage.fulfilled,
            (state, {payload, meta}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'upgrades',
                            replacer: (
                                data,
                                current
                            ): IUpgradePackageSummary[] => {
                                return [data, ...current];
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            uploadUpgradePackage.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['upgrades']
                });
            }
        );

        // SERVICE COMPANY STATS
        builder.addCase(
            getPlatformServiceCompaniesStats.pending,
            (state, {meta}) => {
                pendingRemoteUpdate({
                    state,
                    meta,
                    targets: ['serviceCompaniesStats']
                });
            }
        );
        builder.addCase(
            getPlatformServiceCompaniesStats.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'serviceCompaniesStats',
                            replacer: (
                                data
                            ): IPlatformStatsServiceCompanies => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getPlatformServiceCompaniesStats.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['serviceCompaniesStats']
                });
            }
        );

        // SITES STATS
        builder.addCase(getPlatformSitesStats.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['sitesStats']
            });
        });
        builder.addCase(
            getPlatformSitesStats.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'sitesStats',
                            replacer: (data): IPlatformStatsSites => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getPlatformSitesStats.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['sitesStats']
                });
            }
        );

        // ELEVATORS STATS
        builder.addCase(getPlatformElevatorsStats.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['elevatorsStats']
            });
        });
        builder.addCase(
            getPlatformElevatorsStats.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'elevatorsStats',
                            replacer: (data): IPlatformStatsElevators => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getPlatformElevatorsStats.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['elevatorsStats']
                });
            }
        );

        // AUTH STATS
        builder.addCase(getPlatformAuthStats.pending, (state, {meta}) => {
            pendingRemoteUpdate({
                state,
                meta,
                targets: ['authStats']
            });
        });
        builder.addCase(
            getPlatformAuthStats.fulfilled,
            (state, {meta, payload}) => {
                fulfilledRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: [
                        {
                            key: 'authStats',
                            replacer: (data): IPlatformStatsAuth => {
                                return data;
                            }
                        }
                    ]
                });
            }
        );
        builder.addCase(
            getPlatformAuthStats.rejected,
            (state, {meta, payload}) => {
                rejectedRemoteUpdate({
                    state,
                    meta,
                    payload,
                    targets: ['authStats']
                });
            }
        );

        // GET PERMISSIONS
        builder.addCase(getPermissions.pending, (state, {meta}) => {
            if (meta.arg.entityId !== PLATFORM_ID) {
                return;
            }

            pendingRemoteUpdate({
                state,
                meta,
                targets: ['permissions']
            });
        });
        builder.addCase(getPermissions.fulfilled, (state, {payload, meta}) => {
            if (meta.arg.entityId !== PLATFORM_ID) {
                return;
            }

            fulfilledRemoteUpdate({
                state,
                meta,
                payload,
                targets: [
                    {
                        key: 'permissions',
                        replacer: (data): IResolvedPermissions => {
                            return data;
                        }
                    }
                ]
            });
        });
        builder.addCase(getPermissions.rejected, (state, {meta, payload}) => {
            if (meta.arg.entityId !== PLATFORM_ID) {
                return;
            }

            rejectedRemoteUpdate({
                state,
                meta,
                payload,
                targets: ['permissions']
            });
        });
    }
});

export {platformSlice};
