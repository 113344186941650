import type {
    TAPIRequestContent,
    TAPIResponse,
    TAdminServiceCompanyPendingReviewAPI,
    TServiceCompaniesGetConfigurationsTemplateAPI,
    TServiceCompaniesListAPI,
    TServiceCompaniesListConfigurationsTemplateAPI,
    TServiceCompaniesPendingListAPI,
    TServiceCompaniesPendingRecordListAPI,
    TServiceCompaniesRegisterAPI,
    TServiceCompaniesSearchAPI,
    TServiceCompaniesUpdateConfigurationTemplateAPI,
    TServiceCompaniesUploadConfigurationsTemplateAPI,
    TServiceCompaniesUploadDocumentsAPI,
    TServiceCompanyGetAPI,
    TServiceCompanyGetStateAPI,
    TServiceCompanyGetStatsAPI,
    TServiceCompanyTeamDeleteAPI,
    TServiceCompanyTeamGetAPI,
    TServiceCompanyTeamUpdateAPI,
    TServiceCompanyUpdateGeneralSettingsAPI,
    TServiceCompanyUpdateProfileAPI
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const listServiceCompanies = (
    {query}: TAPIRequestContent<TServiceCompaniesListAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesListAPI> => {
    return request<TServiceCompaniesListAPI>({
        method: 'GET',
        url: '/service-companies',
        signal,
        query
    });
};

const listServiceCompaniesAdmin = (
    {query}: TAPIRequestContent<TServiceCompaniesSearchAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesSearchAPI> => {
    return request<TServiceCompaniesSearchAPI>({
        method: 'GET',
        url: '/service-companies/search',
        signal,
        query
    });
};

const listPendingServiceCompanies = (
    {query}: TAPIRequestContent<TServiceCompaniesPendingListAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesPendingListAPI> => {
    return request<TServiceCompaniesPendingListAPI>({
        method: 'GET',
        url: '/service-companies/admin/pending/records/timestamp',
        signal,
        query
    });
};

const listServiceCompaniesPendingRecords = (
    {query}: TAPIRequestContent<TServiceCompaniesPendingRecordListAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesPendingRecordListAPI> => {
    return request<TServiceCompaniesPendingRecordListAPI>({
        method: 'GET',
        url: '/service-companies/admin/pending/records',
        signal,
        query
    });
};

const getServiceCompany = (
    {query}: TAPIRequestContent<TServiceCompanyGetAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyGetAPI> => {
    return request<TServiceCompanyGetAPI>({
        method: 'GET',
        url: '/service-company',
        signal,
        query
    });
};

const getServiceCompanyState = (
    {query}: TAPIRequestContent<TServiceCompanyGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyGetStateAPI> => {
    return request<TServiceCompanyGetStateAPI>({
        method: 'GET',
        url: '/service-company/state',
        signal,
        query
    });
};

const updateServiceCompanyGeneralSettings = (
    {query, body}: TAPIRequestContent<TServiceCompanyUpdateGeneralSettingsAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyUpdateGeneralSettingsAPI> => {
    return request<TServiceCompanyUpdateGeneralSettingsAPI>({
        method: 'PATCH',
        url: '/service-company/general-settings',
        signal,
        query,
        body
    });
};

const updateServiceCompanyProfile = (
    {query, body}: TAPIRequestContent<TServiceCompanyUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyUpdateProfileAPI> => {
    return request<TServiceCompanyUpdateProfileAPI>({
        method: 'PATCH',
        url: '/service-company/profile',
        signal,
        query,
        body
    });
};

const listServiceCompanyConfigurationsTemplates = (
    {query}: TAPIRequestContent<TServiceCompaniesListConfigurationsTemplateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesListConfigurationsTemplateAPI> => {
    return request<TServiceCompaniesListConfigurationsTemplateAPI>({
        method: 'GET',
        url: '/service-companies/configuration-templates',
        signal,
        query
    });
};

const getServiceCompanyTechnicalParametersTemplate = (
    {query}: TAPIRequestContent<TServiceCompaniesGetConfigurationsTemplateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesGetConfigurationsTemplateAPI> => {
    return request<TServiceCompaniesGetConfigurationsTemplateAPI>({
        method: 'GET',
        url: '/service-companies/configuration-template',
        signal,
        query
    });
};

const createServiceCompanyTechnicalParametersTemplates = (
    {
        query,
        body
    }: TAPIRequestContent<TServiceCompaniesUploadConfigurationsTemplateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesUploadConfigurationsTemplateAPI> => {
    return request<TServiceCompaniesUploadConfigurationsTemplateAPI>({
        method: 'PUT',
        url: '/service-companies/configuration-templates',
        signal,
        query,
        body
    });
};

const updateServiceCompanyTechnicalParametersTemplates = (
    {
        query,
        body
    }: TAPIRequestContent<TServiceCompaniesUpdateConfigurationTemplateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesUpdateConfigurationTemplateAPI> => {
    return request<TServiceCompaniesUpdateConfigurationTemplateAPI>({
        method: 'PATCH',
        url: '/service-companies/configuration-template',
        signal,
        query,
        body
    });
};

const getServiceCompanyTeam = (
    {query}: TAPIRequestContent<TServiceCompanyTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyTeamGetAPI> => {
    return request<TServiceCompanyTeamGetAPI>({
        method: 'GET',
        url: '/service-company/team',
        signal,
        query
    });
};

const updateServiceCompanyTeam = (
    {query, body}: TAPIRequestContent<TServiceCompanyTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyTeamUpdateAPI> => {
    return request<TServiceCompanyTeamUpdateAPI>({
        method: 'PATCH',
        url: '/service-company/team',
        signal,
        query,
        body
    });
};

const deleteServiceCompanyTeam = (
    {query, body}: TAPIRequestContent<TServiceCompanyTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyTeamDeleteAPI> => {
    return request<TServiceCompanyTeamDeleteAPI>({
        method: 'DELETE',
        url: '/service-company/team',
        signal,
        query,
        body
    });
};

const registerServiceCompany = (
    {query, body}: TAPIRequestContent<TServiceCompaniesRegisterAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesRegisterAPI> => {
    return request<TServiceCompaniesRegisterAPI>({
        method: 'PUT',
        url: '/service-companies/register',
        signal,
        query,
        body
    });
};

const reviewServiceCompany = (
    {query, body}: TAPIRequestContent<TAdminServiceCompanyPendingReviewAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminServiceCompanyPendingReviewAPI> => {
    return request<TAdminServiceCompanyPendingReviewAPI>({
        method: 'POST',
        url: '/service-company/admin/pending/review',
        signal,
        query,
        body
    });
};

const uploadServiceCompanyDocuments = (
    {query, body}: TAPIRequestContent<TServiceCompaniesUploadDocumentsAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompaniesUploadDocumentsAPI> => {
    return request<TServiceCompaniesUploadDocumentsAPI>({
        method: 'PUT',
        url: '/service-companies/documents',
        signal,
        query,
        body
    });
};

const getStats = (
    {query}: TAPIRequestContent<TServiceCompanyGetStatsAPI>,
    signal: AbortSignal
): TAPIResponse<TServiceCompanyGetStatsAPI> => {
    return request<TServiceCompanyGetStatsAPI>({
        method: 'GET',
        url: '/service-company/stats',
        signal,
        query
    });
};

export {
    createServiceCompanyTechnicalParametersTemplates,
    deleteServiceCompanyTeam,
    getServiceCompany,
    getServiceCompanyState,
    getServiceCompanyTeam,
    getServiceCompanyTechnicalParametersTemplate,
    getStats,
    listPendingServiceCompanies,
    listServiceCompanies,
    listServiceCompaniesAdmin,
    listServiceCompaniesPendingRecords,
    listServiceCompanyConfigurationsTemplates,
    registerServiceCompany,
    reviewServiceCompany,
    updateServiceCompanyGeneralSettings,
    updateServiceCompanyProfile,
    updateServiceCompanyTeam,
    updateServiceCompanyTechnicalParametersTemplates,
    uploadServiceCompanyDocuments
};
