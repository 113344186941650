import type {
    EEntityType,
    IEUDAUpgradePackageDevicePerk,
    IElevatorAnomaly,
    IElevatorDestination,
    IElevatorDevice,
    IElevatorGeneralSettings,
    IElevatorIssue,
    IElevatorProfile,
    IElevatorState,
    IElevatorStop,
    IElevatorSummary,
    IElevatorTrip,
    IEmergency,
    IGetConfigurationTemplate,
    IResolvedPermissions,
    ITeamMembership,
    IUpgradePackageCheck,
    IUserMembership,
    TChannelConfig,
    TDoorCycleDensityKPI,
    TDoorFaultsTotalKPI,
    TEnergyConsumptionDensityKPI,
    TFloorDistributionKPI,
    TMileageDensityKPI,
    TMileageTotalKPI,
    TOutOfServiceTotalKPI,
    TSafetyTestsTotalKPI,
    TSanitizationRunsTotalKPI,
    TStopsDensityKPI,
    TTimeConsumptionDensityKPI,
    TTripsHistoryKPI
} from '@mcal/core';
import {
    EUpgradeStatus,
    defaultElevatorEmergency,
    defaultElevatorGeneralSettings,
    defaultElevatorProfile,
    defaultElevatorState,
    defaultElevatorSummary,
    defaultGetConfigurationTemplate
} from '@mcal/core';
import type {
    ISliceRemote,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TGlobalTeam =
    | ITeamMembership<EEntityType.ServiceCompany>[]
    | ITeamMembership<EEntityType.Site>[];

type TTeam = ITeamMembership<EEntityType.Elevator>[];

interface IElevatorSliceState {
    status: TEntitySliceStatus;
    remotes: {
        elevatorSummary: ISliceRemote<IElevatorSummary>;
        elevatorState: ISliceRemote<IElevatorState>;
        elevatorProfile: ISliceRemote<IElevatorProfile>;
        elevatorGeneralSettings: ISliceRemote<IElevatorGeneralSettings>;
        elevatorDevices: ISliceRemote<IElevatorDevice[]>;
        configurationTemplates: ISliceRemote<IGetConfigurationTemplate>;
        globalTeams: ISliceRemote<Record<string, TGlobalTeam>>;
        globalMemberships: ISliceRemote<Record<string, IUserMembership[]>>;
        memberships: ISliceRemote<Record<string, IUserMembership[]>>;
        teams: ISliceRemote<Record<string, TTeam>>;
        permissions: ISliceRemote<IResolvedPermissions>;
        emergencies: ISliceRemote<IEmergency>;
        upgrade: ISliceRemote<IUpgradePackageCheck>;
        destinations: ISliceRemote<IElevatorDestination[]>;
        kpis: ISliceRemote<{
            mileageTotal: TMileageTotalKPI | null;
            mileageDensity: TMileageDensityKPI | null;
            floorDistribution: TFloorDistributionKPI | null;
            outOfServiceTotal: TOutOfServiceTotalKPI | null;
            doorFaultsTotal: TDoorFaultsTotalKPI | null;
            safetyTestsTotal: TSafetyTestsTotalKPI | null;
            sanitizationRunsTotal: TSanitizationRunsTotalKPI | null;
            tripHistory: TTripsHistoryKPI | null;
            stopsDensity: TStopsDensityKPI | null;
            energyConsumptionDensity: TEnergyConsumptionDensityKPI | null;
            timeConsumptionDensity: TTimeConsumptionDensityKPI | null;
            doorCyclesDensity: TDoorCycleDensityKPI | null;
        }>;
        issues: ISliceRemote<IElevatorIssue[]>;
        anomalies: ISliceRemote<IElevatorAnomaly[]>;
        trips: ISliceRemote<IElevatorTrip[]>;
        stops: ISliceRemote<IElevatorStop[]>;
        currentDestination: ISliceRemote<IElevatorDestination | object>;
        devicePerks: ISliceRemote<IEUDAUpgradePackageDevicePerk[]>;
        deviceChannelConfig: ISliceRemote<TChannelConfig[]>;
    };
}

const initialState = {
    status: 'INIT',
    remotes: {
        elevatorSummary: createRemote(defaultElevatorSummary),
        kpis: createRemote({
            mileageTotal: null,
            mileageDensity: null,
            floorDistribution: null,
            tripHistory: null,
            outOfServiceTotal: null,
            doorFaultsTotal: null,
            safetyTestsTotal: null,
            sanitizationRunsTotal: null,
            stopsDensity: null,
            energyConsumptionDensity: null,
            timeConsumptionDensity: null,
            doorCyclesDensity: null
        }),
        elevatorState: createRemote(defaultElevatorState),
        emergencies: createRemote(defaultElevatorEmergency),
        elevatorProfile: createRemote(defaultElevatorProfile),
        elevatorGeneralSettings: createRemote(defaultElevatorGeneralSettings),
        upgrade: createRemote({
            status: EUpgradeStatus.None,
            upgradeId: '',
            family: '',
            sequence: 0,
            changelogId: '',
            devices: []
        }),
        teams: createRemote({}),
        memberships: createRemote({}),
        globalMemberships: createRemote({}),
        globalTeams: createRemote({}),
        destinations: createRemote([]),
        elevatorDevices: createRemote([]),
        stops: createRemote([]),
        configurationTemplates: createRemote(defaultGetConfigurationTemplate),
        permissions: createRemote({
            permissions: [],
            grants: [],
            access: [],
            isOwner: false,
            isPlatformMember: false,
            trace: []
        }),
        issues: createRemote([]),
        trips: createRemote([]),
        anomalies: createRemote([]),
        currentDestination: createRemote({}),
        devicePerks: createRemote([]),
        deviceChannelConfig: createRemote([])
    }
} satisfies IElevatorSliceState as IElevatorSliceState;

export type {IElevatorSliceState};
export {initialState};
