import type {IButtonBaseProps, TDSSize} from '@mcal/core-react';
import {getColorPalette, toSize} from '@mcal/core-react';
import type {TCSSObject} from '../../defines/styles.types.js';
import {styled} from '../../utils/styled/styled.js';

interface IProps {
    $variant: NonNullable<IButtonBaseProps<never>['variant']>;
    $disabled: NonNullable<IButtonBaseProps<never>['disabled']>;
    $size: NonNullable<IButtonBaseProps<never>['size']>;
    $color: NonNullable<IButtonBaseProps<never>['color']>;
    $emphasis: NonNullable<IButtonBaseProps<never>['emphasis']>;
    $width: NonNullable<IButtonBaseProps<never>['width']>;
}

const StyledButton = styled('button')<IProps>(
    ({theme, $width, $disabled}) => ({
        borderRadius: theme.shape.corner.rounded.c0.borderRadius,
        borderWidth: '1px',
        borderColor: 'transparent',
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        whiteSpace: 'nowrap',
        cursor: $disabled ? 'not-allowed' : 'pointer',
        userSelect: 'none',
        fontWeight: 500,
        width: $width,
        opacity: $disabled ? theme.opacity.o7 : 1
    }),
    ({theme, $variant, $color, $emphasis, disabled}) => {
        const main = getColorPalette(theme, $color, $emphasis).base;
        const contrast = getColorPalette(theme, $color, $emphasis).onBase;

        const bgHoverHigh = getColorPalette(theme, $color, 'high').state.hovered
            .base;

        const bgHoverLow = getColorPalette(theme, $color, 'low').state.hovered
            .base;

        if ($variant === 'filled') {
            const obj: TCSSObject = {
                borderColor: main,
                backgroundColor: main,
                color: contrast,
                '&:focus': {
                    backgroundColor: bgHoverHigh,
                    borderColor: bgHoverHigh,
                    boxShadow: `${theme.palette.background.normal} 0px 0px 0px 1px, ${bgHoverHigh} 0px 0px 0px 3px`
                },
                '&:active': {
                    backgroundColor: getColorPalette(theme, $color, 'low').state
                        .hovered.base,
                    borderColor: getColorPalette(theme, $color, 'low').state
                        .hovered.base,
                    boxShadow: 'none'
                }
            };

            if (!disabled) {
                obj['&:hover'] = {
                    backgroundColor: bgHoverHigh,
                    borderColor: main
                };
            }

            return obj;
        } else if ($variant === 'outlined') {
            const obj: TCSSObject = {
                borderColor: main,
                color: main,
                '&:focus': {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow,
                    borderColor: bgHoverHigh,
                    boxShadow: `${theme.palette.background.normal} 0px 0px 0px 1px, ${bgHoverHigh} 0px 0px 0px 3px`
                },
                '&:active': {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow,
                    borderColor: bgHoverHigh,
                    boxShadow: 'none'
                }
            };

            if (!disabled) {
                obj['&:hover'] = {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow,
                    borderColor: bgHoverHigh
                };
            }

            return obj;
        } else {
            const obj: TCSSObject = {
                color: main,
                '&:focus': {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow,
                    boxShadow: `${theme.palette.background.normal} 0px 0px 0px 1px, ${bgHoverHigh} 0px 0px 0px 3px`
                },
                '&:active': {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow,
                    boxShadow: 'none'
                }
            };

            if (!disabled) {
                obj['&:hover'] = {
                    backgroundColor:
                        $color === 'neutral'
                            ? theme.palette.neutral.divide
                            : bgHoverLow
                };
            }

            return obj;
        }
    },
    ({theme, $size}) => {
        const sizeKey = toSize<TDSSize>(['s3', 's3', 's4'], $size);
        const sizeHeight = {
            small: '32px',
            medium: '40px',
            large: '48px'
        };

        const padding = toSize(
            [
                `${theme.size.fixed.s0} ${theme.size.fixed.s2}`,
                `${theme.size.fixed.s2} ${theme.size.fixed.s3}`,
                `${theme.size.fixed.s4} ${theme.size.fixed.s5}`
            ],
            $size
        );

        return {
            height: sizeHeight[$size],
            fontSize: theme.size.font[sizeKey],
            padding
        };
    }
);

export {StyledButton};
