import type {EEntityType, EIndividualType} from './permissions.js';

enum EEmailTemplates {
    AnomalyReported = 'AnomalyReported',
    ApprovedSC = 'ApprovedSC',
    ApprovedTechnician = 'ApprovedTechnician',
    RegisterSC = 'RegisterSC',
    RegisterTechnician = 'RegisterTechnician',
    RejectedSC = 'RejectedSC',
    RejectedTechnician = 'RejectedTechnician',
    SiteElevatorRegistered = 'SiteElevatorRegistered',
    UserCompleted = 'UserCompleted'
}

interface IDocument {
    documentId: string;
    owner: string;
    ownerType: EEntityType | EIndividualType;
    name: string;
    contentType: string;
    url: string;
}

interface IImage {
    imageId: string;
    owner: string;
    ownerType: EEntityType | EIndividualType;
    name: string;
    contentType: string;
    url: string;
}

interface ITemplate<
    TOwnerType extends EEntityType | EIndividualType,
    TContent extends object
> {
    templateId: string;
    owner: string;
    ownerType: TOwnerType;
    name: string;
    contentType: string;
    content: TContent;
}

interface IAddress {
    country: string | null;
    city: string | null;
    state: string | null;
    street: string | null;
    number: string | null;
    zip: string | null;
    lat: number | null;
    lng: number | null;
}

interface IBaseEmailBody {
    name: string;
}

interface IBaseContactEmail extends IBaseEmailBody {
    dashboardURL: string;
    contactData: string;
}

interface IAnomalyEmailContent extends IBaseEmailBody {
    dashboardURL: string;
    elevatorName: string;
    siteName: string;
    location: string;
    date: string;
    anomalyCode: string;
    anomalyDescription: string;
    contactData: string;
}

interface IReviewedServiceCompanyEmailContent extends IBaseContactEmail {}

interface IApprovedTechnicianEmailContent extends IBaseContactEmail {}

interface IRegisteredServiceCompanyEmailContent extends IBaseEmailBody {
    contactData: string;
}

interface ISiteElevatorRegisteredEmailContent extends IBaseEmailBody {
    elevatorName: string;
    location: string;
    elevatorGroup: string;
    dashboardURL: string;
}

interface IUserCompletedEmailContent extends IBaseEmailBody {
    dashboardURL: string;
}

interface IRejectedTechnicianEmailContent extends IBaseEmailBody {
    contactData: string;
}

type TEmailContent =
    | IBaseEmailBody
    | IAnomalyEmailContent
    | IReviewedServiceCompanyEmailContent
    | IApprovedTechnicianEmailContent
    | IRegisteredServiceCompanyEmailContent
    | ISiteElevatorRegisteredEmailContent
    | IUserCompletedEmailContent
    | IRejectedTechnicianEmailContent;

interface IEmailEventBody<T extends TEmailContent> {
    email: string;
    content: T;
    templateName: EEmailTemplates;
}

type TThemePack = 'standard' | 'codistica';

const defaultThemePack: TThemePack = 'standard';

type TThemeType = 'auto' | 'light' | 'dark';

const defaultThemeType: TThemeType = 'auto';

export type {
    IAddress,
    IAnomalyEmailContent,
    IApprovedTechnicianEmailContent,
    IBaseEmailBody,
    IDocument,
    IEmailEventBody,
    IImage,
    IRegisteredServiceCompanyEmailContent,
    IRejectedTechnicianEmailContent,
    IReviewedServiceCompanyEmailContent,
    ISiteElevatorRegisteredEmailContent,
    ITemplate,
    IUserCompletedEmailContent,
    TEmailContent,
    TThemePack,
    TThemeType
};
export {EEmailTemplates, defaultThemePack, defaultThemeType};
