import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IMinusSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const MinusSvg: FC<IMinusSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'10'}
            height={'10'}
            viewBox={'0 0 10 10'}
            fill={fill}
            {...props}
        >
            <path d={'M0 5H10V6H0V5Z'} fill={fill || colorA} />
        </svg>
    );
};

export {MinusSvg};
