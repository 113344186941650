import type {TCSSObject} from '../../defines/styles.types.js';
import {styled} from '../../utils/styled/styled.js';
import type {ISelectPosition, TSelectPosition} from './select.js';

interface IContainer {
    open?: boolean;
    disabled?: boolean;
}

const StyledRoot = styled('div')<IContainer>(
    () => ({
        position: 'relative'
    }),
    ({disabled}) => {
        if (disabled) {
            return {
                '*': {
                    pointerEvents: 'none',
                    cursor: 'pointer'
                }
            };
        }
        return {};
    }
);

interface IStyledOptionsContainerProps {
    position?: TSelectPosition | ISelectPosition;
}

const StyledOptionsContainer = styled('div')<IStyledOptionsContainerProps>([
    ({theme}): TCSSObject => {
        return {
            position: 'absolute',
            zIndex: theme.depth.local.z4,
            cursor: 'pointer',
            padding: theme.size.fixed.s0,
            marginBlock: theme.size.fixed.s1,
            background: theme.palette.surface.base.high,
            borderRadius: theme.shape.corner.rounded.c0.borderRadius,
            boxShadow: theme.elevation.e3.shadow,
            border: `1px solid ${theme.palette.neutral.divide}`
        };
    },
    ({theme, position = 'bottomLeft'}): TCSSObject => {
        if (typeof position !== 'string') {
            return position;
        }
        if (position === 'bottomRight') {
            return {
                right: 0,
                top: `calc(100% + ${theme.size.fixed.s0})`
            };
        } else if (position === 'topLeft') {
            return {
                left: 0,
                bottom: `calc(100% + ${theme.size.fixed.s0})`
            };
        } else if (position === 'topRight') {
            return {
                right: 0,
                bottom: `calc(100% + ${theme.size.fixed.s0})`
            };
        }

        return {
            left: 0,
            top: `calc(100% + ${theme.size.fixed.s0})`
        };
    }
]);

const StyledOption = styled('div')(({theme}) => ({
    fontSize: '14px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: `${theme.shape.padding.p1} ${theme.shape.padding.p2}`
}));

const StyledOptionContainer = styled('div')(({theme}) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ':hover': {
        background: theme.palette.surface.base.low
    }
}));

interface IStyledLabelContainer {
    variant?: string;
    size?: string;
}

const StyledLabelContainer = styled('div')<IStyledLabelContainer>(
    ({theme}) => ({
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.size.fixed.s1,
        userSelect: 'none'
    }),
    ({size}) => {
        if (size === 'medium') {
            return {height: '40px'};
        }
        return {};
    },
    ({theme, variant}) => {
        if (variant === 'text') {
            return {
                padding: `${theme.shape.padding.p1} ${theme.shape.padding.p2}`
            };
        }

        return {
            padding: `${theme.shape.padding.p1} ${theme.shape.padding.p2}`,
            border: `1px solid ${theme.palette.neutral.divide}`,
            borderRadius: theme.shape.corner.rounded.c0.borderRadius,
            background: theme.palette.surface.base.high
        };
    }
);

export {
    StyledLabelContainer,
    StyledOption,
    StyledOptionContainer,
    StyledOptionsContainer,
    StyledRoot
};
