import type {
    TAPIRequestContent,
    TAPIResponse,
    TElevatorAddDeviceAPI,
    TElevatorConfigurationsAPI,
    TElevatorDeviceRebootAPI,
    TElevatorDeviceUnregisterAPI,
    TElevatorEmergenciesAPI,
    TElevatorEmergencyAcknowledgeAPI,
    TElevatorEmergencyRestoreAPI,
    TElevatorFullHandoffAPI,
    TElevatorGetAPI,
    TElevatorGetAnomaliesAPI,
    TElevatorGetChannelsConfigAPI,
    TElevatorGetDestinationsAPI,
    TElevatorGetDeviceConfigAPI,
    TElevatorGetDevicePerksAPI,
    TElevatorGetDevicesAPI,
    TElevatorGetIssuesAPI,
    TElevatorGetStateAPI,
    TElevatorGetStatusAPI,
    TElevatorGetStopsAPI,
    TElevatorGetSystemConfigAPI,
    TElevatorGetTechnicalParametersAPI,
    TElevatorGetTripsAPI,
    TElevatorOperationModeAPI,
    TElevatorPassengersGetServiceDegradationsAPI,
    TElevatorPassengersGetStateAPI,
    TElevatorPatchChannelsConfigAPI,
    TElevatorRebootAPI,
    TElevatorRemoteControlAPI,
    TElevatorRemoteControlCallAPI,
    TElevatorRemoteControlMoveAPI,
    TElevatorRenameDeviceAPI,
    TElevatorServiceRoutineAPI,
    TElevatorTeamAddAPI,
    TElevatorTeamDeleteAPI,
    TElevatorTeamGetAPI,
    TElevatorTeamUpdateAPI,
    TElevatorUpdateConfigurationSensorsAPI,
    TElevatorUpdateConfigurationSystemPlateAPI,
    TElevatorUpdateDestinationsAPI,
    TElevatorUpdateDeviceConfigAPI,
    TElevatorUpdateProfileAPI,
    TElevatorUpdateStopsAPI,
    TElevatorUpdateSystemConfigAPI,
    TElevatorUpdateTechnicalParametersAPI,
    TElevatorsListAPI,
    TElevatorsRegisterAPI,
    TUpgradePackageApply,
    TUpgradePackageChecker,
    TUpgradeReleaseChannel
} from '@mcal/core';
import {request} from '../../../utils/request/request.js';

const registerElevators = (
    {query, body}: TAPIRequestContent<TElevatorsRegisterAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorsRegisterAPI> => {
    return request<TElevatorsRegisterAPI>({
        method: 'PUT',
        url: '/elevators/register',
        signal,
        query,
        body
    });
};

const listElevators = (
    {query}: TAPIRequestContent<TElevatorsListAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorsListAPI> => {
    return request<TElevatorsListAPI>({
        method: 'GET',
        url: '/elevators',
        signal,
        query
    });
};

const listElevatorDestinations = (
    {query}: TAPIRequestContent<TElevatorGetDestinationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDestinationsAPI> => {
    return request<TElevatorGetDestinationsAPI>({
        method: 'GET',
        url: '/elevator/destinations',
        signal,
        query
    });
};

const listElevatorStops = (
    {query}: TAPIRequestContent<TElevatorGetStopsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStopsAPI> => {
    return request<TElevatorGetStopsAPI>({
        method: 'GET',
        url: '/elevator/stops',
        signal,
        query
    });
};

const getElevatorEmergency = (
    {query}: TAPIRequestContent<TElevatorEmergenciesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorEmergenciesAPI> => {
    return request<TElevatorEmergenciesAPI>({
        method: 'GET',
        url: '/elevator/emergencies',
        signal,
        query
    });
};

const restoreElevatorEmergency = (
    {query, body}: TAPIRequestContent<TElevatorEmergencyRestoreAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorEmergencyRestoreAPI> => {
    return request<TElevatorEmergencyRestoreAPI>({
        method: 'POST',
        url: '/elevator/emergency/restore',
        signal,
        body,
        query
    });
};

const acknowledgeElevatorEmergency = (
    {query, body}: TAPIRequestContent<TElevatorEmergencyAcknowledgeAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorEmergencyAcknowledgeAPI> => {
    return request<TElevatorEmergencyAcknowledgeAPI>({
        method: 'POST',
        url: '/elevator/emergency/acknowledge',
        signal,
        body,
        query
    });
};

const updateElevatorStops = (
    {query, body}: TAPIRequestContent<TElevatorUpdateStopsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateStopsAPI> => {
    return request<TElevatorUpdateStopsAPI>({
        method: 'PATCH',
        url: '/elevator/stops',
        signal,
        body,
        query
    });
};

const updateElevatorDestinations = (
    {query, body}: TAPIRequestContent<TElevatorUpdateDestinationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateDestinationsAPI> => {
    return request<TElevatorUpdateDestinationsAPI>({
        method: 'PATCH',
        url: '/elevator/destinations',
        signal,
        body,
        query
    });
};

const getElevator = (
    {query}: TAPIRequestContent<TElevatorGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetAPI> => {
    return request<TElevatorGetAPI>({
        method: 'GET',
        url: '/elevator',
        signal,
        query
    });
};

const getElevatorState = (
    {query}: TAPIRequestContent<TElevatorGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStateAPI> => {
    return request<TElevatorGetStateAPI>({
        method: 'GET',
        url: '/elevator/state',
        signal,
        query
    });
};

const getElevatorDevices = (
    {query}: TAPIRequestContent<TElevatorGetDevicesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDevicesAPI> => {
    return request<TElevatorGetDevicesAPI>({
        method: 'GET',
        url: '/elevator/devices',
        signal,
        query
    });
};

const getElevatorTechnicalParameters = (
    {query}: TAPIRequestContent<TElevatorGetTechnicalParametersAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetTechnicalParametersAPI> => {
    return request<TElevatorGetTechnicalParametersAPI>({
        method: 'GET',
        url: '/elevator/technical-parameters',
        signal,
        query
    });
};

const getElevatorIssues = (
    {query}: TAPIRequestContent<TElevatorGetIssuesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetIssuesAPI> => {
    return request<TElevatorGetIssuesAPI>({
        method: 'GET',
        url: '/elevator/issues',
        signal,
        query
    });
};

const getElevatorAnomalies = (
    {query}: TAPIRequestContent<TElevatorGetAnomaliesAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetAnomaliesAPI> => {
    return request<TElevatorGetAnomaliesAPI>({
        method: 'GET',
        url: '/elevator/anomalies',
        signal,
        query
    });
};

const getElevatorTrips = (
    {query}: TAPIRequestContent<TElevatorGetTripsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetTripsAPI> => {
    return request<TElevatorGetTripsAPI>({
        method: 'GET',
        url: '/elevator/trips',
        signal,
        query
    });
};

const updateElevatorTechnicalParameters = (
    {query, body}: TAPIRequestContent<TElevatorUpdateTechnicalParametersAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateTechnicalParametersAPI> => {
    return request<TElevatorUpdateTechnicalParametersAPI>({
        method: 'PATCH',
        url: '/elevator/technical-parameters',
        signal,
        body,
        query
    });
};

const getElevatorConfigurationTemplate = (
    {query}: TAPIRequestContent<TElevatorConfigurationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorConfigurationsAPI> => {
    return request<TElevatorConfigurationsAPI>({
        method: 'GET',
        url: '/elevator/configuration',
        signal,
        query
    });
};

const updateElevatorSystemPlate = (
    {
        query,
        body
    }: TAPIRequestContent<TElevatorUpdateConfigurationSystemPlateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateConfigurationSystemPlateAPI> => {
    return request<TElevatorUpdateConfigurationSystemPlateAPI>({
        method: 'PATCH',
        url: '/elevator/configuration/system-config',
        signal,
        body,
        query
    });
};

const updateElevatorSensors = (
    {query, body}: TAPIRequestContent<TElevatorUpdateConfigurationSensorsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateConfigurationSensorsAPI> => {
    return request<TElevatorUpdateConfigurationSensorsAPI>({
        method: 'PATCH',
        url: '/elevator/configuration/sensors',
        signal,
        body,
        query
    });
};

const updateElevatorAdvanced = (
    {query, body}: TAPIRequestContent<TElevatorUpdateDeviceConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateDeviceConfigAPI> => {
    return request<TElevatorUpdateDeviceConfigAPI>({
        method: 'PATCH',
        url: '/elevator/device-config',
        signal,
        body,
        query
    });
};

const getElevatorDeviceConfig = (
    {query}: TAPIRequestContent<TElevatorGetDeviceConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDeviceConfigAPI> => {
    return request<TElevatorGetDeviceConfigAPI>({
        method: 'GET',
        url: '/elevator/device-config',
        signal,
        query
    });
};

const getElevatorSystemConfig = (
    {query}: TAPIRequestContent<TElevatorGetSystemConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetSystemConfigAPI> => {
    return request<TElevatorGetSystemConfigAPI>({
        method: 'GET',
        url: '/elevator/system-config',
        signal,
        query
    });
};

const updateElevatorDeviceConfig = (
    {query, body}: TAPIRequestContent<TElevatorUpdateDeviceConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateDeviceConfigAPI> => {
    return request<TElevatorUpdateDeviceConfigAPI>({
        method: 'PATCH',
        url: '/elevator/device-config',
        body,
        signal,
        query
    });
};

const updateElevatorSystemConfig = (
    {query, body}: TAPIRequestContent<TElevatorUpdateSystemConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateSystemConfigAPI> => {
    return request<TElevatorUpdateSystemConfigAPI>({
        method: 'PATCH',
        url: '/elevator/system-config',
        signal,
        body,
        query
    });
};

const updateElevatorProfile = (
    {query, body}: TAPIRequestContent<TElevatorUpdateProfileAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorUpdateProfileAPI> => {
    return request<TElevatorUpdateProfileAPI>({
        method: 'PATCH',
        url: '/elevator/profile',
        signal,
        query,
        body
    });
};

const getElevatorTeam = (
    {query}: TAPIRequestContent<TElevatorTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamGetAPI> => {
    return request<TElevatorTeamGetAPI>({
        method: 'GET',
        url: '/elevator/team',
        signal,
        query
    });
};

const updateElevatorTeam = (
    {query, body}: TAPIRequestContent<TElevatorTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamUpdateAPI> => {
    return request<TElevatorTeamUpdateAPI>({
        method: 'PATCH',
        url: '/elevator/team',
        signal,
        query,
        body
    });
};

const deleteElevatorTeam = (
    {query, body}: TAPIRequestContent<TElevatorTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamDeleteAPI> => {
    return request<TElevatorTeamDeleteAPI>({
        method: 'DELETE',
        url: '/elevator/team',
        signal,
        query,
        body
    });
};

const updateElevatorOperationMode = (
    {query, body}: TAPIRequestContent<TElevatorOperationModeAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorOperationModeAPI> => {
    return request<TElevatorOperationModeAPI>({
        method: 'PATCH',
        url: '/elevator/operation-mode',
        signal,
        query,
        body
    });
};

const updateElevatorHandoff = (
    {query, body}: TAPIRequestContent<TElevatorFullHandoffAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorFullHandoffAPI> => {
    return request<TElevatorFullHandoffAPI>({
        method: 'PATCH',
        url: '/elevator/full-handoff',
        signal,
        query,
        body
    });
};

const serviceRoutine = (
    {query, body}: TAPIRequestContent<TElevatorServiceRoutineAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorServiceRoutineAPI> => {
    return request<TElevatorServiceRoutineAPI>({
        method: 'POST',
        url: '/elevator/service-routine',
        signal,
        query,
        body
    });
};

const remoteControlCall = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlCallAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlCallAPI> => {
    return request<TElevatorRemoteControlCallAPI>({
        method: 'POST',
        url: '/elevator/remote-call',
        signal,
        query,
        body
    });
};

const remoteMove = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlMoveAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlMoveAPI> => {
    return request<TElevatorRemoteControlMoveAPI>({
        method: 'POST',
        url: '/elevator/remote-move',
        signal,
        query,
        body
    });
};

const updateRemoteControlMode = (
    {query, body}: TAPIRequestContent<TElevatorRemoteControlAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRemoteControlAPI> => {
    return request<TElevatorRemoteControlAPI>({
        method: 'PATCH',
        url: '/elevator/remote-control',
        signal,
        query,
        body
    });
};

const getElevatorStatus = (
    {query}: TAPIRequestContent<TElevatorGetStatusAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetStatusAPI> => {
    return request<TElevatorGetStatusAPI>({
        method: 'GET',
        url: '/elevator/status',
        signal,
        query
    });
};

const rebootElevatorDevice = (
    {query, body}: TAPIRequestContent<TElevatorDeviceRebootAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorDeviceRebootAPI> => {
    return request<TElevatorDeviceRebootAPI>({
        method: 'POST',
        url: '/elevator/devices/reboot',
        signal,
        body,
        query
    });
};

const renameElevatorDevice = (
    {query}: TAPIRequestContent<TElevatorRenameDeviceAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRenameDeviceAPI> => {
    return request<TElevatorRenameDeviceAPI>({
        method: 'PATCH',
        url: '/elevator/devices/rename',
        signal,
        query
    });
};

const removeElevatorDevice = (
    {query, body}: TAPIRequestContent<TElevatorDeviceUnregisterAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorDeviceUnregisterAPI> => {
    return request<TElevatorDeviceUnregisterAPI>({
        method: 'DELETE',
        url: '/elevator/devices',
        signal,
        query,
        body
    });
};

const rebootElevator = (
    {query, body}: TAPIRequestContent<TElevatorRebootAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorRebootAPI> => {
    return request<TElevatorRebootAPI>({
        method: 'POST',
        url: '/devices/reboot-elevator',
        signal,
        body,
        query
    });
};

const addElevatorTeamMember = (
    {query, body}: TAPIRequestContent<TElevatorTeamAddAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorTeamAddAPI> => {
    return request<TElevatorTeamAddAPI>({
        method: 'PUT',
        url: '/elevator/team',
        signal,
        query,
        body
    });
};

const checkUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackageChecker>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageChecker> => {
    return request<TUpgradePackageChecker>({
        method: 'GET',
        url: '/upgrades/checker',
        signal,
        query
    });
};

const applyUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackageApply>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageApply> => {
    return request<TUpgradePackageApply>({
        method: 'POST',
        url: '/upgrades/apply',
        signal,
        query
    });
};

const updateUpgradeReleaseChannel = (
    {query, body}: TAPIRequestContent<TUpgradeReleaseChannel>,
    signal: AbortSignal
): TAPIResponse<TUpgradeReleaseChannel> => {
    return request<TUpgradeReleaseChannel>({
        method: 'POST',
        url: '/upgrades/release-channel',
        signal,
        query,
        body
    });
};

const addElevatorDevice = (
    {query, body}: TAPIRequestContent<TElevatorAddDeviceAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorAddDeviceAPI> => {
    return request<TElevatorAddDeviceAPI>({
        method: 'PUT',
        url: '/elevator/devices/add-device',
        signal,
        query,
        body
    });
};

const getElevatorDevicePerks = (
    {query}: TAPIRequestContent<TElevatorGetDevicePerksAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetDevicePerksAPI> => {
    return request<TElevatorGetDevicePerksAPI>({
        method: 'GET',
        url: '/devices/perks',
        signal,
        query
    });
};

const getElevatorDeviceChannelConfig = (
    {query}: TAPIRequestContent<TElevatorGetChannelsConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorGetChannelsConfigAPI> => {
    return request<TElevatorGetChannelsConfigAPI>({
        method: 'GET',
        url: '/devices/channel-config',
        signal,
        query
    });
};

const updateElevatorDeviceChannelConfig = (
    {query, body}: TAPIRequestContent<TElevatorPatchChannelsConfigAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorPatchChannelsConfigAPI> => {
    return request<TElevatorPatchChannelsConfigAPI>({
        method: 'PATCH',
        url: '/devices/channel-config',
        signal,
        query,
        body
    });
};

const getElevatorPassengersServiceDegradations = (
    {query}: TAPIRequestContent<TElevatorPassengersGetServiceDegradationsAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorPassengersGetServiceDegradationsAPI> => {
    return request<TElevatorPassengersGetServiceDegradationsAPI>({
        method: 'GET',
        url: '/elevator/passengers/service-degradations',
        signal,
        query
    });
};

const getElevatorPassengersState = (
    {query}: TAPIRequestContent<TElevatorPassengersGetStateAPI>,
    signal: AbortSignal
): TAPIResponse<TElevatorPassengersGetStateAPI> => {
    return request<TElevatorPassengersGetStateAPI>({
        method: 'GET',
        url: '/elevator/passengers/state',
        signal,
        query
    });
};

export {
    acknowledgeElevatorEmergency,
    addElevatorDevice,
    addElevatorTeamMember,
    applyUpgradePackage,
    checkUpgradePackage,
    deleteElevatorTeam,
    getElevator,
    getElevatorAnomalies,
    getElevatorConfigurationTemplate,
    getElevatorDeviceChannelConfig,
    getElevatorDeviceConfig,
    getElevatorDevicePerks,
    getElevatorDevices,
    getElevatorEmergency,
    getElevatorIssues,
    getElevatorPassengersServiceDegradations,
    getElevatorPassengersState,
    getElevatorState,
    getElevatorStatus,
    getElevatorSystemConfig,
    getElevatorTeam,
    getElevatorTechnicalParameters,
    getElevatorTrips,
    listElevatorDestinations,
    listElevatorStops,
    listElevators,
    rebootElevator,
    rebootElevatorDevice,
    registerElevators,
    remoteControlCall,
    remoteMove,
    removeElevatorDevice,
    renameElevatorDevice,
    restoreElevatorEmergency,
    serviceRoutine,
    updateElevatorAdvanced,
    updateElevatorDestinations,
    updateElevatorDeviceChannelConfig,
    updateElevatorDeviceConfig,
    updateElevatorHandoff,
    updateElevatorOperationMode,
    updateElevatorProfile,
    updateElevatorSensors,
    updateElevatorStops,
    updateElevatorSystemConfig,
    updateElevatorSystemPlate,
    updateElevatorTeam,
    updateElevatorTechnicalParameters,
    updateRemoteControlMode,
    updateUpgradeReleaseChannel
};
