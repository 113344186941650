import type {TNested} from '../common/utility.types.js';
import type {TTimeZone} from '../core/formats.js';
import type {IAddress} from './common.js';
import type {IEntity} from './permissions.js';
import {EEntityStatus, EEntityType} from './permissions.js';

enum ESiteStatus {
    Normal = 'normal',
    Emergency = 'emergency',
    Unknown = 'unknown'
}

type TSiteScope =
    | EEntityType.Platform
    | EEntityType.ServiceCompany
    | EEntityType.Site;

interface ISiteSummary extends IEntity<EEntityType.Site> {
    status: ESiteStatus;
    name: string;
    address: IAddress;
    registeredElevators: number;
}

const defaultSiteSummary: ISiteSummary = {
    entityId: '',
    entityType: EEntityType.Site,
    entityStatus: EEntityStatus.Pending,
    parentId: '',
    team: [],
    status: ESiteStatus.Unknown,
    name: '',
    address: {
        state: null,
        country: null,
        city: null,
        street: null,
        number: null,
        zip: null,
        lat: null,
        lng: null
    },
    registeredElevators: 0
};

interface ISiteState {
    siteId: string;
    status: ESiteStatus;
}

const defaultSiteState: ISiteState = {
    siteId: '',
    status: ESiteStatus.Unknown
};

interface ISiteProfile {
    siteId: string;
    name: string;
    description: string | null;
    banner: string | null;
    logo: string | null;
    favicon: string | null;
    address: IAddress;
    standardEmail: string | null;
    standardPhoneNumber: string | null;
    emergencyEmail: string | null;
    emergencyPhoneNumber: string | null;
}

const defaultSiteProfile: ISiteProfile = {
    siteId: '',
    name: '',
    description: null,
    banner: null,
    logo: null,
    favicon: null,
    address: {
        state: null,
        country: null,
        city: null,
        street: null,
        number: null,
        zip: null,
        lat: null,
        lng: null
    },
    standardEmail: null,
    standardPhoneNumber: null,
    emergencyEmail: null,
    emergencyPhoneNumber: null
};

interface ISiteGeneralSettings {
    siteId: string;
    customColor: string | null;
    timeZone: TTimeZone;
}

const defaultSiteGeneralSettings: ISiteGeneralSettings = {
    siteId: '',
    customColor: null,
    timeZone: 'UTC'
};

enum ESiteActivityType {
    ElevatorRegistered = 'activity-elevator-registered',
    ElevatorUnregistered = 'activity-elevator-unregistered',
    ElevatorOnline = 'activity-elevator-online',
    ElevatorOffline = 'activity-elevator-offline',
    ElevatorAnomaly = 'activity-elevator-anomaly',
    ElevatorEmergency = 'activity-elevator-emergency',
    ElevatorOperationModeChanged = 'activity-elevator-operation-mode-changed',
    ElevatorKPIMilestone = 'activity-elevator-kpi-milestone',
    ElevatorServiceRoutineStarted = 'activity-elevator-service-routine-started',
    SiteKPIMilestone = 'activity-site-kpi-milestone',
    SiteTeamChanged = 'activity-site-team-changed'
}

interface ISiteActivityEntryBase {
    activityType: ESiteActivityType;
    timestamp: number;
    entityId: string;
    entityType: EEntityType;
    value: number | string;
    initiatedBy: string | null;
}

interface ISiteActivityEntryParent extends ISiteActivityEntryBase {
    parentId: string;
}

interface ISiteActivityEntry extends ISiteActivityEntryBase {
    activityId: string;
    siteId: string;
    parent: ISiteActivityEntryParent | TNested<ISiteActivityEntryParent, null>;
}

interface ISiteFull {
    summary: ISiteSummary;
    state: ISiteState;
    profile: ISiteProfile;
    generalSettings: ISiteGeneralSettings;
}

interface ISiteStats {
    totalElevators: number;
    totalElevatorEmergencies: number;

    // USED FOR "Connectivity" STATS
    connectivityElevatorsOnline: number;
    connectivityElevatorsOffline: number;

    // USED FOR "Operation" STATS
    operationElevatorsNormal: number;
    operationElevatorsService: number;
    operationElevatorsSetup: number;

    // USED FOR "Health" STATS
    healthElevatorsHealthy: number;
    healthElevatorsFaulty: number;
}

interface IPlatformStatsSites {
    totalSites: number;
}

export type {
    IPlatformStatsSites,
    ISiteActivityEntry,
    ISiteActivityEntryBase,
    ISiteActivityEntryParent,
    ISiteFull,
    ISiteGeneralSettings,
    ISiteProfile,
    ISiteState,
    ISiteStats,
    ISiteSummary,
    TSiteScope
};
export {
    ESiteActivityType,
    ESiteStatus,
    defaultSiteGeneralSettings,
    defaultSiteProfile,
    defaultSiteState,
    defaultSiteSummary
};
