import type {CSSObject} from '@emotion/react';
import {autoPlacement, autoUpdate, useFloating} from '@floating-ui/react-dom';
import type {IOption} from '@mcal/core';
import type {TTestIDs} from '@mcal/core-react';
import {cn} from '@mcal/core-react';
import type {ElementType, MouseEvent, ReactNode} from 'react';
import {useCallback, useMemo, useState} from 'react';
import {createTestIDs} from '../../dev/index.js';
import type {TOnChange} from '../../hooks/use-input-control/use-input-control.js';
import {useInputControl} from '../../hooks/use-input-control/use-input-control.js';
import {useOnClickOutside} from '../../hooks/use-on-click-outside/use-on-click-outside.js';
import {CheckSvg} from '../../icons/check.svg.js';
import {DropdownSvg} from '../../icons/dropdown.svg.js';
import {Flex} from '../flex/flex.js';
import {Label} from '../label/label.js';
import {
    StyledLabelContainer,
    StyledOption,
    StyledOptionContainer,
    StyledOptionsContainer,
    StyledRoot
} from './select.styles.js';

const ownTestIDs = createTestIDs('Select', ['root']);

type TSelectPosition = 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight';
interface ISelectPosition extends CSSObject {
    left?: string;
    top?: string;
    bottom?: string;
    right?: string;
}

interface IReactOption extends IOption {
    icon?: ReactNode;
}

interface ISelectProps<T extends IReactOption = IReactOption> {
    className?: string;
    size?: 'small' | 'medium' | 'large';
    defaultValue?: IReactOption | string;
    label?: string;
    onChange?: TOnChange<IReactOption>;
    onClick?: (option: IReactOption) => void;
    onContextMenu?: (option: IReactOption) => void;
    onSelect?: (option: T) => void;
    options: T[];
    placeholder?: string;
    testIDs?: TTestIDs<typeof ownTestIDs>;
    trigger?: 'CLICK' | 'CONTEXT-MENU';
    position?: TSelectPosition | ISelectPosition;
    disabled?: boolean;
    value?: IReactOption | string;
    variant?: 'text';
    PlaceholderComponent?: ElementType;
    activateFloating?: boolean;
    selectedIcon?: ReactNode;
    grouped?: boolean;
    groupLabels?: Record<string, string>;
    classes?: {
        root?: string;
    };
}
function Select<T extends IReactOption = IReactOption>({
    variant,
    value,
    size,
    defaultValue,
    options,
    onChange,
    onClick,
    onSelect,
    onContextMenu,
    placeholder = '',
    trigger = 'CLICK',
    testIDs = {},
    className = '',
    disabled,
    classes = {},
    position,
    activateFloating = false,
    selectedIcon = <CheckSvg />,
    PlaceholderComponent,
    grouped,
    groupLabels
}: ISelectProps<T>): JSX.Element {
    const placeholderOption = useMemo(() => {
        return {
            label: placeholder,
            value: ''
        };
    }, [placeholder]);

    const serializer = useCallback((value: string | IReactOption) => {
        if (typeof value === 'string') {
            return value;
        } else {
            return value.value;
        }
    }, []);

    const parser = useCallback(
        (value: string | IReactOption) => {
            if (typeof value === 'string') {
                const match = options.find((option) => option.value === value);

                if (match) {
                    return match;
                } else {
                    return placeholderOption;
                }
            } else {
                return value;
            }
        },
        [options, placeholderOption]
    );

    const control = useInputControl<IReactOption>({
        value,
        defaultValue: defaultValue || placeholderOption,
        onChange,
        serializer,
        parser
    });

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onSelectionHandler = useCallback(
        (o: T): void => {
            if (onSelect) {
                onSelect(o);
                setIsOpen(false);
                return;
            }
            control.setValue({
                value: o.value,
                label: o.label
            });
            setIsOpen(false);
        },
        [control, onSelect]
    );

    const onClickHandler = useCallback((): void => {
        if (onClick) {
            onClick(control.value);
        }

        if (trigger === 'CLICK') {
            setIsOpen((currentOpen) => !currentOpen);
        }
    }, [control.value, onClick, trigger]);

    const onContextMenuHandler = useCallback(
        (e: MouseEvent): boolean => {
            if (onContextMenu) {
                onContextMenu(control.value);
            }

            if (trigger === 'CONTEXT-MENU') {
                e.preventDefault();

                setIsOpen((currentOpen) => !currentOpen);

                return false;
            }

            return true;
        },
        [control.value, onContextMenu, trigger]
    );

    const {refs, floatingStyles} = useFloating({
        middleware: [autoPlacement()],
        whileElementsMounted: autoUpdate,
        open: isOpen
    });

    const onClickOutsideHandler = useCallback(() => {
        setIsOpen(false);
    }, []);

    const bind = useOnClickOutside<HTMLDivElement>(onClickOutsideHandler);

    const Placeholder = useCallback(() => {
        return PlaceholderComponent ? (
            <PlaceholderComponent />
        ) : (
            <>
                <Label type={'small'}>{control.defaultValue.label}</Label>
                <DropdownSvg />
            </>
        );
    }, [PlaceholderComponent, control.defaultValue.label]);

    const grouppedOptions = useMemo(() => {
        if (grouped) {
            const groups: {key: string; label: string; options: T[]}[] = [];
            const noGroup: T[] = [];

            for (let i = 0; i < options.length; i++) {
                const option = options[i];
                if (option.group) {
                    const findGroup = groups.find(
                        (group) => group.key === option.group
                    );
                    if (findGroup) {
                        findGroup.options.push(option);
                    } else {
                        groups.push({
                            key: option.group,
                            label:
                                groupLabels && groupLabels[option.group]
                                    ? groupLabels[option.group]
                                    : option.group,
                            options: [option]
                        });
                    }
                } else {
                    noGroup.push(option);
                }
            }

            return [...groups, {key: 'noGroup', label: '', options: noGroup}];
        }

        return [{key: 'noGroup', label: '', options: options}];
    }, [groupLabels, grouped, options]);

    return (
        <StyledRoot
            data-testid={testIDs.root || ownTestIDs.root}
            className={cn(className, classes.root)}
            open={isOpen}
            disabled={disabled}
            {...bind}
        >
            <StyledLabelContainer
                size={size}
                variant={variant}
                onClick={onClickHandler}
                onContextMenu={onContextMenuHandler}
                ref={refs.setReference}
            >
                {control.value.value !== '' ? (
                    <>
                        <Flex justifyContent={'flex-start'}>
                            {control.value.icon && control.value.icon}
                            <Label type={'small'}>{control.value.label}</Label>
                        </Flex>
                        <DropdownSvg />
                    </>
                ) : (
                    <Placeholder />
                )}
            </StyledLabelContainer>

            {isOpen && (
                <StyledOptionsContainer
                    ref={refs.setFloating}
                    style={activateFloating ? floatingStyles : {}}
                    position={position}
                >
                    {grouppedOptions.map((group) => {
                        return (
                            <div key={group.key}>
                                {group.label && (
                                    <StyledOption>
                                        <Label type={'extraSmall'}>
                                            {group.label}
                                        </Label>
                                    </StyledOption>
                                )}

                                {group.options.map((option) => {
                                    return (
                                        <StyledOptionContainer
                                            key={option.value}
                                            onClick={() => {
                                                onSelectionHandler(option);
                                            }}
                                        >
                                            <StyledOption>
                                                {option.icon && option.icon}
                                                {option.label}
                                            </StyledOption>
                                            {control.value.value ===
                                                option.value &&
                                                selectedIcon && (
                                                    <>{selectedIcon}</>
                                                )}
                                        </StyledOptionContainer>
                                    );
                                })}
                            </div>
                        );
                    })}
                    {/* {options.map((option) => {
                        return (
                            <StyledOptionContainer
                                key={option.value}
                                onClick={() => {
                                    onSelectionHandler(option);
                                }}>
                                <StyledOption>
                                    {option.icon && option.icon}
                                    {option.label}
                                </StyledOption>
                                {control.value.value === option.value && selectedIcon && (
                                    <>{selectedIcon}</>
                                )}
                            </StyledOptionContainer>
                        );
                    })} */}
                </StyledOptionsContainer>
            )}
        </StyledRoot>
    );
}

export type {ISelectPosition, ISelectProps, TSelectPosition};
export {Select, ownTestIDs as testIDs};
