import type {IFontStyles} from '../../defines/fonts.js';
import {fontsStyles} from '../../defines/fonts.js';
import type {TTypeFace} from '../../defines/theme.types.js';
import {useTranslation} from '../use-translation/use-translation.js';

function useFontsStyles(typeface: TTypeFace): IFontStyles {
    const {i18n, ready} = useTranslation(undefined, {useSuspense: false});

    if (ready && fontsStyles[i18n.language]) {
        return fontsStyles[i18n.language][typeface];
    }

    return fontsStyles.default[typeface];
}

export {useFontsStyles};
