import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
}

interface IInfoSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const InfoSvg: FC<IInfoSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#999DA5'
            };
        } else {
            return {
                colorA: '#555C69'
            };
        }
    }, [type]);
    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'16'}
            height={'16'}
            viewBox={'0 0 16 16'}
            fill={'none'}
            {...props}
        >
            <path
                d={
                    'M7.9956 12C8.20687 12 8.38542 11.9281 8.53125 11.7844C8.67708 11.6406 8.75 11.4625 8.75 11.25V7.75C8.75 7.5375 8.67855 7.35938 8.53565 7.21562C8.39273 7.07187 8.21565 7 8.0044 7C7.79313 7 7.61458 7.07187 7.46875 7.21562C7.32292 7.35938 7.25 7.5375 7.25 7.75V11.25C7.25 11.4625 7.32145 11.6406 7.46435 11.7844C7.60727 11.9281 7.78435 12 7.9956 12ZM7.9956 5.5C8.20687 5.5 8.38542 5.42855 8.53125 5.28565C8.67708 5.14273 8.75 4.96565 8.75 4.7544C8.75 4.54313 8.67855 4.36458 8.53565 4.21875C8.39273 4.07292 8.21565 4 8.0044 4C7.79313 4 7.61458 4.07145 7.46875 4.21435C7.32292 4.35727 7.25 4.53435 7.25 4.7456C7.25 4.95687 7.32145 5.13542 7.46435 5.28125C7.60727 5.42708 7.78435 5.5 7.9956 5.5ZM8.00575 16C6.9047 16 5.86806 15.7917 4.89583 15.375C3.92361 14.9583 3.07292 14.3854 2.34375 13.6562C1.61458 12.9271 1.04167 12.0767 0.625 11.105C0.208333 10.1334 0 9.09519 0 7.99046C0 6.88571 0.208333 5.85069 0.625 4.88542C1.04167 3.92014 1.61458 3.07292 2.34375 2.34375C3.07292 1.61458 3.92332 1.04167 4.89496 0.625C5.86661 0.208333 6.90481 0 8.00954 0C9.11429 0 10.1493 0.208333 11.1146 0.625C12.0799 1.04167 12.9271 1.61458 13.6562 2.34375C14.3854 3.07292 14.9583 3.92169 15.375 4.89008C15.7917 5.85849 16 6.89321 16 7.99425C16 9.09531 15.7917 10.1319 15.375 11.1042C14.9583 12.0764 14.3854 12.9271 13.6562 13.6562C12.9271 14.3854 12.0783 14.9583 11.1099 15.375C10.1415 15.7917 9.10679 16 8.00575 16Z'
                }
                fill={fill || colorA}
            />
        </svg>
    );
};

export {InfoSvg};
