import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {getUsersMemberships} from '../../services/app-node-auth/users/users.thunks.js';
import {listElevators} from '../../services/app-node-elevators/elevators/elevators.thunks.js';
import {
    listEntityInvites,
    sendInvite
} from '../../services/app-node-invites/invites/invites.thunks.js';
import {getMileageTotal} from '../../services/app-node-kpis/kpis/kpis.thunks.js';
import {
    addElevatorGroup,
    addSiteTeamMembers,
    deleteElevatorGroup,
    deleteSiteTeam,
    getSite,
    getSiteState,
    getSiteTeam,
    getStats,
    listElevatorGroups,
    loadMoreSiteActivity,
    updateElevatorGroupProfile,
    updateSiteProfile,
    updateSiteTeam,
    updateSiteTeamInherit
} from '../../services/app-node-sites/sites/sites.thunks.js';
import {
    selectElevatorGroups,
    selectElevators,
    selectGroupedElevators,
    selectGroupedElevatorsStatus,
    selectSiteActivity,
    selectSiteActivityStatus,
    selectSiteGlobalTeam,
    selectSiteMileageTotal,
    selectSitePermissions,
    selectSiteProfile,
    selectSiteState,
    selectSiteStatus,
    selectSiteSummary,
    selectSiteTeam,
    selectStats
} from './site.selectors.js';
import {siteSlice} from './site.slice.js';

const siteActions = siteSlice.actions;

const siteSelectors = {
    selectSiteSummary,
    selectSiteState,
    selectSiteProfile,
    selectSiteStatus,
    selectSiteMileageTotal,
    selectElevatorGroups,
    selectElevators,
    selectSiteGlobalTeam,
    selectGroupedElevators,
    selectGroupedElevatorsStatus,
    selectSitePermissions,
    selectSiteTeam,
    selectSiteActivity,
    selectSiteActivityStatus,
    selectStats
};

const siteThunks = {
    addElevatorGroup,
    addSiteTeamMembers,
    deleteElevatorGroup,
    deleteSiteTeam,
    getMileageTotal,
    getPermissions,
    getSite,
    getSiteState,
    getSiteTeam,
    getStats,
    getUsersMemberships,
    listElevatorGroups,
    listElevators,
    listEntityInvites,
    loadMoreSiteActivity,
    sendInvite,
    updateElevatorGroupProfile,
    updateSiteProfile,
    updateSiteTeam,
    updateSiteTeamInherit
};

export {siteActions, siteSelectors, siteSlice, siteThunks};
