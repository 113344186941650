import type {EElevatorDeviceType} from './elevator.js';

/**
 * Interface definitions for reading the JSON specifications of the upgrade packages.
 */

/**
 * @property {number} schema - The version of the schema used to describe the package.
 * @property {string} family - The product family that the devices in the package belong to.
 * @property {number} sequence - The sequence number of the upgrade.
 * @property {Object} changelog - Information about the changes in the upgrade.
 * @property {string} changelog.path - The path to the changelog file.
 * @property {IEUDAUpgradePackageDevice[]} content - The list of devices in the package.
 */
interface IEUDAUpgradePackage {
    schema: number;
    family: string;
    sequence: number;
    changelog: {
        path: string;
    };
    content: IEUDAUpgradePackageDevice[];
}

/**
 * @property {string} platform - The platform unique identifier (hardware version/variant).
 * @property {string} firmware - The firmware version of the device (full semver support).
 * @property {Object} bin - Information about the firmware binary.
 * @property {string} bin.path - The path to the firmware binary file.
 * @property {string} bin.hash - The hash of the firmware binary file.
 * @property {number} timestamp - The timestamp of the firmware (build time).
 * @property {Object} support - The support information for the device.
 * @property {string} support.ble - The version of the BLE support.
 * @property {string} support.shadows - The version of the shadows support.
 * @property {string} support.mqtt - The version of the MQTT support.
 */
interface IEUDAUpgradePackageDevice {
    platform: string;
    firmware: string;
    bin: {
        path: string;
        hash: string;
    };
    timestamp: number;
    support: IEUDAUpgradeSupport;
    capabilities: TEUDAUpgradePackageDeviceCapability[];
    perks: IEUDAUpgradePackageDevicePerk[];
}

interface IEUDAUpgradeSupport {
    ble: string;
    shadows: string;
    mqtt: string;
}

/**
 * @property {Object[]} capabilities - The list of elevator capabilities enabled by the device.
 * @property {string} capabilities.key - The unique key of the capability.
 * @property {boolean} [capabilities.defaultValue] - The default value of the capability. Will be applied only if the capability is not present at the time of the upgrade.
 * @property {boolean} [capabilities.value] - The value of the capability. Will override any existing value of the capability. This field takes precedence over the `defaultValue` field. At least one of these fields must be present.
 */
type TEUDAUpgradePackageDeviceCapability =
    | {
          key: string;
          defaultValue: boolean;
      }
    | {
          key: string;
          value: boolean;
      };

/**
 * @property {Object[]} perks - The list of device perks available at the device level.
 * @property {string} perks.type - The type of the perk.
 * @property {string|number} perks.key - The unique key of the perk within the type.
 * @property {string|number|boolean} [perks.value] - The value of the perk. This field is optional and depends on the type of the perk.
 */
interface IEUDAUpgradePackageDevicePerk {
    type: string;
    key: string | number;
    value?: string | number | boolean;
}

/**
 * Interface definitions for getting from the API the upgrade information.
 */

/**
 * @property {EElevatorDeviceType} deviceType - The type of the elevator device.
 * @property {number} count - The number of devices of the specified type that have installed the version.
 */
interface IUpgradeDeviceCount {
    deviceType: EElevatorDeviceType;
    count: number;
}

/**
 * Represents a short version of the upgrade.
 *
 * @property {string} family - The product family of the upgrade.
 * @property {number} sequence - The sequence number of the upgrade.
 * @property {number | null} publicationTimestamp - The timestamp when the upgrade was published, or null if not published.
 * @property {EUpgradeState} status - The current status of the upgrade.
 * @property {IUpgradeDeviceCount[]} counter - The count of devices that have installed the upgrade, categorized by device type.
 */
interface IUpgradePackageSummary {
    family: string;
    sequence: number;
    publicationTimestamp: string | null;
    channel: EUpgradeChannel;
    status: EUpgradeState;
    counter: IUpgradeDeviceCount[];
    upgradeId: string;
    createdAt: string;
    updatedAt: string;
}

/**
 * Enum representing the different channels for an upgrade.
 *
 * @enum {string}
 */
enum EUpgradeChannel {
    Dev = 'Dev', // Development channel
    Beta = 'Beta', // Beta testing channel
    Official = 'Official', // Official release channel
    None = 'None'
}

/**
 * Enum representing the different states of an upgrade.
 * Here is important to note that once an upgrade is published, it cannot be unpublished. It can only be deprecated.
 *
 * @enum {string}
 */
enum EUpgradeState {
    Published = 'Published', // Upgrade has been published
    Draft = 'Draft', // Upgrade has not been published
    Deprecated = 'Deprecated' // Upgrade has been deprecated
}

/**
 * Represents a device in an elevator that can be upgraded.
 *
 * @property {string} platform - The platform unique identifier (hardware version/variant).
 * @property {string} firmware - The firmware version of the device (full semver support).
 * @property {number} buildTimestamp - The timestamp of the firmware build.
 * @property {Object} support - The support information for the device.
 * @property {string} support.ble - The version of the BLE support.
 * @property {string} support.shadows - The version of the shadows support.
 * @property {string} support.mqtt - The version of the MQTT support.
 * @property {TEUDAUpgradePackageDeviceCapability[]} capabilities - The list of elevator capabilities enabled by the device.
 * @property {IEUDAUpgradePackageDevicePerk[]} perks - The list of device perks available at the device level.
 */
interface IUpgradeDevice {
    platform: string;
    firmware: string;
    deviceType: EElevatorDeviceType;
    buildTimestamp: number;
    buildHash: string;
    support: {
        ble: string;
        shadows: string;
        mqtt: string;
    };
    capabilities: TEUDAUpgradePackageDeviceCapability[];
    perks: IEUDAUpgradePackageDevicePerk[];
}

enum EUpgradeIssueSeverity {
    Warning = 'warning',
    Error = 'error'
}

interface IIssuesUpgrade {
    code: string;
    fields: string[];
    severity: EUpgradeIssueSeverity;
    meta: string;
}

interface IUpgradeFile {
    key: string;
    s3Url: string;
    hash: string;
    firmwareVersion: string;
}

/**
 * Represents a full version of the upgrade, extending the short version with additional details.
 *
 * @property {IUpgradeDevice[]} content - The list of devices included in the upgrade.
 */
interface IUpgradePackage extends IUpgradePackageSummary {
    content: IUpgradeDevice[];
    issues: IIssuesUpgrade[];
    createdAt: string;
    updatedAt: string;
}

enum EUpgradeDeviceStatus {
    InProgress = 'in-progress',
    Queued = 'queued',
    Success = 'success',
    TimedOut = 'timed-out',
    Failed = 'failed',
    Canceled = 'canceled',
    Rejected = 'rejected',
    Removed = 'removed'
}

/**
 * Represents the progress of an upgrade for a specific device in an elevator.
 * This interface is used for WebSocket communication to track the real-time status of the upgrade process.
 *
 * @property {string} elevatorId - The unique identifier of the elevator.
 * @property {string} deviceId - The unique identifier of the device.
 * @property {number} progress - The progress of the upgrade, represented as a percentage (0-100).
 * @property {EUpgradeStatus} status - The current status of the upgrade process.
 */
interface IUpgradeDeviceProgress {
    elevatorId: string;
    deviceId: string;
    progress?: number;
    status?: EUpgradeDeviceStatus;
}

/**
 * Enum representing the different statuses of an upgrade.
 *
 * @enum {string}
 */
enum EUpgradeStatus {
    /** Indicates a new update is available */
    New = 'new-update',
    /** Indicates the update is currently in progress */
    InProgress = 'update-in-progress',
    /** Indicates there are no updates available */
    None = 'no-updates'
}

/**
 * Represents the status check of an upgrade package.
 *
 * @property {EUpgradeStatus} status - The current status of the upgrade.
 * @property {IUpgradeDeviceProgress[]} [devices] - The list of devices and their progress in the upgrade process.
 */
interface IUpgradePackageCheck {
    status: EUpgradeStatus;
    upgradeId?: string;
    family?: string;
    sequence?: number;
    devices?: IUpgradeDeviceProgress[];
}

interface IWSUpgradeDeviceProgress extends IUpgradeDeviceProgress {
    elevatorId: string;
}

export type {
    IEUDAUpgradePackage,
    IEUDAUpgradePackageDevicePerk,
    IEUDAUpgradeSupport,
    IUpgradeDevice,
    IUpgradeDeviceCount,
    IUpgradeDeviceProgress,
    IUpgradeFile,
    IUpgradePackage,
    IUpgradePackageCheck,
    IUpgradePackageSummary,
    IWSUpgradeDeviceProgress,
    TEUDAUpgradePackageDeviceCapability
};
export {EUpgradeChannel, EUpgradeDeviceStatus, EUpgradeState, EUpgradeStatus};
