import type {
    EEntityType,
    IElevatorGroupSummary,
    IElevatorSummary,
    IEntityInviteEntry,
    IResolvedPermissions,
    ISiteActivityEntry,
    ISiteGeneralSettings,
    ISiteProfile,
    ISiteState,
    ISiteStats,
    ISiteSummary,
    ITeamMembership,
    IUserMembership,
    TMileageTotalKPI
} from '@mcal/core';
import {
    defaultSiteGeneralSettings,
    defaultSiteProfile,
    defaultSiteState,
    defaultSiteSummary
} from '@mcal/core';
import type {
    ISliceRemote,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TGlobalTeam = ITeamMembership<EEntityType.ServiceCompany>[];
type TTeam = ITeamMembership<EEntityType.Site>[];

type TInvites = IEntityInviteEntry<EEntityType.Site>[];

interface ISiteSliceState {
    status: TEntitySliceStatus;
    remotes: {
        siteSummary: ISliceRemote<ISiteSummary>;
        siteState: ISliceRemote<ISiteState>;
        siteProfile: ISliceRemote<ISiteProfile>;
        siteGeneralSettings: ISliceRemote<ISiteGeneralSettings>;
        elevatorGroups: ISliceRemote<IElevatorGroupSummary[]>;
        elevators: ISliceRemote<IElevatorSummary[]>;
        activity: ISliceRemote<{
            currentCursor: string | null;
            nextCursor: string | null;
            data: ISiteActivityEntry[];
        }>;
        kpis: ISliceRemote<{
            mileageTotal: TMileageTotalKPI | null;
        }>;
        globalTeams: ISliceRemote<Record<string, TGlobalTeam>>;
        globalMemberships: ISliceRemote<Record<string, IUserMembership[]>>;
        memberships: ISliceRemote<Record<string, IUserMembership[]>>;
        teams: ISliceRemote<Record<string, TTeam>>;
        invites: ISliceRemote<TInvites>;
        permissions: ISliceRemote<IResolvedPermissions>;
        stats: ISliceRemote<ISiteStats>;
    };
}

const initialState = {
    status: 'INIT',
    remotes: {
        siteSummary: createRemote(defaultSiteSummary),
        siteState: createRemote(defaultSiteState),
        siteProfile: createRemote(defaultSiteProfile),
        siteGeneralSettings: createRemote(defaultSiteGeneralSettings),
        elevatorGroups: createRemote([]),
        elevators: createRemote([]),
        activity: createRemote({
            currentCursor: null,
            nextCursor: null,
            data: []
        }),
        kpis: createRemote({
            mileageTotal: null
        }),
        memberships: createRemote({}),
        teams: createRemote({}),
        globalMemberships: createRemote({}),
        globalTeams: createRemote({}),
        invites: createRemote([]),
        permissions: createRemote({
            permissions: [],
            grants: [],
            access: [],
            isOwner: false,
            isPlatformMember: false,
            trace: []
        }),
        stats: createRemote({
            totalElevatorGroups: 0,
            totalElevators: 0,
            totalElevatorEmergencies: 0,
            connectivityElevatorsOnline: 0,
            connectivityElevatorsOffline: 0,
            operationElevatorsNormal: 0,
            operationElevatorsService: 0,
            operationElevatorsSetup: 0,
            healthElevatorsHealthy: 0,
            healthElevatorsFaulty: 0
        })
    }
} satisfies ISiteSliceState as ISiteSliceState;

export type {ISiteSliceState, TInvites};
export {initialState};
