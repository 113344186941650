import type {
    TAPIResponseSuccess,
    TInvitesCancelAPI,
    TInvitesListEntityInvitesAPI,
    TInvitesListUserInvitesAPI,
    TInvitesRespondAPI,
    TInvitesSendAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './invites.services.js';

const sendInvite = createAsyncThunk<
    TAPIResponseSuccess<TInvitesSendAPI>,
    TAPIAsyncThunkArgs<TInvitesSendAPI>,
    TAPIAsyncThunkConfig<TInvitesSendAPI>
>('invites/send', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .sendInvite(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        entityId: args.entityId,
                        assignments: args.assignments
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const cancelInvite = createAsyncThunk<
    TAPIResponseSuccess<TInvitesCancelAPI>,
    TAPIAsyncThunkArgs<TInvitesCancelAPI>,
    TAPIAsyncThunkConfig<TInvitesCancelAPI>
>('invites/cancel', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .cancelInvite(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        inviteId: args.inviteId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const respondInvite = createAsyncThunk<
    TAPIResponseSuccess<TInvitesRespondAPI>,
    TAPIAsyncThunkArgs<TInvitesRespondAPI>,
    TAPIAsyncThunkConfig<TInvitesRespondAPI>
>('invites/respond', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .respondInvite(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        inviteId: args.inviteId,
                        result: args.result
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listEntityInvites = createAsyncThunk<
    TAPIResponseSuccess<TInvitesListEntityInvitesAPI>,
    TAPIAsyncThunkArgs<TInvitesListEntityInvitesAPI>,
    TAPIAsyncThunkConfig<TInvitesListEntityInvitesAPI>
>('invites/listEntityInvites', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listEntityInvites(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    entityId: args.entityId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listUserInvites = createAsyncThunk<
    TAPIResponseSuccess<TInvitesListUserInvitesAPI>,
    TAPIAsyncThunkArgs<TInvitesListUserInvitesAPI>,
    TAPIAsyncThunkConfig<TInvitesListUserInvitesAPI>
>('invites/listUserInvites', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listUserInvites(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    cancelInvite,
    listEntityInvites,
    listUserInvites,
    respondInvite,
    sendInvite
};
