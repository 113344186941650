import type {
    EElevatorOperationMode,
    EElevatorOverallStatus,
    IElevatorAnomaly,
    IElevatorIssue,
    IElevatorStatusReport,
    IElevatorTrip,
    IIoTStateRecord,
    IWSUpgradeDeviceProgress,
    TUnknownWSServerMessage,
    TWSMessageElevatorEmergencyRestored
} from '@mcal/core';
import {
    EElevatorDoorStatus,
    EElevatorLightStatus,
    EEntityType,
    EWSChannel,
    EWSClientMessageType,
    EWSServerMessageType
} from '@mcal/core';
import type {
    IDispatch,
    IDispatchExtEmpty,
    IPartialState
} from '@mcal/core-react';
import {
    elevatorActions,
    getSocketInstance,
    siteActions,
    siteThunks,
    socketsActions
} from '@mcal/core-react';
import type {Middleware} from '@reduxjs/toolkit';

let socketInstance: WebSocket | null = null;

const getOrCreateSocket = async (): Promise<WebSocket | null> => {
    if (!socketInstance) {
        socketInstance = await getSocketInstance(null);
    }
    return socketInstance;
};

const createMessageHandler =
    (
        store: {getState: () => IPartialState; dispatch: IDispatch},
        siteId?: string
    ) =>
    (event: MessageEvent<string>): void => {
        const state = store.getState();

        if (!state.elevator) {
            return;
        }

        const data = JSON.parse(event.data) as TUnknownWSServerMessage;

        const destinations = state.elevator.remotes.destinations.current;
        const stops = state.elevator.remotes.stops.current;

        switch (data.type) {
            case EWSServerMessageType.ElevatorTripReported:
            case EWSServerMessageType.ElevatorTripStarted:
            case EWSServerMessageType.ElevatorTripEnded: {
                const payload = data.payload as IElevatorTrip;

                const foundStop = stops.find(
                    (stop) =>
                        stop.key === payload.pos &&
                        stop.elevatorId === payload.elevatorId
                );

                if (foundStop) {
                    const foundDest = destinations.find(
                        (destination) =>
                            destination.stopId === foundStop.stopId &&
                            destination.elevatorId === payload.elevatorId
                    );

                    if (foundDest) {
                        void store.dispatch(
                            elevatorActions.setCurrentDestination(foundDest)
                        );

                        void store.dispatch(
                            siteActions.updateSiteElevatorFloor(foundDest)
                        );

                        void store.dispatch(
                            elevatorActions.updateTrips(payload)
                        );
                    }
                }

                break;
            }

            case EWSServerMessageType.ElevatorIssueStarted:
            case EWSServerMessageType.ElevatorIssueReported:
            case EWSServerMessageType.ElevatorIssueEnded: {
                const payload = data.payload as IElevatorIssue;

                void store.dispatch(elevatorActions.updateIssues(payload));

                break;
            }

            case EWSServerMessageType.ElevatorAnomalyReported: {
                const payload = data.payload as IElevatorAnomaly;

                void store.dispatch(elevatorActions.updateAnomalies(payload));

                break;
            }

            case EWSServerMessageType.ElevatorStatusChanged: {
                const payload = {
                    ...(data.payload as Partial<IElevatorStatusReport>),
                    elevatorId: data.entityId as string
                };

                void store.dispatch(elevatorActions.updateSummary(payload));
                void store.dispatch(elevatorActions.updateState(payload));

                void store.dispatch(
                    siteActions.updateSiteElevatorStatus(payload)
                );

                break;
            }

            case EWSServerMessageType.ElevatorGeneralSettingsUpdated: {
                const wsPayload = data.payload as {
                    operationMode: IIoTStateRecord<EElevatorOperationMode>;
                    remoteControl: IIoTStateRecord<boolean>;
                };
                const payload = {
                    ...wsPayload,
                    elevatorId: data.entityId as string
                };

                void store.dispatch(
                    elevatorActions.updateReportedGeneralSettings(payload)
                );
                void store.dispatch(
                    siteActions.updateReportedGeneralSettings(payload)
                );

                break;
            }

            case EWSServerMessageType.ElevatorEmergencyRestored: {
                const payload = (data as TWSMessageElevatorEmergencyRestored)
                    .payload;

                if (payload.state) {
                    void store.dispatch(
                        elevatorActions.updateState(payload.state)
                    );
                    void store.dispatch(
                        elevatorActions.updateSummary(payload.state)
                    );

                    void store.dispatch(
                        siteActions.updateSiteElevatorStatus(payload.state)
                    );
                }

                break;
            }
            case EWSServerMessageType.ElevatorEmergencyReported: {
                const payload = data.payload as {
                    elevatorId: string;
                    elevatorStatus: EElevatorOverallStatus;
                    operationMode: EElevatorOperationMode;
                };

                void store.dispatch(elevatorActions.updateState(payload));
                void store.dispatch(elevatorActions.updateSummary(payload));

                void store.dispatch(
                    siteActions.updateSiteElevatorStatus(payload)
                );

                break;
            }

            case EWSServerMessageType.ElevatorRegistered: {
                if (!siteId) return;

                void store.dispatch(
                    siteThunks.listElevators({
                        scope: EEntityType.Site,
                        target: siteId
                    })
                );
                void store.dispatch(
                    siteThunks.listElevatorGroups({
                        scope: EEntityType.Site,
                        target: siteId
                    })
                );
                break;
            }

            case EWSServerMessageType.ElevatorDeviceStatusChanged: {
                const payload = data.payload as IWSUpgradeDeviceProgress;

                void store.dispatch(
                    elevatorActions.updateUpgradeDeviceProgress(payload)
                );

                break;
            }

            // LIGHTS

            case EWSServerMessageType.ElevatorLightOff: {
                void store.dispatch(
                    elevatorActions.updateState({
                        lightStatus: EElevatorLightStatus.Off,
                        elevatorId: data.entityId as string
                    })
                );

                break;
            }

            case EWSServerMessageType.ElevatorLightOn: {
                void store.dispatch(
                    elevatorActions.updateState({
                        lightStatus: EElevatorLightStatus.On,
                        elevatorId: data.entityId as string
                    })
                );

                break;
            }

            // DOORS

            case EWSServerMessageType.ElevatorDoorOpened: {
                void store.dispatch(
                    elevatorActions.updateState({
                        doorAStatus: EElevatorDoorStatus.Open,
                        elevatorId: data.entityId as string
                    })
                );

                break;
            }

            case EWSServerMessageType.ElevatorDoorClosed: {
                void store.dispatch(
                    elevatorActions.updateState({
                        doorAStatus: EElevatorDoorStatus.Closed,
                        elevatorId: data.entityId as string
                    })
                );

                break;
            }

            case EWSServerMessageType.UpgradeDeviceProgress: {
                const payload = data.payload as IWSUpgradeDeviceProgress;

                void store.dispatch(
                    elevatorActions.updateUpgradeDeviceProgress(payload)
                );

                break;
            }

            default:
                break;
        }
    };

const socketsMiddleware: Middleware<
    IDispatchExtEmpty,
    IPartialState,
    IDispatch
> = (store) => (next) => (action) => {
    const originalPromise = next(action);

    if (socketsActions.subscribeToElevatorStatus.match(action)) {
        void getOrCreateSocket().then((socket) => {
            if (!socket) return;

            const state = store.getState();
            if (!state.sockets || !state.elevator) return;

            const elevatorId = action.payload;
            const elevatorSubscriptions =
                state.sockets.remotes.elevatorStatusSubscriptions.current;

            if (!elevatorSubscriptions.includes(elevatorId)) {
                void store.dispatch(
                    socketsActions.addElevatorSubscription(elevatorId)
                );

                socket.send(
                    JSON.stringify({
                        id: Date.now().toString(),
                        type: EWSClientMessageType.Subscribe,
                        timestamp: Date.now(),
                        channel: EWSChannel.Connection,
                        entityId: elevatorId,
                        payload: {
                            subscriptions: [
                                {
                                    entityId: elevatorId,
                                    channels: [EWSChannel.ElevatorState]
                                }
                            ]
                        }
                    })
                );

                socket.addEventListener('message', createMessageHandler(store));
            }
        });
    } else if (socketsActions.subscribeToSiteStatus.match(action)) {
        void getOrCreateSocket().then((socket) => {
            if (!socket) return;

            const state = store.getState();
            if (!state.sockets) return;

            if (!state.sockets || !state.elevator) return;

            const siteId = action.payload;
            const siteSubscriptions =
                state.sockets.remotes.siteStatusSubscriptions.current;

            if (!siteSubscriptions.includes(siteId)) {
                void store.dispatch(socketsActions.addSiteSubscription(siteId));

                socket.send(
                    JSON.stringify({
                        id: Date.now().toString(),
                        type: EWSClientMessageType.Subscribe,
                        timestamp: Date.now(),
                        channel: EWSChannel.Connection,
                        entityId: siteId,
                        payload: {
                            subscriptions: [
                                {
                                    entityId: siteId,
                                    channels: [EWSChannel.SiteState]
                                }
                            ]
                        }
                    })
                );

                socket.addEventListener(
                    'message',
                    createMessageHandler(store, siteId)
                );
            }
        });
    } else if (
        socketsActions.subscribeToElevatorDeviceUpgradeProgress.match(action)
    ) {
        void getOrCreateSocket().then((socket) => {
            if (!socket) return;

            const state = store.getState();
            if (!state.sockets || !state.elevator) return;

            const upgradeDeviceId = action.payload;
            const upgradeDeviceSubscriptions =
                state.sockets.remotes.upgradeDeviceStatusSubscriptions.current;

            if (!upgradeDeviceSubscriptions.includes(upgradeDeviceId)) {
                void store.dispatch(
                    socketsActions.addElevatorDeviceUpgradeProgress(
                        upgradeDeviceId
                    )
                );

                socket.send(
                    JSON.stringify({
                        id: Date.now().toString(),
                        type: EWSClientMessageType.Subscribe,
                        timestamp: Date.now(),
                        channel: EWSChannel.Connection,
                        entityId: upgradeDeviceId,
                        payload: {
                            subscriptions: [
                                {
                                    entityId: upgradeDeviceId,
                                    channels: [EWSChannel.ElevatorState]
                                }
                            ]
                        }
                    })
                );

                socket.addEventListener('message', createMessageHandler(store));
            }
        });
    }

    return originalPromise;
};

export {socketsMiddleware};
