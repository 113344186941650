import {useTheme} from '@mcal/core-react';
import type {FC} from 'react';
import {useMemo} from 'react';

interface IColors {
    colorA: string;
    colorB: string;
}

interface ICallSvgProps {
    height?: number | string;
    width?: number | string;
    fill?: string;
}

const CallSvg: FC<ICallSvgProps> = ({fill, ...props}) => {
    const {type} = useTheme();

    const {colorA, colorB} = useMemo<IColors>(() => {
        if (type === 'dark') {
            return {
                colorA: '#AAAEB4',
                colorB: '#D9D9D9'
            };
        } else {
            return {
                colorA: '#555C69',
                colorB: '#D9D9D9'
            };
        }
    }, [type]);

    return (
        <svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={'20'}
            height={'20'}
            viewBox={'0 0 20 20'}
            fill={'none'}
            {...props}
        >
            <mask
                id={'mask0_71_3956'}
                maskUnits={'userSpaceOnUse'}
                x={'0'}
                y={'0'}
                width={'20'}
                height={'20'}
            >
                <rect width={'20'} height={'20'} fill={colorB} />
            </mask>
            <g mask={'url(#mask0_71_3956)'}>
                <path
                    d={
                        'M15.8959 16.9792C14.2153 16.8542 12.6216 16.4411 11.1146 15.7397C9.60769 15.0383 8.25699 14.0904 7.06255 12.8959C5.8681 11.7015 4.92366 10.3508 4.22921 8.84383C3.53477 7.33689 3.12505 5.74314 3.00005 4.06258C2.97227 3.77091 3.05907 3.51744 3.26046 3.30216C3.46185 3.08689 3.70838 2.97925 4.00005 2.97925H6.83338C7.06949 2.97925 7.27435 3.05216 7.44796 3.198C7.62157 3.34383 7.73616 3.52786 7.79171 3.75008L8.29171 5.97925C8.31949 6.1598 8.30907 6.33341 8.26046 6.50008C8.21185 6.66675 8.13199 6.80564 8.02088 6.91675L6.00005 8.95842C6.27782 9.48619 6.59727 9.99314 6.95838 10.4792C7.31949 10.9654 7.72227 11.4237 8.16671 11.8542C8.58338 12.2709 9.02782 12.6563 9.50005 13.0105C9.97227 13.3647 10.4723 13.6806 11 13.9584L13.0625 11.9584C13.1737 11.8473 13.3125 11.7674 13.4792 11.7188C13.6459 11.6702 13.8195 11.6598 14 11.6876L16.2292 12.1667C16.4653 12.2362 16.6528 12.3577 16.7917 12.5313C16.9306 12.7049 17 12.9098 17 13.1459V15.9792C17 16.2709 16.8889 16.5174 16.6667 16.7188C16.4445 16.9202 16.1875 17.007 15.8959 16.9792Z'
                    }
                    fill={fill || colorA}
                />
            </g>
        </svg>
    );
};

export {CallSvg};
