import type {TWSMessageType} from '../common/ws.types.js';

enum EWSServerMessageType {
    // CONNECTION
    Ping = 'server-ping',
    Pong = 'server-pong',
    Error = 'server-error',
    Handover = 'server-handover',
    Subscriptions = 'server-subscriptions',

    // ELEVATOR
    ElevatorTripStarted = 'ElevatorTripStarted',
    ElevatorTripReported = 'ElevatorTripReported',
    ElevatorTripEnded = 'ElevatorTripEnded',
    ElevatorDoorOpened = 'ElevatorDoorOpened',
    ElevatorDoorClosed = 'ElevatorDoorClosed',
    ElevatorLightOn = 'ElevatorLightOn',
    ElevatorLightOff = 'ElevatorLightOff',
    ElevatorIssueStarted = 'ElevatorIssueStarted',
    ElevatorIssueReported = 'ElevatorIssueReported',
    ElevatorIssueEnded = 'ElevatorIssueEnded',
    ElevatorStatusChanged = 'ElevatorStatusChanged',
    ElevatorDeviceStatusChanged = 'ElevatorDeviceStatusChanged',
    ElevatorAnomalyReported = 'ElevatorAnomalyReported',
    ElevatorOperationModeChanged = 'ElevatorOperationModeChanged',
    ElevatorShadowUpdated = 'ElevatorShadowUpdated',
    ElevatorDeviceICUDUpdated = 'ElevatorDeviceICUDUpdated',
    ElevatorDeviceHUFDUpdated = 'ElevatorDeviceHUFDUpdated',
    ElevatorDeviceSensorsUpdated = 'ElevatorDeviceSensorsUpdated',
    ElevatorEmergencyAcknowledge = 'ElevatorEmergencyAcknowledge',
    ElevatorEmergencyRestored = 'ElevatorEmergencyRestored',
    ElevatorEmergencyReported = 'ElevatorEmergencyReported',
    ElevatorGeneralSettingsUpdated = 'ElevatorGeneralSettingsUpdated',

    // SITE
    ElevatorRegistered = 'ElevatorRegistered',

    // UPGRADES
    UpgradeDeviceProgress = 'UpgradeDeviceProgress',

    // KPIS
    KPIRTReported = 'KPIRTReported'
}

enum EWSClientMessageType {
    // CONNECTION
    Ping = 'client-ping',
    Pong = 'client-pong',
    Restore = 'client-restore',
    Subscribe = 'client-subscribe',
    Unsubscribe = 'client-unsubscribe',
    Subscriptions = 'client-subscriptions'
}

enum EWSChannel {
    Connection = 'Connection',
    ElevatorState = 'ElevatorState',
    ElevatorKpis = 'ElevatorKpis',
    ElevatorSetup = 'ElevatorSetup',
    SiteState = 'SiteState'
}

const WS_MESSAGE_CHANNEL_MAP = {
    // CONNECTION (SERVER)
    [EWSServerMessageType.Ping]: EWSChannel.Connection,
    [EWSServerMessageType.Pong]: EWSChannel.Connection,
    [EWSServerMessageType.Error]: EWSChannel.Connection,
    [EWSServerMessageType.Handover]: EWSChannel.Connection,
    [EWSServerMessageType.Subscriptions]: EWSChannel.Connection,

    // CONNECTION (CLIENT)
    [EWSClientMessageType.Ping]: EWSChannel.Connection,
    [EWSClientMessageType.Pong]: EWSChannel.Connection,
    [EWSClientMessageType.Restore]: EWSChannel.Connection,
    [EWSClientMessageType.Subscribe]: EWSChannel.Connection,
    [EWSClientMessageType.Unsubscribe]: EWSChannel.Connection,
    [EWSClientMessageType.Subscriptions]: EWSChannel.Connection,

    // ELEVATOR
    [EWSServerMessageType.ElevatorStatusChanged]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorTripStarted]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorTripReported]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorTripEnded]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDoorOpened]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDoorClosed]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorLightOn]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorLightOff]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorIssueStarted]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorIssueReported]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorIssueEnded]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorAnomalyReported]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorOperationModeChanged]:
        EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorShadowUpdated]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDeviceICUDUpdated]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDeviceHUFDUpdated]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDeviceSensorsUpdated]:
        EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorEmergencyAcknowledge]:
        EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorEmergencyRestored]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorEmergencyReported]: EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorGeneralSettingsUpdated]:
        EWSChannel.ElevatorState,
    [EWSServerMessageType.ElevatorDeviceStatusChanged]:
        EWSChannel.ElevatorState,

    // SITE
    [EWSServerMessageType.ElevatorRegistered]: EWSChannel.SiteState,

    // UPGRADES
    [EWSServerMessageType.UpgradeDeviceProgress]: EWSChannel.ElevatorState,

    // KPIS
    [EWSServerMessageType.KPIRTReported]: EWSChannel.ElevatorKpis
} as const satisfies Record<TWSMessageType, EWSChannel>;

export {
    EWSChannel,
    EWSClientMessageType,
    EWSServerMessageType,
    WS_MESSAGE_CHANNEL_MAP
};
