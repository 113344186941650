/**
 * Represents any platform entity.
 */
enum EEntityType {
    Platform = 'mcal-entity-platform',
    ServiceCompany = 'mcal-entity-service-company',
    Site = 'mcal-entity-site',
    ElevatorGroup = 'mcal-entity-elevator-group',
    Elevator = 'mcal-entity-elevator'
}

/**
 * Represents the status of an entity.
 */
enum EEntityStatus {
    Active = 'mcal-entity-status-active',
    Disabled = 'mcal-entity-status-disabled',
    Pending = 'mcal-entity-status-pending',
    Blocked = 'mcal-entity-status-blocked',
    Inactive = 'mcal-entity-status-inactive',
    Deleted = 'mcal-entity-status-deleted'
}

/**
 * Represents the type for an individual.
 */
enum EIndividualType {
    User = 'mcal-individual-user',
    TechnicianProfile = 'mcal-individual-technician-profile'
}

/**
 * Represents the status of an individual.
 */
enum EIndividualStatus {
    Active = 'mcal-individual-status-active',
    Disabled = 'mcal-individual-status-disabled',
    Pending = 'mcal-individual-status-pending',
    Blocked = 'mcal-individual-status-blocked',
    Inactive = 'mcal-individual-status-inactive',
    Deleted = 'mcal-individual-status-deleted'
}

/**
 * The list of all possible permissions at the Platform level.
 */
enum EPlatformPermission {
    UsersView = 'mcal-platform-users-view',
    UsersEdit = 'mcal-platform-users-edit',
    UsersDelete = 'mcal-platform-users-delete',
    TeamView = 'mcal-platform-team-view',
    TeamEdit = 'mcal-platform-team-edit',
    TeamCreate = 'mcal-platform-team-create',
    TeamDelete = 'mcal-platform-team-delete',
    StatsView = 'mcal-platform-stats-view'
}

/**
 * The list of all possible permissions at the Service Company level.
 */
enum EServiceCompanyPermission {
    SettingsGeneralView = 'mcal-service-company-settings-general-view',
    SettingsGeneralEdit = 'mcal-service-company-settings-general-edit',
    SettingsDocumentsView = 'mcal-service-company-settings-documents-view',
    SettingsDocumentsEdit = 'mcal-service-company-settings-documents-edit',
    SettingsNotificationsView = 'mcal-service-company-settings-notifications-view',
    SettingsNotificationsEdit = 'mcal-service-company-settings-notifications-edit',
    SettingsFeatureModulesView = 'mcal-service-company-settings-feature-modules-view',
    SettingsFeatureModulesEdit = 'mcal-service-company-settings-feature-modules-edit',
    SettingsBillingView = 'mcal-service-company-settings-billing-view',
    SettingsBillingEdit = 'mcal-service-company-settings-billing-edit',
    ProfileView = 'mcal-service-company-profile-view',
    ProfileEdit = 'mcal-service-company-profile-edit',
    TeamView = 'mcal-service-company-team-view',
    TeamEdit = 'mcal-service-company-team-edit',
    TeamCreate = 'mcal-service-company-team-create',
    TeamDelete = 'mcal-service-company-team-delete',
    SiteCreate = 'mcal-service-company-site-create',
    SiteList = 'mcal-service-company-site-list',
    TechnicalParametersTemplatesView = 'mcal-service-company-technical-parameters-template-general-view',
    TechnicalParametersTemplatesEdit = 'mcal-service-company-technical-parameters-template-general-edit',
    Delete = 'mcal-service-company-delete',
    StatsView = 'mcal-service-company-stats-view'
}

/**
 * The list of all possible permissions at the Site level.
 */
enum ESitePermission {
    SettingsGeneralView = 'mcal-site-settings-general-view',
    SettingsGeneralEdit = 'mcal-site-settings-general-edit',
    SettingsNotificationsView = 'mcal-site-settings-notifications-view',
    SettingsNotificationsEdit = 'mcal-site-settings-notifications-edit',
    SettingsFeatureModulesView = 'mcal-site-settings-feature-modules-view',
    SettingsFeatureModulesEdit = 'mcal-site-settings-feature-modules-edit',
    SettingsBillingView = 'mcal-site-settings-billing-view',
    SettingsBillingEdit = 'mcal-site-settings-billing-edit',
    ProfileView = 'mcal-site-profile-view',
    ProfileEdit = 'mcal-site-profile-edit',
    TeamView = 'mcal-site-team-view',
    TeamEdit = 'mcal-site-team-edit',
    TeamCreate = 'mcal-site-team-create',
    TeamDelete = 'mcal-site-team-delete',
    TeamInheritanceEdit = 'mcal-site-team-inheritance-edit',
    ElevatorGroupCreate = 'mcal-site-elevator-group-create',
    ElevatorGroupDelete = 'mcal-site-elevator-group-delete',
    ElevatorGroupEdit = 'mcal-site-elevator-group-edit',
    Delete = 'mcal-site-delete',
    StatsView = 'mcal-site-stats-view'
}

/**
 * The list of all possible permissions at the Elevator Group level.
 */
enum EElevatorGroupPermission {
    ProfileView = 'mcal-elevator-group-profile-view',
    ProfileEdit = 'mcal-elevator-group-profile-edit',
    TeamView = 'mcal-elevator-group-team-view',
    TeamEdit = 'mcal-elevator-group-team-edit',
    TeamCreate = 'mcal-elevator-group-team-create',
    TeamDelete = 'mcal-elevator-group-team-delete',
    TeamInheritanceEdit = 'mcal-elevator-group-team-inheritance-edit',
    ElevatorCreate = 'mcal-elevator-group-elevator-create',
    Delete = 'mcal-elevator-group-delete'
}

/**
 * The list of all possible permissions at the Elevator level.
 */
enum EElevatorPermission {
    ProfileView = 'mcal-elevator-profile-view',
    ProfileEdit = 'mcal-elevator-profile-edit',
    TeamView = 'mcal-elevator-team-view',
    TeamEdit = 'mcal-elevator-team-edit',
    TeamCreate = 'mcal-elevator-team-create',
    TeamDelete = 'mcal-elevator-team-delete',
    TeamInheritanceEdit = 'mcal-elevator-team-inheritance-edit',
    FloorsConfigView = 'mcal-elevator-floors-config-view',
    FloorsConfigEdit = 'mcal-elevator-floors-config-edit',
    TechnicalParametersView = 'mcal-elevator-technical-parameters-view',
    TechnicalParametersEdit = 'mcal-elevator-technical-parameters-edit',
    RemoteServiceControlToggleEdit = 'mcal-elevator-remote-service-control-toggle-edit',
    RemoteServiceControlEdit = 'mcal-elevator-remote-service-control-edit',
    LocalServiceControlEdit = 'mcal-elevator-local-service-control-edit',
    FullHandoffToggleEdit = 'mcal-elevator-full-handoff-toggle-edit',
    ServiceRoutinesEdit = 'mcal-elevator-service-routines-edit',
    KpisCustomEdit = 'mcal-elevator-kpis-custom-edit',
    RebootEdit = 'mcal-elevator-reboot-edit',
    UpgradeEdit = 'mcal-elevator-upgrade-edit',
    RestoreEdit = 'mcal-elevator-restore-edit',
    ServiceModeEdit = 'mcal-elevator-service-mode-edit',
    Delete = 'mcal-elevator-delete',
    DeviceCreate = 'mcal-elevator-device-create'
}

/**
 * Represents any permission.
 */
type TPermission =
    | EPlatformPermission
    | EServiceCompanyPermission
    | ESitePermission
    | EElevatorGroupPermission
    | EElevatorPermission;

/**
 * Maps each entity type to its corresponding permissions.
 */
interface IPermissionsMap {
    [EEntityType.Platform]: EPlatformPermission;
    [EEntityType.ServiceCompany]: EServiceCompanyPermission;
    [EEntityType.Site]: ESitePermission;
    [EEntityType.ElevatorGroup]: EElevatorGroupPermission;
    [EEntityType.Elevator]: EElevatorPermission;
}

/**
 * Maps each entity type to its corresponding permissions.
 */
const PERMISSIONS_MAP = {
    [EEntityType.Platform]: EPlatformPermission,
    [EEntityType.ServiceCompany]: EServiceCompanyPermission,
    [EEntityType.Site]: ESitePermission,
    [EEntityType.ElevatorGroup]: EElevatorGroupPermission,
    [EEntityType.Elevator]: EElevatorPermission
} as const;

/**
 * Describes a permission.
 */
interface IPermissionDescriptor {
    key: TPermission;
    entityType: EEntityType;
}

type TStrictPermissionDescriptors = {
    [T in TPermission]: {
        key: T;
        entityType: {
            [K in keyof IPermissionsMap]: T extends IPermissionsMap[K]
                ? K
                : never;
        }[keyof IPermissionsMap];
    };
};

/**
 * Every permission descriptor.
 */
const PERMISSION = {
    [EPlatformPermission.UsersView]: {
        key: EPlatformPermission.UsersView,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.UsersEdit]: {
        key: EPlatformPermission.UsersEdit,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.UsersDelete]: {
        key: EPlatformPermission.UsersDelete,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.TeamView]: {
        key: EPlatformPermission.TeamView,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.TeamEdit]: {
        key: EPlatformPermission.TeamEdit,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.TeamCreate]: {
        key: EPlatformPermission.TeamCreate,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.TeamDelete]: {
        key: EPlatformPermission.TeamDelete,
        entityType: EEntityType.Platform
    },
    [EPlatformPermission.StatsView]: {
        key: EPlatformPermission.StatsView,
        entityType: EEntityType.Platform
    },

    [EServiceCompanyPermission.SettingsGeneralView]: {
        key: EServiceCompanyPermission.SettingsGeneralView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsGeneralEdit]: {
        key: EServiceCompanyPermission.SettingsGeneralEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsDocumentsView]: {
        key: EServiceCompanyPermission.SettingsDocumentsView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsDocumentsEdit]: {
        key: EServiceCompanyPermission.SettingsDocumentsEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsNotificationsView]: {
        key: EServiceCompanyPermission.SettingsNotificationsView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsNotificationsEdit]: {
        key: EServiceCompanyPermission.SettingsNotificationsEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsFeatureModulesView]: {
        key: EServiceCompanyPermission.SettingsFeatureModulesView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsFeatureModulesEdit]: {
        key: EServiceCompanyPermission.SettingsFeatureModulesEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsBillingView]: {
        key: EServiceCompanyPermission.SettingsBillingView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SettingsBillingEdit]: {
        key: EServiceCompanyPermission.SettingsBillingEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.ProfileView]: {
        key: EServiceCompanyPermission.ProfileView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.ProfileEdit]: {
        key: EServiceCompanyPermission.ProfileEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TeamView]: {
        key: EServiceCompanyPermission.TeamView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TeamEdit]: {
        key: EServiceCompanyPermission.TeamEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TeamCreate]: {
        key: EServiceCompanyPermission.TeamCreate,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TeamDelete]: {
        key: EServiceCompanyPermission.TeamDelete,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SiteCreate]: {
        key: EServiceCompanyPermission.SiteCreate,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.SiteList]: {
        key: EServiceCompanyPermission.SiteList,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TechnicalParametersTemplatesView]: {
        key: EServiceCompanyPermission.TechnicalParametersTemplatesView,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.TechnicalParametersTemplatesEdit]: {
        key: EServiceCompanyPermission.TechnicalParametersTemplatesEdit,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.Delete]: {
        key: EServiceCompanyPermission.Delete,
        entityType: EEntityType.ServiceCompany
    },
    [EServiceCompanyPermission.StatsView]: {
        key: EServiceCompanyPermission.StatsView,
        entityType: EEntityType.ServiceCompany
    },

    [ESitePermission.SettingsGeneralView]: {
        key: ESitePermission.SettingsGeneralView,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsGeneralEdit]: {
        key: ESitePermission.SettingsGeneralEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsNotificationsView]: {
        key: ESitePermission.SettingsNotificationsView,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsNotificationsEdit]: {
        key: ESitePermission.SettingsNotificationsEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsFeatureModulesView]: {
        key: ESitePermission.SettingsFeatureModulesView,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsFeatureModulesEdit]: {
        key: ESitePermission.SettingsFeatureModulesEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsBillingView]: {
        key: ESitePermission.SettingsBillingView,
        entityType: EEntityType.Site
    },
    [ESitePermission.SettingsBillingEdit]: {
        key: ESitePermission.SettingsBillingEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.ProfileView]: {
        key: ESitePermission.ProfileView,
        entityType: EEntityType.Site
    },
    [ESitePermission.ProfileEdit]: {
        key: ESitePermission.ProfileEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.TeamView]: {
        key: ESitePermission.TeamView,
        entityType: EEntityType.Site
    },
    [ESitePermission.TeamEdit]: {
        key: ESitePermission.TeamEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.TeamCreate]: {
        key: ESitePermission.TeamCreate,
        entityType: EEntityType.Site
    },
    [ESitePermission.TeamDelete]: {
        key: ESitePermission.TeamDelete,
        entityType: EEntityType.Site
    },
    [ESitePermission.TeamInheritanceEdit]: {
        key: ESitePermission.TeamInheritanceEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.ElevatorGroupCreate]: {
        key: ESitePermission.ElevatorGroupCreate,
        entityType: EEntityType.Site
    },
    [ESitePermission.ElevatorGroupEdit]: {
        key: ESitePermission.ElevatorGroupEdit,
        entityType: EEntityType.Site
    },
    [ESitePermission.ElevatorGroupDelete]: {
        key: ESitePermission.ElevatorGroupDelete,
        entityType: EEntityType.Site
    },
    [ESitePermission.Delete]: {
        key: ESitePermission.Delete,
        entityType: EEntityType.Site
    },
    [ESitePermission.StatsView]: {
        key: ESitePermission.StatsView,
        entityType: EEntityType.Site
    },

    [EElevatorGroupPermission.ProfileView]: {
        key: EElevatorGroupPermission.ProfileView,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.ProfileEdit]: {
        key: EElevatorGroupPermission.ProfileEdit,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.TeamView]: {
        key: EElevatorGroupPermission.TeamView,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.TeamEdit]: {
        key: EElevatorGroupPermission.TeamEdit,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.TeamCreate]: {
        key: EElevatorGroupPermission.TeamCreate,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.TeamDelete]: {
        key: EElevatorGroupPermission.TeamDelete,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.TeamInheritanceEdit]: {
        key: EElevatorGroupPermission.TeamInheritanceEdit,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.ElevatorCreate]: {
        key: EElevatorGroupPermission.ElevatorCreate,
        entityType: EEntityType.ElevatorGroup
    },
    [EElevatorGroupPermission.Delete]: {
        key: EElevatorGroupPermission.Delete,
        entityType: EEntityType.ElevatorGroup
    },

    [EElevatorPermission.ProfileView]: {
        key: EElevatorPermission.ProfileView,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.ProfileEdit]: {
        key: EElevatorPermission.ProfileEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TeamView]: {
        key: EElevatorPermission.TeamView,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TeamEdit]: {
        key: EElevatorPermission.TeamEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TeamCreate]: {
        key: EElevatorPermission.TeamCreate,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TeamDelete]: {
        key: EElevatorPermission.TeamDelete,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TeamInheritanceEdit]: {
        key: EElevatorPermission.TeamInheritanceEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.FloorsConfigView]: {
        key: EElevatorPermission.FloorsConfigView,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.FloorsConfigEdit]: {
        key: EElevatorPermission.FloorsConfigEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.Delete]: {
        key: EElevatorPermission.Delete,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.RebootEdit]: {
        key: EElevatorPermission.RebootEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.UpgradeEdit]: {
        key: EElevatorPermission.UpgradeEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.RestoreEdit]: {
        key: EElevatorPermission.RestoreEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.ServiceModeEdit]: {
        key: EElevatorPermission.ServiceModeEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.RemoteServiceControlToggleEdit]: {
        key: EElevatorPermission.RemoteServiceControlToggleEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.RemoteServiceControlEdit]: {
        key: EElevatorPermission.RemoteServiceControlEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.LocalServiceControlEdit]: {
        key: EElevatorPermission.LocalServiceControlEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.FullHandoffToggleEdit]: {
        key: EElevatorPermission.FullHandoffToggleEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.ServiceRoutinesEdit]: {
        key: EElevatorPermission.ServiceRoutinesEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.KpisCustomEdit]: {
        key: EElevatorPermission.KpisCustomEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TechnicalParametersView]: {
        key: EElevatorPermission.TechnicalParametersView,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.TechnicalParametersEdit]: {
        key: EElevatorPermission.TechnicalParametersEdit,
        entityType: EEntityType.Elevator
    },
    [EElevatorPermission.DeviceCreate]: {
        key: EElevatorPermission.DeviceCreate,
        entityType: EEntityType.Elevator
    }
} as const satisfies TStrictPermissionDescriptors;

/**
 * The list of all possible roles at the Platform level.
 */
enum EPlatformRole {
    Owner = 'mcal-platform-owner',
    Administrator = 'mcal-platform-administrator',
    Editor = 'mcal-platform-editor',
    Viewer = 'mcal-platform-viewer'
}

/**
 * The list of all possible roles at the Service Company level.
 */
enum EServiceCompanyRole {
    Owner = 'mcal-service-company-owner',
    Administrator = 'mcal-service-company-administrator',
    Editor = 'mcal-service-company-editor',
    Viewer = 'mcal-service-company-viewer'
}

/**
 * The list of all possible roles at the Site level.
 */
enum ESiteRole {
    Owner = 'mcal-site-owner',
    Administrator = 'mcal-site-administrator',
    Editor = 'mcal-site-editor',
    Viewer = 'mcal-site-viewer',
    TechnicalEditor = 'mcal-site-technical-editor',
    TechnicalViewer = 'mcal-site-technical-viewer'
}

/**
 * The list of all possible roles at the Elevator Group level.
 */
enum EElevatorGroupRole {
    Editor = 'mcal-elevator-group-editor',
    Viewer = 'mcal-elevator-group-viewer',
    TechnicalEditor = 'mcal-elevator-group-technical-editor',
    TechnicalViewer = 'mcal-elevator-group-technical-viewer'
}

/**
 * The list of all possible roles at the Elevator level.
 */
enum EElevatorRole {
    Editor = 'mcal-elevator-editor',
    Viewer = 'mcal-elevator-viewer',
    TechnicalEditor = 'mcal-elevator-technical-editor',
    TechnicalViewer = 'mcal-elevator-technical-viewer'
}

/**
 * Represents any role.
 */
type TRole =
    | EPlatformRole
    | EServiceCompanyRole
    | ESiteRole
    | EElevatorGroupRole
    | EElevatorRole;

/**
 * Maps each entity type to its corresponding roles.
 */
interface IRolesMap {
    [EEntityType.Platform]: EPlatformRole;
    [EEntityType.ServiceCompany]: EServiceCompanyRole;
    [EEntityType.Site]: ESiteRole;
    [EEntityType.ElevatorGroup]: EElevatorGroupRole;
    [EEntityType.Elevator]: EElevatorRole;
}

/**
 * Maps each entity type to its corresponding roles.
 */
const ROLES_MAP = {
    [EEntityType.Platform]: EPlatformRole,
    [EEntityType.ServiceCompany]: EServiceCompanyRole,
    [EEntityType.Site]: ESiteRole,
    [EEntityType.ElevatorGroup]: EElevatorGroupRole,
    [EEntityType.Elevator]: EElevatorRole
} as const;

/**
 * Describes a role.
 */
interface IRoleDescriptor {
    key: TRole;
    entityType: EEntityType;
    readOnly: boolean;
    systemOnly: boolean;
    permissions: IPermissionDescriptor[];
}

type TStrictRoleDescriptors = {
    [T in TRole]: {
        key: T;
        entityType: {
            [K in keyof IRolesMap]: T extends IRolesMap[K] ? K : never;
        }[keyof IRolesMap];
        readOnly: boolean;
        systemOnly: boolean;
        permissions: IPermissionDescriptor[];
    };
};

/**
 * Every role descriptor.
 */
const ROLE = {
    [EPlatformRole.Owner]: {
        key: EPlatformRole.Owner,
        entityType: EEntityType.Platform,
        readOnly: true,
        systemOnly: true,
        permissions: [
            PERMISSION[EPlatformPermission.UsersView],
            PERMISSION[EPlatformPermission.UsersEdit],
            PERMISSION[EPlatformPermission.UsersDelete],
            PERMISSION[EPlatformPermission.TeamView],
            PERMISSION[EPlatformPermission.TeamEdit],
            PERMISSION[EPlatformPermission.TeamCreate],
            PERMISSION[EPlatformPermission.TeamDelete],
            PERMISSION[EPlatformPermission.StatsView],

            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.TeamEdit],
            PERMISSION[EServiceCompanyPermission.TeamCreate],
            PERMISSION[EServiceCompanyPermission.TeamDelete],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],
            PERMISSION[EServiceCompanyPermission.Delete],
            PERMISSION[EServiceCompanyPermission.StatsView],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.TeamInheritanceEdit],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],
            PERMISSION[ESitePermission.ElevatorGroupDelete],
            PERMISSION[ESitePermission.Delete],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],
            PERMISSION[EElevatorGroupPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],
            PERMISSION[EElevatorGroupPermission.Delete],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.FloorsConfigEdit],
            PERMISSION[EElevatorPermission.Delete],
            PERMISSION[EElevatorPermission.RebootEdit],
            PERMISSION[EElevatorPermission.UpgradeEdit],
            PERMISSION[EElevatorPermission.RestoreEdit],
            PERMISSION[EElevatorPermission.ServiceModeEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlToggleEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlEdit],
            PERMISSION[EElevatorPermission.LocalServiceControlEdit],
            PERMISSION[EElevatorPermission.FullHandoffToggleEdit],
            PERMISSION[EElevatorPermission.ServiceRoutinesEdit],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.TechnicalParametersEdit],
            PERMISSION[EElevatorPermission.DeviceCreate]
        ]
    },
    [EPlatformRole.Administrator]: {
        key: EPlatformRole.Administrator,
        entityType: EEntityType.Platform,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EPlatformPermission.UsersView],
            PERMISSION[EPlatformPermission.UsersEdit],
            PERMISSION[EPlatformPermission.UsersDelete],
            PERMISSION[EPlatformPermission.TeamView],
            PERMISSION[EPlatformPermission.TeamEdit],
            PERMISSION[EPlatformPermission.TeamCreate],
            PERMISSION[EPlatformPermission.TeamDelete],
            PERMISSION[EPlatformPermission.StatsView],

            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.TeamEdit],
            PERMISSION[EServiceCompanyPermission.TeamCreate],
            PERMISSION[EServiceCompanyPermission.TeamDelete],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],
            PERMISSION[EServiceCompanyPermission.Delete],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.TeamInheritanceEdit],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],
            PERMISSION[ESitePermission.ElevatorGroupDelete],
            PERMISSION[ESitePermission.Delete],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],
            PERMISSION[EElevatorGroupPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],
            PERMISSION[EElevatorGroupPermission.Delete],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.FloorsConfigEdit],
            PERMISSION[EElevatorPermission.Delete],
            PERMISSION[EElevatorPermission.RebootEdit],
            PERMISSION[EElevatorPermission.UpgradeEdit],
            PERMISSION[EElevatorPermission.RestoreEdit],
            PERMISSION[EElevatorPermission.ServiceModeEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlToggleEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlEdit],
            PERMISSION[EElevatorPermission.LocalServiceControlEdit],
            PERMISSION[EElevatorPermission.FullHandoffToggleEdit],
            PERMISSION[EElevatorPermission.ServiceRoutinesEdit],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.TechnicalParametersEdit]
        ]
    },
    [EPlatformRole.Editor]: {
        key: EPlatformRole.Editor,
        entityType: EEntityType.Platform,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EPlatformPermission.UsersView],
            PERMISSION[EPlatformPermission.UsersEdit],
            PERMISSION[EPlatformPermission.TeamView],
            PERMISSION[EPlatformPermission.TeamEdit],
            PERMISSION[EPlatformPermission.TeamCreate],
            PERMISSION[EPlatformPermission.StatsView],

            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.TeamEdit],
            PERMISSION[EServiceCompanyPermission.TeamCreate],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.TeamInheritanceEdit],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.FloorsConfigEdit],
            PERMISSION[EElevatorPermission.RebootEdit],
            PERMISSION[EElevatorPermission.UpgradeEdit],
            PERMISSION[EElevatorPermission.RestoreEdit],
            PERMISSION[EElevatorPermission.ServiceModeEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlToggleEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlEdit],
            PERMISSION[EElevatorPermission.LocalServiceControlEdit],
            PERMISSION[EElevatorPermission.FullHandoffToggleEdit],
            PERMISSION[EElevatorPermission.ServiceRoutinesEdit],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.TechnicalParametersEdit]
        ]
    },
    [EPlatformRole.Viewer]: {
        key: EPlatformRole.Viewer,
        entityType: EEntityType.Platform,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EPlatformPermission.UsersView],
            PERMISSION[EPlatformPermission.TeamView],
            PERMISSION[EPlatformPermission.StatsView],

            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.TeamView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.TeamView],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },
    [EServiceCompanyRole.Owner]: {
        key: EServiceCompanyRole.Owner,
        entityType: EEntityType.ServiceCompany,
        readOnly: true,
        systemOnly: true,
        permissions: [
            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.TeamEdit],
            PERMISSION[EServiceCompanyPermission.TeamCreate],
            PERMISSION[EServiceCompanyPermission.TeamDelete],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],
            PERMISSION[EServiceCompanyPermission.Delete],
            PERMISSION[EServiceCompanyPermission.StatsView],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.TeamInheritanceEdit],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],
            PERMISSION[ESitePermission.ElevatorGroupDelete],
            PERMISSION[ESitePermission.Delete],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],
            PERMISSION[EElevatorGroupPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],
            PERMISSION[EElevatorGroupPermission.Delete],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.FloorsConfigEdit],
            PERMISSION[EElevatorPermission.Delete],
            PERMISSION[EElevatorPermission.RebootEdit],
            PERMISSION[EElevatorPermission.UpgradeEdit],
            PERMISSION[EElevatorPermission.RestoreEdit],
            PERMISSION[EElevatorPermission.ServiceModeEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlToggleEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlEdit],
            PERMISSION[EElevatorPermission.LocalServiceControlEdit],
            PERMISSION[EElevatorPermission.FullHandoffToggleEdit],
            PERMISSION[EElevatorPermission.ServiceRoutinesEdit],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.TechnicalParametersEdit],
            PERMISSION[EElevatorPermission.DeviceCreate]
        ]
    },
    [EServiceCompanyRole.Administrator]: {
        key: EServiceCompanyRole.Administrator,
        entityType: EEntityType.ServiceCompany,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.TeamEdit],
            PERMISSION[EServiceCompanyPermission.TeamCreate],
            PERMISSION[EServiceCompanyPermission.TeamDelete],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],
            PERMISSION[EServiceCompanyPermission.StatsView],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],
            PERMISSION[ESitePermission.ElevatorGroupDelete],
            PERMISSION[ESitePermission.Delete],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],
            PERMISSION[EElevatorGroupPermission.Delete],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.FloorsConfigEdit],
            PERMISSION[EElevatorPermission.Delete],
            PERMISSION[EElevatorPermission.RebootEdit],
            PERMISSION[EElevatorPermission.UpgradeEdit],
            PERMISSION[EElevatorPermission.RestoreEdit],
            PERMISSION[EElevatorPermission.ServiceModeEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlToggleEdit],
            PERMISSION[EElevatorPermission.RemoteServiceControlEdit],
            PERMISSION[EElevatorPermission.LocalServiceControlEdit],
            PERMISSION[EElevatorPermission.FullHandoffToggleEdit],
            PERMISSION[EElevatorPermission.ServiceRoutinesEdit],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.TechnicalParametersEdit],
            PERMISSION[EElevatorPermission.DeviceCreate]
        ]
    },
    [EServiceCompanyRole.Editor]: {
        key: EServiceCompanyRole.Editor,
        entityType: EEntityType.ServiceCompany,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsGeneralEdit],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsEdit],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesEdit],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingEdit],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.ProfileEdit],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.SiteCreate],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesEdit
            ],
            PERMISSION[EServiceCompanyPermission.StatsView],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.ElevatorGroupCreate],
            PERMISSION[ESitePermission.ElevatorGroupEdit],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.ElevatorCreate],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView],
            PERMISSION[EElevatorPermission.DeviceCreate]
        ]
    },
    [EServiceCompanyRole.Viewer]: {
        key: EServiceCompanyRole.Viewer,
        entityType: EEntityType.ServiceCompany,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[EServiceCompanyPermission.SettingsGeneralView],
            PERMISSION[EServiceCompanyPermission.SettingsDocumentsView],
            PERMISSION[EServiceCompanyPermission.SettingsNotificationsView],
            PERMISSION[EServiceCompanyPermission.SettingsFeatureModulesView],
            PERMISSION[EServiceCompanyPermission.SettingsBillingView],
            PERMISSION[EServiceCompanyPermission.ProfileView],
            PERMISSION[EServiceCompanyPermission.TeamView],
            PERMISSION[EServiceCompanyPermission.SiteList],
            PERMISSION[
                EServiceCompanyPermission.TechnicalParametersTemplatesView
            ],
            PERMISSION[EServiceCompanyPermission.StatsView],

            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.TeamView],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },

    [ESiteRole.Owner]: {
        key: ESiteRole.Owner,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: true,
        permissions: [
            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.TeamInheritanceEdit],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],
            PERMISSION[EElevatorGroupPermission.TeamInheritanceEdit],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.TeamInheritanceEdit],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },
    [ESiteRole.Administrator]: {
        key: ESiteRole.Administrator,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.TeamEdit],
            PERMISSION[ESitePermission.TeamCreate],
            PERMISSION[ESitePermission.TeamDelete],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],
            PERMISSION[EElevatorGroupPermission.TeamEdit],
            PERMISSION[EElevatorGroupPermission.TeamCreate],
            PERMISSION[EElevatorGroupPermission.TeamDelete],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.TeamEdit],
            PERMISSION[EElevatorPermission.TeamCreate],
            PERMISSION[EElevatorPermission.TeamDelete],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },
    [ESiteRole.Editor]: {
        key: ESiteRole.Editor,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsGeneralEdit],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsNotificationsEdit],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsFeatureModulesEdit],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.SettingsBillingEdit],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.ProfileEdit],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.ProfileEdit],
            PERMISSION[EElevatorGroupPermission.TeamView],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.ProfileEdit],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.KpisCustomEdit],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },
    [ESiteRole.Viewer]: {
        key: ESiteRole.Viewer,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: false,
        permissions: [
            PERMISSION[ESitePermission.SettingsGeneralView],
            PERMISSION[ESitePermission.SettingsNotificationsView],
            PERMISSION[ESitePermission.SettingsFeatureModulesView],
            PERMISSION[ESitePermission.SettingsBillingView],
            PERMISSION[ESitePermission.ProfileView],
            PERMISSION[ESitePermission.TeamView],
            PERMISSION[ESitePermission.StatsView],

            PERMISSION[EElevatorGroupPermission.ProfileView],
            PERMISSION[EElevatorGroupPermission.TeamView],

            PERMISSION[EElevatorPermission.ProfileView],
            PERMISSION[EElevatorPermission.TeamView],
            PERMISSION[EElevatorPermission.FloorsConfigView],
            PERMISSION[EElevatorPermission.TechnicalParametersView]
        ]
    },
    [ESiteRole.TechnicalEditor]: {
        key: ESiteRole.TechnicalEditor,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [ESiteRole.TechnicalViewer]: {
        key: ESiteRole.TechnicalViewer,
        entityType: EEntityType.Site,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },

    [EElevatorGroupRole.Editor]: {
        key: EElevatorGroupRole.Editor,
        entityType: EEntityType.ElevatorGroup,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorGroupRole.Viewer]: {
        key: EElevatorGroupRole.Viewer,
        entityType: EEntityType.ElevatorGroup,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorGroupRole.TechnicalEditor]: {
        key: EElevatorGroupRole.TechnicalEditor,
        entityType: EEntityType.ElevatorGroup,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorGroupRole.TechnicalViewer]: {
        key: EElevatorGroupRole.TechnicalViewer,
        entityType: EEntityType.ElevatorGroup,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },

    [EElevatorRole.Editor]: {
        key: EElevatorRole.Editor,
        entityType: EEntityType.Elevator,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorRole.Viewer]: {
        key: EElevatorRole.Viewer,
        entityType: EEntityType.Elevator,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorRole.TechnicalEditor]: {
        key: EElevatorRole.TechnicalEditor,
        entityType: EEntityType.Elevator,
        readOnly: true,
        systemOnly: false,
        permissions: []
    },
    [EElevatorRole.TechnicalViewer]: {
        key: EElevatorRole.TechnicalViewer,
        entityType: EEntityType.Elevator,
        readOnly: true,
        systemOnly: false,
        permissions: []
    }
} as const satisfies TStrictRoleDescriptors;

/**
 * The list of all possible member attributes at the Service Company level.
 */
enum EServiceCompanyMemberAttribute {
    Technician = 'mcal-member-attributes-service-company-technician'
}

/**
 * Represents any member attribute.
 */
type TMemberAttribute = EServiceCompanyMemberAttribute;

/**
 * Associates each member attribute with the corresponding entity type that can assign it.
 */
interface IMemberAttributeMap {
    [EEntityType.Platform]: never;
    [EEntityType.ServiceCompany]: EServiceCompanyMemberAttribute;
    [EEntityType.Site]: never;
    [EEntityType.ElevatorGroup]: never;
    [EEntityType.Elevator]: never;
}

/**
 * The type of entities to which users can be invited, thus providing affinity
 * to any assignment those users get on other sub-entities on behalf of the
 * same entity.
 */
type TAffinityProvider =
    | EEntityType.Platform
    | EEntityType.ServiceCompany
    | EEntityType.Site;

/**
 * The type of entities to which a user can be added, without an invitation,
 * by having affinity to another entity.
 */
type TAffinityReceiver =
    | EEntityType.Site
    | EEntityType.ElevatorGroup
    | EEntityType.Elevator;

/**
 * Way to identify an affinity provider by its type or ID.
 */
type TAffinity = TAffinityProvider | string;

/**
 * Boundaries are limits to the affinity inheritance. They are used to prevent
 * the propagation of affinities through certain entities.
 */
interface IBoundary {
    entityId: string;
    entityType: TAffinityProvider;
}

/**
 * Is any affinity providing access to an entity, after considering boundaries,
 * but before considering delegations.
 */
interface IGrant {
    entityId: string;
    entityType: TAffinityProvider;
}

/**
 * Represents an affinity provider.
 */
interface IAffinity {
    entityId: string;
    entityType: TAffinityProvider;
}

/**
 * Is an affinity providing access to an entity, after considering boundaries,
 * specifying if it was delegated or not.
 */
interface IAffinityAccessDescriptor {
    entityId: string;
    entityType: TAffinityProvider;
    isDelegated: boolean;
}

/**
 * The affinity types that are delegated from other affinity providers.
 */
interface IAffinityDelegations {
    [EEntityType.Platform]: EEntityType.ServiceCompany | EEntityType.Site;
    [EEntityType.ServiceCompany]: EEntityType.Site;
    [EEntityType.Site]: never;
}

/**
 * The affinity types that are delegated from other affinity providers.
 */
const AFFINITY_DELEGATIONS: Record<TAffinityProvider, TAffinityProvider[]> = {
    [EEntityType.Platform]: [EEntityType.ServiceCompany, EEntityType.Site],
    [EEntityType.ServiceCompany]: [EEntityType.Site],
    [EEntityType.Site]: []
};

/**
 * The affinity providers for each entity type.
 */
interface IAffinitiesMap {
    [EEntityType.Platform]: EEntityType.Platform;
    [EEntityType.ServiceCompany]: EEntityType.ServiceCompany;
    [EEntityType.Site]: EEntityType.ServiceCompany | EEntityType.Site;
    [EEntityType.ElevatorGroup]: EEntityType.ServiceCompany | EEntityType.Site;
    [EEntityType.Elevator]: EEntityType.ServiceCompany | EEntityType.Site;
}

/**
 * The affinity providers for each entity type.
 */
const AFFINITIES_MAP: Record<EEntityType, TAffinityProvider[]> = {
    [EEntityType.Platform]: [EEntityType.Platform],
    [EEntityType.ServiceCompany]: [EEntityType.ServiceCompany],
    [EEntityType.Site]: [EEntityType.ServiceCompany, EEntityType.Site],
    [EEntityType.ElevatorGroup]: [EEntityType.ServiceCompany, EEntityType.Site],
    [EEntityType.Elevator]: [EEntityType.ServiceCompany, EEntityType.Site]
};

/**
 * A Role assigned to a user, with the affinity that provides it.
 */
interface IUserAssignment<T extends EEntityType> {
    userId: string;
    entityId: string;
    entityType: T;
    affinityId: string;
    affinityType: TAffinityProvider;
    role: IRolesMap[T];
    attributes: IMemberAttributeMap[T][];
    affiliationStatus: EAffiliationStatus;
}

/**
 * Represents the status of a user's affiliation with an entity.
 */
enum EAffiliationStatus {
    Active = 'mcal-affiliation-status-active',
    Inactive = 'mcal-affiliation-status-inactive',
    Pending = 'mcal-affiliation-status-pending'
}

/**
 * Represents the participation of a user within a team.
 */
interface ITeamMembership<T extends EEntityType> {
    userId: string;
    affiliationStatus: EAffiliationStatus;
    role: IRolesMap[T];
    affinityId: string;
    affinityType: TAffinityProvider;
    attributes: IMemberAttributeMap[T][];
}

/**
 * A request to create and invitation/invite to join a team.
 */
interface ITeamInviteRequest<T extends TAffinityProvider> {
    entityId: string;
    entityType: T;
    userEmail: string;
    role: IRolesMap[T];
    requestedAt: number;
}

/**
 * An invitation/invite to join a team.
 */
interface ITeamInvite<T extends TAffinityProvider> {
    inviteId: string;
    entityId: string;
    entityType: T;
    userId: string | null;
    userEmail: string;
    role: IRolesMap[T];
    requestedAt: number;
}

/**
 * An invitation/invite to join a team, associated with a registered user.
 */
interface ITeamInviteAssigned<T extends TAffinityProvider>
    extends ITeamInvite<T> {
    userId: string;
}

/**
 * Represents the result of an invitation.
 */
enum EInviteResult {
    Approved = 'approved',
    Rejected = 'rejected'
}

/**
 * Summarizes an invitation for entity's list of invites.
 */
interface IEntityInviteEntry<T extends TAffinityProvider> {
    inviteId: string;
    userEmail: string;
    role: IRolesMap[T];
    requestedAt: number;
}

/**
 * Summarizes an invitation for user's list of invites.
 */
interface IUserInviteEntry<T extends TAffinityProvider> {
    inviteId: string;
    entityId: string;
    entityType: T;
    role: IRolesMap[T];
    requestedAt: number;
}

/**
 * Used to specify the assignment of a user in an entity during invite process.
 */
interface IUserInviteAssignment<T extends TAffinityProvider> {
    userEmail: string;
    role: IRolesMap[T];
}

/**
 * Indicates entity types that can be parents of each entity type.
 */
interface IParentsMap {
    [EEntityType.Elevator]: EEntityType.ElevatorGroup;
    [EEntityType.ElevatorGroup]: EEntityType.Site;
    [EEntityType.Site]: EEntityType.ServiceCompany;
    [EEntityType.ServiceCompany]: EEntityType.Platform;
    [EEntityType.Platform]: never;
}

/**
 * Indicates entity types that can be ancestors of each entity type.
 */
interface IAncestorsMap {
    [EEntityType.Elevator]:
        | EEntityType.ElevatorGroup
        | EEntityType.Site
        | EEntityType.ServiceCompany
        | EEntityType.Platform;
    [EEntityType.ElevatorGroup]:
        | EEntityType.Site
        | EEntityType.ServiceCompany
        | EEntityType.Platform;
    [EEntityType.Site]: EEntityType.ServiceCompany | EEntityType.Platform;
    [EEntityType.ServiceCompany]: EEntityType.Platform;
    [EEntityType.Platform]: never;
}

/**
 * Indicates entity types that can be children of each entity type.
 */
interface IChildrenMap {
    [EEntityType.Elevator]: never;
    [EEntityType.ElevatorGroup]: EEntityType.Elevator;
    [EEntityType.Site]: EEntityType.ElevatorGroup;
    [EEntityType.ServiceCompany]: EEntityType.Site;
    [EEntityType.Platform]: EEntityType.ServiceCompany;
}

/**
 * Identifies an entity for linking/relation purposes.
 */
interface IEntityLink<T extends EEntityType> {
    entityId: string;
    entityType: T;
}

/**
 * The base of any entity.
 */
interface IEntity<T extends EEntityType> {
    entityId: string;
    entityType: T;
    entityStatus: EEntityStatus;
    /** @deprecated Get from context instead. */
    parentId: string;
    /** @deprecated Use denormalized form (e.g., IDDBTeamMembership) instead. */
    team: ITeamMembership<T>[];
}

/**
 * Represents a single entity and its relations.
 */
interface IEntityRelationNode<T extends EEntityType> {
    entityId: string;
    entityType: T;
    parentId: string;
    boundaries: T extends TAffinityReceiver ? IBoundary[] : never[];
}

interface IEntityTrace<T extends EEntityType> {
    node: IEntityRelationNode<T>;
    parents: T extends unknown
        ? IEntityRelationNode<IAncestorsMap[T]>[]
        : never;
}

const ENTITY_TRACE_ORDER = [
    EEntityType.Elevator,
    EEntityType.ElevatorGroup,
    EEntityType.Site,
    EEntityType.ServiceCompany,
    EEntityType.Platform
] as const;

const ENTITY_TRACE_LENGTH = {
    [EEntityType.Elevator]: 4,
    [EEntityType.ElevatorGroup]: 3,
    [EEntityType.Site]: 2,
    [EEntityType.ServiceCompany]: 1,
    [EEntityType.Platform]: 0
} as const;

/**
 * Indicates the hierarchy of roles within each entity type.
 */
const ROLES_HIERARCHY: Record<EEntityType, TRole[]> = {
    [EEntityType.Platform]: [
        EPlatformRole.Owner,
        EPlatformRole.Administrator,
        EPlatformRole.Editor,
        EPlatformRole.Viewer
    ],
    [EEntityType.ServiceCompany]: [
        EServiceCompanyRole.Owner,
        EServiceCompanyRole.Administrator,
        EServiceCompanyRole.Editor,
        EServiceCompanyRole.Viewer
    ],
    [EEntityType.Site]: [
        ESiteRole.Owner,
        ESiteRole.Administrator,
        ESiteRole.Editor,
        ESiteRole.Viewer,
        ESiteRole.TechnicalEditor,
        ESiteRole.TechnicalViewer
    ],
    [EEntityType.ElevatorGroup]: [
        EElevatorGroupRole.Editor,
        EElevatorGroupRole.Viewer,
        EElevatorGroupRole.TechnicalEditor,
        EElevatorGroupRole.TechnicalViewer
    ],
    [EEntityType.Elevator]: [
        EElevatorRole.Editor,
        EElevatorRole.Viewer,
        EElevatorRole.TechnicalEditor,
        EElevatorRole.TechnicalViewer
    ]
};

/**
 * A descriptor of a role's assignment during permissions resolution process.
 */
interface IRoleTraceItem {
    entityId: string;
    entityType: EEntityType;
    affinityId: string;
    affinityType: string;
    role: TRole;
    isOwner: boolean;
    isBound: boolean;
}

/**
 * The result of permissions resolution process.
 */
interface IResolvedPermissions {
    permissions: TPermission[];
    grants: IGrant[];
    access: IAffinityAccessDescriptor[];
    isOwner: boolean;
    isPlatformMember: boolean;
    trace: IRoleTraceItem[][];
}

/**
 * Used to specify the assignment of a user in an entity during add process.
 */
interface IUserAddAssignment<T extends TAffinityReceiver> {
    userId: string;
    role: IRolesMap[T];
}

export type {
    IAffinitiesMap,
    IAffinity,
    IAffinityAccessDescriptor,
    IAffinityDelegations,
    IAncestorsMap,
    IBoundary,
    IChildrenMap,
    IEntity,
    IEntityInviteEntry,
    IEntityLink,
    IEntityRelationNode,
    IEntityTrace,
    IGrant,
    IMemberAttributeMap,
    IParentsMap,
    IPermissionDescriptor,
    IPermissionsMap,
    IResolvedPermissions,
    IRoleDescriptor,
    IRoleTraceItem,
    IRolesMap,
    ITeamInvite,
    ITeamInviteAssigned,
    ITeamInviteRequest,
    ITeamMembership,
    IUserAddAssignment,
    IUserAssignment,
    IUserInviteAssignment,
    IUserInviteEntry,
    TAffinity,
    TAffinityProvider,
    TAffinityReceiver,
    TMemberAttribute,
    TPermission,
    TRole,
    TStrictPermissionDescriptors,
    TStrictRoleDescriptors
};

export {
    AFFINITIES_MAP,
    AFFINITY_DELEGATIONS,
    EAffiliationStatus,
    EElevatorGroupPermission,
    EElevatorGroupRole,
    EElevatorPermission,
    EElevatorRole,
    EEntityStatus,
    EEntityType,
    EIndividualStatus,
    EIndividualType,
    EInviteResult,
    ENTITY_TRACE_LENGTH,
    ENTITY_TRACE_ORDER,
    EPlatformPermission,
    EPlatformRole,
    EServiceCompanyMemberAttribute,
    EServiceCompanyPermission,
    EServiceCompanyRole,
    ESitePermission,
    ESiteRole,
    PERMISSION,
    PERMISSIONS_MAP,
    ROLE,
    ROLES_HIERARCHY,
    ROLES_MAP
};
