import type {
    EEntityType,
    IElevatorSummary,
    IEntity,
    IPlatformStatsAuth,
    IPlatformStatsElevators,
    IPlatformStatsServiceCompanies,
    IPlatformStatsSites,
    IResolvedPermissions,
    IServiceCompanyPendingRecord,
    IServiceCompanySummary,
    ISiteSummary,
    ITechnicianPendingRecord,
    ITechnicianProfile,
    IUpgradePackage,
    IUpgradePackageSummary,
    IUserSummary,
    TMileageDensityKPI
} from '@mcal/core';
import {createSelector} from '@reduxjs/toolkit';
import type {ITeamMember} from '../../defines/platform.types.js';
import type {
    IPartialState,
    TEntitySliceStatus
} from '../../defines/redux.types.js';
import {composeTeam} from '../../utils/compose-team/compose-team.js';
import type {IPlatformSliceState} from './platform.state.js';

const platformSelector = (state: IPartialState): IPlatformSliceState => {
    if (!state.platform) {
        throw new Error('Platform slice is not available.');
    }

    return state.platform;
};

const selectPlatformEntity = createSelector(
    [platformSelector],
    (platform): IEntity<EEntityType.Platform> => {
        return platform.platformEntity;
    }
);

const selectPlatformStatus = createSelector(
    [platformSelector],
    (platform): TEntitySliceStatus => {
        return platform.status;
    }
);

const selectElevators = createSelector(
    [platformSelector],
    (platform): IElevatorSummary[] => {
        return platform.remotes.elevators.current;
    }
);

const selectSites = createSelector(
    [platformSelector],
    (platform): ISiteSummary[] => {
        return platform.remotes.sites.current;
    }
);

const selectServiceCompanies = createSelector(
    [platformSelector],
    (platform): IServiceCompanySummary[] => {
        return platform.remotes.serviceCompanies.current;
    }
);

const selectPendingServiceCompanies = createSelector(
    [platformSelector],
    (platform): IServiceCompanySummary[] => {
        return platform.remotes.pendingServiceCompanies.current;
    }
);

const selectServiceCompaniesPendingRecords = createSelector(
    [platformSelector],
    (platform): IServiceCompanyPendingRecord[] => {
        return platform.remotes.serviceCompaniesPendingRecords.current;
    }
);

const selectMileageDensity = createSelector(
    [platformSelector],
    (platform): TMileageDensityKPI | null => {
        return platform.remotes.kpis.current.mileageDensity;
    }
);

const selectUsers = createSelector(
    [platformSelector],
    (platform): IUserSummary[] | null => {
        return platform.remotes.users.current;
    }
);

const selectTechnicians = createSelector(
    [platformSelector],
    (platform): ITechnicianProfile[] => {
        return platform.remotes.technicians.current;
    }
);

const selectPendingTechnicians = createSelector(
    [platformSelector],
    (platform): ITechnicianProfile[] => {
        return platform.remotes.pendingTechnicians.current;
    }
);

const selectPendingTechniciansRecord = createSelector(
    [platformSelector],
    (platform): ITechnicianPendingRecord[] => {
        return platform.remotes.techniciansPendingRecord.current;
    }
);

const selectServiceCompaniesStats = createSelector(
    [platformSelector],
    (platform): IPlatformStatsServiceCompanies => {
        return platform.remotes.serviceCompaniesStats.current;
    }
);

const selectSitesStats = createSelector(
    [platformSelector],
    (platform): IPlatformStatsSites => {
        return platform.remotes.sitesStats.current;
    }
);

const selectElevatorsStats = createSelector(
    [platformSelector],
    (platform): IPlatformStatsElevators => {
        return platform.remotes.elevatorsStats.current;
    }
);

const selectAuthStats = createSelector(
    [platformSelector],
    (platform): IPlatformStatsAuth => {
        return platform.remotes.authStats.current;
    }
);

const selectTeams = createSelector(
    [platformSelector],
    (platform): ITeamMember<EEntityType.Platform>[] => {
        return composeTeam({
            team: platform.remotes.team.current,
            memberships: platform.remotes.memberships.current,
            invites: platform.remotes.invites.current
        });
    }
);

const selectPlatFormPermissions = createSelector(
    [platformSelector],
    (platform): IResolvedPermissions => {
        return platform.remotes.permissions.current;
    }
);

const selectUpgrades = createSelector(
    [platformSelector],
    (platform): IUpgradePackageSummary[] => {
        return platform.remotes.upgrades.current;
    }
);

const selectUpgrade = createSelector(
    [platformSelector],
    (platform): IUpgradePackage => {
        return platform.remotes.upgrade.current;
    }
);

export {
    selectAuthStats,
    selectElevators,
    selectElevatorsStats,
    selectMileageDensity,
    selectPendingServiceCompanies,
    selectPendingTechnicians,
    selectPendingTechniciansRecord,
    selectPlatFormPermissions,
    selectPlatformEntity,
    selectPlatformStatus,
    selectServiceCompanies,
    selectServiceCompaniesPendingRecords,
    selectServiceCompaniesStats,
    selectSites,
    selectSitesStats,
    selectTeams,
    selectTechnicians,
    selectUpgrade,
    selectUpgrades,
    selectUsers
};
