import type {
    TCurrency,
    TDecimalSeparator,
    TLocale,
    TMeasureSystem,
    TTimeZone
} from '../core/formats.js';
import {
    defaultChronosVariants,
    defaultCurrency,
    defaultDecimalSeparator,
    defaultLocale,
    defaultMeasureSystem,
    defaultTimeZone
} from '../core/formats.js';
import type {IAuthFlow, IAuthMFA} from './auth.js';
import type {IAddress, TThemePack, TThemeType} from './common.js';
import {defaultThemePack, defaultThemeType} from './common.js';
import {EIndividualStatus} from './permissions.js';

enum EUserCapabilities {
    Technician = 'mcal-user-capabilities-technician'
}

enum ETechnicianProfileAttributes {
    PlatformTechnician = 'mcal-technician-profile-attributes-platform-technician',
    Hidden = 'mcal-technician-profile-attributes-hidden'
}

enum ETechnicianProfileReviewResult {
    Approved = 'approved',
    Rejected = 'rejected'
}

interface IUserSummary {
    userId: string;
    userStatus: EIndividualStatus;
    email: string;
    phoneNumber: string | null;
    capabilities: EUserCapabilities[];
    firstName: string | null;
    lastName: string | null;
    photo: string | null;
    birthday: number | null;
}

const defaultUserSummary: IUserSummary = {
    userId: '',
    userStatus: EIndividualStatus.Pending,
    email: '',
    phoneNumber: null,
    capabilities: [],
    firstName: null,
    lastName: null,
    photo: null,
    birthday: null
};

interface IUserProfile {
    userId: string;
    firstName: string | null;
    lastName: string | null;
    photo: string | null;
    birthday: number | null;
}

const defaultUserProfile: IUserProfile = {
    userId: '',
    firstName: null,
    lastName: null,
    photo: null,
    birthday: null
};

interface IUserGeneralSettings {
    userId: string;
    locale: TLocale;
    currency: TCurrency;
    timeZone: TTimeZone;
    longDate: string;
    shortDate: string;
    longTime: string;
    shortTime: string;
    fullChronos: string;
    decimalSeparator: TDecimalSeparator;
    measureSystem: TMeasureSystem;
    themePack: TThemePack;
    themeType: TThemeType;
}

const defaultUserGeneralSettings: IUserGeneralSettings = {
    userId: '',
    locale: defaultLocale,
    currency: defaultCurrency,
    timeZone: defaultTimeZone,
    longDate: defaultChronosVariants.longDate,
    shortDate: defaultChronosVariants.shortDate,
    longTime: defaultChronosVariants.longTime,
    shortTime: defaultChronosVariants.shortTime,
    fullChronos: defaultChronosVariants.fullChronos,
    decimalSeparator: defaultDecimalSeparator,
    measureSystem: defaultMeasureSystem,
    themePack: defaultThemePack,
    themeType: defaultThemeType
};

interface IUserAuthSettings {
    userId: string;
    flows: IAuthFlow[];
    mfa: IAuthMFA[];
}

const defaultUserAuthSettings: IUserAuthSettings = {
    userId: '',
    flows: [],
    mfa: []
};

interface IUserTripSettings {
    userId: string;
    disableFrequentTrack: boolean;
}

const defaultUserTripSettings: IUserTripSettings = {
    userId: '',
    disableFrequentTrack: false
};

interface ITechnicianProfile {
    technicianProfileId: string;
    technicianProfileStatus: EIndividualStatus;
    userId: string;
    fiscalId: string;
    fiscalName: string;
    photo: string | null;
    address: IAddress;
    standardEmail: string | null;
    standardPhoneNumber: string | null;
    emergencyEmail: string | null;
    emergencyPhoneNumber: string | null;
    attributes: ETechnicianProfileAttributes[];
    createdAt: number;
    updatedAt: number;
}

interface ITechnicianPendingRecord {
    pendingYear: number;
    pendingMonth: number;
    pendingCount: number;
}

interface ITechnicianPendingRecord {
    pendingYear: number;
    pendingMonth: number;
    pendingCount: number;
}

const defaultTechnicianProfile: ITechnicianProfile = {
    technicianProfileId: '',
    technicianProfileStatus: EIndividualStatus.Pending,
    userId: '',
    fiscalId: '',
    fiscalName: '',
    photo: null,
    address: {
        state: null,
        country: null,
        city: null,
        street: null,
        number: null,
        zip: null,
        lat: null,
        lng: null
    },
    standardEmail: null,
    standardPhoneNumber: null,
    emergencyEmail: null,
    emergencyPhoneNumber: null,
    attributes: [],
    createdAt: 0,
    updatedAt: 0
};

interface IUserFull {
    summary: IUserSummary;
    profile: IUserProfile;
    generalSettings: IUserGeneralSettings;
    authSettings: IUserAuthSettings;
    tripSettings: IUserTripSettings;
}

interface IUserEmailRecord {
    userId: string;
    email: string;
}

interface IUserMembership {
    userId: string;
    email: string;
    firstName: string | null;
    lastName: string | null;
    photo: string | null;
    capabilities: EUserCapabilities[];
}

interface IPlatformStatsAuth {
    totalUsers: number;
    totalTechnicians: number;
}

export type {
    IPlatformStatsAuth,
    ITechnicianPendingRecord,
    ITechnicianProfile,
    IUserAuthSettings,
    IUserEmailRecord,
    IUserFull,
    IUserGeneralSettings,
    IUserMembership,
    IUserProfile,
    IUserSummary,
    IUserTripSettings
};
export {
    ETechnicianProfileAttributes,
    ETechnicianProfileReviewResult,
    EUserCapabilities,
    defaultTechnicianProfile,
    defaultUserAuthSettings,
    defaultUserGeneralSettings,
    defaultUserProfile,
    defaultUserSummary,
    defaultUserTripSettings
};
