import type {IFlexBaseProps} from '@mcal/core-react';
import {styled} from '../../utils/styled/styled.js';
import {Box} from '../box/box.js';

interface IStyledRoot {
    display: NonNullable<IFlexBaseProps<never>['display']>;
    flexDirection: NonNullable<IFlexBaseProps<never>['flexDirection']>;
    flexWrap: NonNullable<IFlexBaseProps<never>['flexWrap']>;
    justifyContent: NonNullable<IFlexBaseProps<never>['justifyContent']>;
    alignItems: NonNullable<IFlexBaseProps<never>['alignItems']>;
    alignContent: NonNullable<IFlexBaseProps<never>['alignContent']>;
    order: NonNullable<IFlexBaseProps<never>['order']>;
    flexGrow: NonNullable<IFlexBaseProps<never>['flexGrow']>;
    flexShrink: NonNullable<IFlexBaseProps<never>['flexShrink']>;
    flexBasis: NonNullable<IFlexBaseProps<never>['flexBasis']>;
    alignSelf: NonNullable<IFlexBaseProps<never>['alignSelf']>;
    gap: NonNullable<IFlexBaseProps<never>['gap']>;
    $height?: string;
    $width?: string;
}

const StyledRoot = styled(Box)<IStyledRoot>(
    ({
        display,
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        alignContent,
        order,
        flexGrow,
        flexShrink,
        flexBasis,
        alignSelf,
        gap,
        $height = '',
        $width = ''
    }) => ({
        display,
        flexDirection,
        flexWrap,
        justifyContent,
        alignItems,
        alignContent,
        order,
        flexGrow,
        flexShrink,
        flexBasis,
        alignSelf,
        gap,
        height: $height,
        width: $width
    })
);

export {StyledRoot};
