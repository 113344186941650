import type {
    TAPIResponseSuccess,
    TAssignmentsGetAPI,
    TPermissionsGetAPI
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {getState} from '../../../utils/get-state/get-state.js';
import * as services from './permissions.services.js';

const getPermissions = createAsyncThunk<
    TAPIResponseSuccess<TPermissionsGetAPI>,
    TAPIAsyncThunkArgs<TPermissionsGetAPI>,
    TAPIAsyncThunkConfig<TPermissionsGetAPI>
>('gateway/permissions', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPermissions(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    entityId: args.entityId,
                    affinity: args.affinity
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getAssignments = createAsyncThunk<
    TAPIResponseSuccess<TAssignmentsGetAPI>,
    TAPIAsyncThunkArgs<TAssignmentsGetAPI>,
    TAPIAsyncThunkConfig<TAssignmentsGetAPI>
>('gateway/assignments', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getAssignments(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {getAssignments, getPermissions};
