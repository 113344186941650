import type {
    TAPIRequestContent,
    TAPIResponse,
    TAdminAuthPlatformStatsGetAPI,
    TAdminElevatorsPlatformStatsGetAPI,
    TAdminServiceCompaniesPlatformStatsGetAPI,
    TAdminSitesPlatformStatsGetAPI,
    TPlatformTeamDeleteAPI,
    TPlatformTeamGetAPI,
    TPlatformTeamUpdateAPI,
    TUpgradePackageAdminGetAPI,
    TUpgradePackageDeprecate,
    TUpgradePackageGetAPI,
    TUpgradePackageList,
    TUpgradePackagePromote,
    TUpgradePackageUnpublish,
    TUpgradePackageUpload
} from '@mcal/core';

import {request} from '../../../utils/request/request.js';

const getPlatformTeam = (
    {query}: TAPIRequestContent<TPlatformTeamGetAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamGetAPI> => {
    return request<TPlatformTeamGetAPI>({
        method: 'GET',
        url: '/platform/team',
        signal,
        query
    });
};

const updatePlatformTeam = (
    {query, body}: TAPIRequestContent<TPlatformTeamUpdateAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamUpdateAPI> => {
    return request<TPlatformTeamUpdateAPI>({
        method: 'PATCH',
        url: '/platform/team',
        signal,
        query,
        body
    });
};

const deletePlatformTeam = (
    {query, body}: TAPIRequestContent<TPlatformTeamDeleteAPI>,
    signal: AbortSignal
): TAPIResponse<TPlatformTeamDeleteAPI> => {
    return request<TPlatformTeamDeleteAPI>({
        method: 'DELETE',
        url: '/platform/team',
        signal,
        query,
        body
    });
};

const listUpgrades = (
    {query}: TAPIRequestContent<TUpgradePackageList>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageList> => {
    return request<TUpgradePackageList>({
        method: 'GET',
        url: '/upgrades/admin/list',
        signal,
        query
    });
};

const getUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackageGetAPI>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageGetAPI> => {
    return request<TUpgradePackageGetAPI>({
        method: 'GET',
        url: '/upgrades',
        signal,
        query
    });
};

const getUpgradePackageAdmin = (
    {query}: TAPIRequestContent<TUpgradePackageAdminGetAPI>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageAdminGetAPI> => {
    return request<TUpgradePackageAdminGetAPI>({
        method: 'GET',
        url: '/upgrades/admin',
        signal,
        query
    });
};

const deprecateUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackageDeprecate>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageDeprecate> => {
    return request<TUpgradePackageDeprecate>({
        method: 'POST',
        url: '/upgrades/admin/deprecate',
        signal,
        query
    });
};

const promoteUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackagePromote>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackagePromote> => {
    return request<TUpgradePackagePromote>({
        method: 'POST',
        url: '/upgrades/admin/promote',
        signal,
        query
    });
};

const uploadUpgradePackage = (
    {body, query}: TAPIRequestContent<TUpgradePackageUpload>,
    signal: AbortSignal
    // onUploadProgress:  ((progressEvent: AxiosProgressEvent) => void) | undefined
): TAPIResponse<TUpgradePackageUpload> => {
    return request<TUpgradePackageUpload>({
        method: 'PUT',
        url: '/upgrades/admin',
        body,
        signal,
        query
    });
};

const unpublishUpgradePackage = (
    {query}: TAPIRequestContent<TUpgradePackageUnpublish>,
    signal: AbortSignal
): TAPIResponse<TUpgradePackageUnpublish> => {
    return request<TUpgradePackageUnpublish>({
        method: 'DELETE',
        url: '/upgrades/admin/unpublish',
        signal,
        query
    });
};

const getPlatformServiceCompaniesStats = (
    {query}: TAPIRequestContent<TAdminServiceCompaniesPlatformStatsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminServiceCompaniesPlatformStatsGetAPI> => {
    return request<TAdminServiceCompaniesPlatformStatsGetAPI>({
        method: 'GET',
        url: '/service-companies/admin/platform-stats',
        signal,
        query
    });
};

const getPlatformSitesStats = (
    {query}: TAPIRequestContent<TAdminSitesPlatformStatsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminSitesPlatformStatsGetAPI> => {
    return request<TAdminSitesPlatformStatsGetAPI>({
        method: 'GET',
        url: '/sites/admin/platform-stats',
        signal,
        query
    });
};

const getPlatformElevatorsStats = (
    {query}: TAPIRequestContent<TAdminElevatorsPlatformStatsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminElevatorsPlatformStatsGetAPI> => {
    return request<TAdminElevatorsPlatformStatsGetAPI>({
        method: 'GET',
        url: '/elevators/admin/platform-stats',
        signal,
        query
    });
};

const getPlatformAuthStats = (
    {query}: TAPIRequestContent<TAdminAuthPlatformStatsGetAPI>,
    signal: AbortSignal
): TAPIResponse<TAdminAuthPlatformStatsGetAPI> => {
    return request<TAdminAuthPlatformStatsGetAPI>({
        method: 'GET',
        url: '/admin/platform-stats',
        signal,
        query
    });
};

export {
    deletePlatformTeam,
    deprecateUpgradePackage,
    getPlatformAuthStats,
    getPlatformElevatorsStats,
    getPlatformServiceCompaniesStats,
    getPlatformSitesStats,
    getPlatformTeam,
    getUpgradePackage,
    getUpgradePackageAdmin,
    listUpgrades,
    promoteUpgradePackage,
    unpublishUpgradePackage,
    updatePlatformTeam,
    uploadUpgradePackage
};
