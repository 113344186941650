import type {
    TAPIResponseSuccess,
    TAdminAuthPlatformStatsGetAPI,
    TAdminElevatorsPlatformStatsGetAPI,
    TAdminServiceCompaniesPlatformStatsGetAPI,
    TAdminSitesPlatformStatsGetAPI,
    TPlatformTeamDeleteAPI,
    TPlatformTeamGetAPI,
    TPlatformTeamUpdateAPI,
    TUpgradePackageAdminGetAPI,
    TUpgradePackageDeprecate,
    TUpgradePackageGetAPI,
    TUpgradePackageList,
    TUpgradePackagePromote,
    TUpgradePackageUnpublish,
    TUpgradePackageUpload
} from '@mcal/core';
import {PLATFORM_ID} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {notificationsActions} from '../../../slices/notifications/notifications.index.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUsersMemberships} from '../../app-node-auth/users/users.thunks.js';
import * as services from './platform.services.js';

const getPlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamGetAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamGetAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamGetAPI>
>('platform/getPlatformTeam', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getUsersMemberships({
                        entityId: PLATFORM_ID,
                        affinityId: PLATFORM_ID,
                        members: response.data.map(({userId}) => userId)
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updatePlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamUpdateAPI>
>('platform/updatePlatformTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updatePlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deletePlatformTeam = createAsyncThunk<
    TAPIResponseSuccess<TPlatformTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TPlatformTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TPlatformTeamDeleteAPI>
>('platform/deletePlatformTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deletePlatformTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listUpgrades = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageList>,
    TAPIAsyncThunkArgs<TUpgradePackageList>,
    TAPIAsyncThunkConfig<TUpgradePackageList>
>('platform/listUpgrades', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listUpgrades(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeFamily: args.upgradeFamily,
                    state: args.state,
                    channel: args.channel
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getUpgradePackage = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageGetAPI>,
    TAPIAsyncThunkArgs<TUpgradePackageGetAPI>,
    TAPIAsyncThunkConfig<TUpgradePackageGetAPI>
>('platform/getUpgradePackage', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getUpgradePackage(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeId: args.upgradeId,
                    family: args.family
                    // sequence: args.sequence
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getUpgradePackageAdmin = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageAdminGetAPI>,
    TAPIAsyncThunkArgs<TUpgradePackageAdminGetAPI>,
    TAPIAsyncThunkConfig<TUpgradePackageAdminGetAPI>
>('platform/getUpgradePackageAdmin', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getUpgradePackageAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeId: args.upgradeId,
                    family: args.family
                    // sequence: args.sequence
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const promoteUpgradePackage = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackagePromote>,
    TAPIAsyncThunkArgs<TUpgradePackagePromote>,
    TAPIAsyncThunkConfig<TUpgradePackagePromote>
>('platform/promoteUpgradePackage', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .promoteUpgradePackage(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeId: args.upgradeId,
                    family: args.family,
                    // sequence: args.sequence,
                    channel: args.channel
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Error while updating upgrade package.'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Upgrade package successfully update.'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deprecateUpgradePackage = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageDeprecate>,
    TAPIAsyncThunkArgs<TUpgradePackageDeprecate>,
    TAPIAsyncThunkConfig<TUpgradePackageDeprecate>
>('platform/deprecateUpgradePackage', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deprecateUpgradePackage(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeId: args.upgradeId,
                    family: args.family
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Error while deprecated upgrade package.'
                    })
                );

                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'Upgrade package successfully deprecated.'
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const uploadUpgradePackage = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageUpload>,
    TAPIAsyncThunkArgs<TUpgradePackageUpload>,
    TAPIAsyncThunkConfig<TUpgradePackageUpload>
>('platform/uploadUpgradePackage', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .uploadUpgradePackage(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: response.error.message
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const unpublishUpgradePackage = createAsyncThunk<
    TAPIResponseSuccess<TUpgradePackageUnpublish>,
    TAPIAsyncThunkArgs<TUpgradePackageUnpublish>,
    TAPIAsyncThunkConfig<TUpgradePackageUnpublish>
>('platform/unpublishUpgradePackage', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .unpublishUpgradePackage(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    upgradeId: args.upgradeId,
                    family: args.family
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getPlatformServiceCompaniesStats = createAsyncThunk<
    TAPIResponseSuccess<TAdminServiceCompaniesPlatformStatsGetAPI>,
    TAPIAsyncThunkArgs<TAdminServiceCompaniesPlatformStatsGetAPI>,
    TAPIAsyncThunkConfig<TAdminServiceCompaniesPlatformStatsGetAPI>
>('platform/getPlatformStats', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformServiceCompaniesStats(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getPlatformSitesStats = createAsyncThunk<
    TAPIResponseSuccess<TAdminSitesPlatformStatsGetAPI>,
    TAPIAsyncThunkArgs<TAdminSitesPlatformStatsGetAPI>,
    TAPIAsyncThunkConfig<TAdminSitesPlatformStatsGetAPI>
>('platform/getPlatformSitesStats', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformSitesStats(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getPlatformElevatorsStats = createAsyncThunk<
    TAPIResponseSuccess<TAdminElevatorsPlatformStatsGetAPI>,
    TAPIAsyncThunkArgs<TAdminElevatorsPlatformStatsGetAPI>,
    TAPIAsyncThunkConfig<TAdminElevatorsPlatformStatsGetAPI>
>('platform/getPlatformElevatorsStats', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformElevatorsStats(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getPlatformAuthStats = createAsyncThunk<
    TAPIResponseSuccess<TAdminAuthPlatformStatsGetAPI>,
    TAPIAsyncThunkArgs<TAdminAuthPlatformStatsGetAPI>,
    TAPIAsyncThunkConfig<TAdminAuthPlatformStatsGetAPI>
>('platform/getPlatformAuthStats', async (_, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getPlatformAuthStats(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    deletePlatformTeam,
    deprecateUpgradePackage,
    getPlatformAuthStats,
    getPlatformElevatorsStats,
    getPlatformServiceCompaniesStats,
    getPlatformSitesStats,
    getPlatformTeam,
    getUpgradePackage,
    getUpgradePackageAdmin,
    listUpgrades,
    promoteUpgradePackage,
    unpublishUpgradePackage,
    updatePlatformTeam,
    uploadUpgradePackage
};
