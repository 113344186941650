import {
    EElevatorMotionSystem,
    EElevatorOverallStatus,
    EElevatorType,
    EPremiseBackupPowerStatus,
    EPremiseConnectivityStatus,
    EPremiseMainPowerStatus,
    EPremisePowerSourceStatus
} from '@mcal/core';
import type {TStatus} from './common.types.js';

const ElevatorOperationModeMap: Record<EElevatorOverallStatus, TStatus> = {
    [EElevatorOverallStatus.Normal]: 'normal',
    [EElevatorOverallStatus.Service]: 'service',
    [EElevatorOverallStatus.Emergency]: 'emergency',
    [EElevatorOverallStatus.Running]: 'running',
    [EElevatorOverallStatus.Busy]: 'busy',
    [EElevatorOverallStatus.Positioning]: 'positioning',
    [EElevatorOverallStatus.Faulty]: 'faulty',
    [EElevatorOverallStatus.Setup]: 'setup',
    [EElevatorOverallStatus.Inactive]: 'inactive',
    [EElevatorOverallStatus.Rebooting]: 'rebooting',
    [EElevatorOverallStatus.Resetting]: 'resetting',
    [EElevatorOverallStatus.Upgrading]: 'upgrading',
    [EElevatorOverallStatus.Offline]: 'offline',
    [EElevatorOverallStatus.Unknown]: 'unknown'
};

const ElevatorTypeMap = {
    [EElevatorType.Passenger]: 'passenger',
    [EElevatorType.Freight]: 'freight',
    [EElevatorType.Service]: 'service'
};

const ElevatorTypeReversedMap = {
    passenger: EElevatorType.Passenger,
    freight: EElevatorType.Freight,
    service: EElevatorType.Service
};

const ElevatorMotorSystemMap = {
    [EElevatorMotionSystem.Hydraulic]: 'hydraulic',
    [EElevatorMotionSystem.Traction]: 'traction'
};

const ElevatorMotorSystemReversedMap = {
    hydraulic: EElevatorMotionSystem.Hydraulic,
    traction: EElevatorMotionSystem.Traction
};

const PremiseConectivityStatusMap: {
    [EPremiseConnectivityStatus.Normal]: 'normal';
    [EPremiseConnectivityStatus.Faulty]: 'faulty';
    [EPremiseConnectivityStatus.Unknown]: 'unknown';
} = {
    [EPremiseConnectivityStatus.Normal]: 'normal',
    [EPremiseConnectivityStatus.Faulty]: 'faulty',
    [EPremiseConnectivityStatus.Unknown]: 'unknown'
};

const PremiseMainPowerStatusMap: {
    [EPremiseMainPowerStatus.Normal]: 'normal';
    [EPremiseMainPowerStatus.Faulty]: 'faulty';
    [EPremiseMainPowerStatus.Unknown]: 'unknown';
} = {
    [EPremiseMainPowerStatus.Normal]: 'normal',
    [EPremiseMainPowerStatus.Faulty]: 'faulty',
    [EPremiseMainPowerStatus.Unknown]: 'unknown'
};

const PremiseBackupPowerStatusMap: {
    [EPremiseBackupPowerStatus.Normal]: 'normal';
    [EPremiseBackupPowerStatus.Faulty]: 'faulty';
    [EPremiseBackupPowerStatus.Unknown]: 'unknown';
} = {
    [EPremiseBackupPowerStatus.Normal]: 'normal',
    [EPremiseBackupPowerStatus.Faulty]: 'faulty',
    [EPremiseBackupPowerStatus.Unknown]: 'unknown'
};

const PremisePowerSourceMap: {
    [EPremisePowerSourceStatus.Backup]: 'backup';
    [EPremisePowerSourceStatus.Main]: 'main';
    [EPremisePowerSourceStatus.Unknown]: 'unknown';
} = {
    [EPremisePowerSourceStatus.Backup]: 'backup',
    [EPremisePowerSourceStatus.Main]: 'main',
    [EPremisePowerSourceStatus.Unknown]: 'unknown'
};

export {
    ElevatorMotorSystemMap,
    ElevatorMotorSystemReversedMap,
    ElevatorOperationModeMap,
    ElevatorTypeMap,
    ElevatorTypeReversedMap,
    PremiseBackupPowerStatusMap,
    PremiseConectivityStatusMap,
    PremiseMainPowerStatusMap,
    PremisePowerSourceMap
};
