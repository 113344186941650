import {getPermissions} from '../../services/app-gateway/permissions/permissions.thunks.js';
import {
    getPendingTechniciansAdmin,
    getTechniciansAdmin,
    getUsersAdmin,
    listPendingTechniciansRecordsAdmin,
    reviewTechnicianAdmin
} from '../../services/app-node-auth/users/users.thunks.js';
import {listEntityInvites} from '../../services/app-node-invites/invites/invites.thunks.js';
import {
    deletePlatformTeam,
    deprecateUpgradePackage,
    getPlatformAuthStats,
    getPlatformElevatorsStats,
    getPlatformServiceCompaniesStats,
    getPlatformSitesStats,
    getPlatformTeam,
    getUpgradePackage,
    getUpgradePackageAdmin,
    listUpgrades,
    promoteUpgradePackage,
    unpublishUpgradePackage,
    updatePlatformTeam,
    uploadUpgradePackage
} from '../../services/app-node-platform/platform/platform.thunks.js';
import {
    listPendingServiceCompanies,
    listServiceCompaniesPendingRecords,
    reviewServiceCompany
} from '../../services/app-node-service-companies/service-companies/service-companies.thunks.js';
import {
    selectAuthStats,
    selectElevators,
    selectElevatorsStats,
    selectMileageDensity,
    selectPendingServiceCompanies,
    selectPendingTechnicians,
    selectPendingTechniciansRecord,
    selectPlatFormPermissions,
    selectPlatformEntity,
    selectPlatformStatus,
    selectServiceCompanies,
    selectServiceCompaniesPendingRecords,
    selectServiceCompaniesStats,
    selectSites,
    selectSitesStats,
    selectTeams,
    selectTechnicians,
    selectUpgrade,
    selectUpgrades,
    selectUsers
} from './platform.selectors.js';
import {platformSlice} from './platform.slice.js';

const platformSelectors = {
    selectAuthStats,
    selectElevators,
    selectElevatorsStats,
    selectMileageDensity,
    selectPendingServiceCompanies,
    selectPendingTechnicians,
    selectPendingTechniciansRecord,
    selectPlatFormPermissions,
    selectPlatformEntity,
    selectPlatformStatus,
    selectServiceCompanies,
    selectServiceCompaniesPendingRecords,
    selectServiceCompaniesStats,
    selectSites,
    selectSitesStats,
    selectTeams,
    selectTechnicians,
    selectUpgrade,
    selectUpgrades,
    selectUsers
};

const platformThunks = {
    deletePlatformTeam,
    deprecateUpgradePackage,
    getPendingTechniciansAdmin,
    getPermissions,
    getPlatformAuthStats,
    getPlatformElevatorsStats,
    getPlatformServiceCompaniesStats,
    getPlatformSitesStats,
    getPlatformTeam,
    getTechniciansAdmin,
    getUpgradePackage,
    getUpgradePackageAdmin,
    getUsersAdmin,
    listEntityInvites,
    listPendingServiceCompanies,
    listPendingTechniciansRecordsAdmin,
    listServiceCompaniesPendingRecords,
    listUpgrades,
    promoteUpgradePackage,
    reviewServiceCompany,
    reviewTechnicianAdmin,
    unpublishUpgradePackage,
    updatePlatformTeam,
    uploadUpgradePackage
};

export {platformSelectors, platformSlice, platformThunks};
