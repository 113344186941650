import type {
    TAPIResponseSuccess,
    TAdminServiceCompanyPendingReviewAPI,
    TServiceCompaniesGetConfigurationsTemplateAPI,
    TServiceCompaniesListAPI,
    TServiceCompaniesListConfigurationsTemplateAPI,
    TServiceCompaniesPendingListAPI,
    TServiceCompaniesPendingRecordListAPI,
    TServiceCompaniesRegisterAPI,
    TServiceCompaniesSearchAPI,
    TServiceCompaniesUpdateConfigurationTemplateAPI,
    TServiceCompaniesUploadConfigurationsTemplateAPI,
    TServiceCompaniesUploadDocumentsAPI,
    TServiceCompanyGetAPI,
    TServiceCompanyGetStateAPI,
    TServiceCompanyGetStatsAPI,
    TServiceCompanyTeamDeleteAPI,
    TServiceCompanyTeamGetAPI,
    TServiceCompanyTeamUpdateAPI,
    TServiceCompanyUpdateGeneralSettingsAPI,
    TServiceCompanyUpdateProfileAPI
} from '@mcal/core';
import {
    EEntityType,
    EServiceCompanyReviewResult,
    PLATFORM_ID
} from '@mcal/core';
import {createAsyncThunk} from '@reduxjs/toolkit';
import type {
    TAPIAsyncThunkArgs,
    TAPIAsyncThunkConfig
} from '../../../defines/redux.types.js';
import {notificationsActions} from '../../../slices/notifications/notifications.index.js';
import {getState} from '../../../utils/get-state/get-state.js';
import {getUsersMemberships} from '../../app-node-auth/users/users.thunks.js';
import * as services from './service-companies.services.js';

const listServiceCompanies = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesListAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesListAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesListAPI>
>('serviceCompanies/listServiceCompanies', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listServiceCompanies(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    scope: EEntityType.Platform,
                    target: PLATFORM_ID
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listServiceCompaniesAdmin = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesSearchAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesSearchAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesSearchAPI>
>('serviceCompanies/listServiceCompaniesAdmin', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listServiceCompaniesAdmin(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    fiscalName: query.fiscalName
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listPendingServiceCompanies = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesPendingListAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesPendingListAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesPendingListAPI>
>('serviceCompanies/listPendingServiceCompanies', async (query, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .listPendingServiceCompanies(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    scope: EEntityType.Platform,
                    target: PLATFORM_ID,
                    year: query.year,
                    month: query.month
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listServiceCompaniesPendingRecords = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesPendingRecordListAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesPendingRecordListAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesPendingRecordListAPI>
>(
    'serviceCompanies/listServiceCompaniesPendingRecords',
    async (query, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .listServiceCompaniesPendingRecords(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale,
                        scope: query.scope,
                        target: query.target
                    }
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    return thunkAPI.rejectWithValue(response);
                } else {
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const getServiceCompany = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyGetAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyGetAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyGetAPI>
>('serviceCompanies/getServiceCompany', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getServiceCompany(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    serviceCompanyId: args.serviceCompanyId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getServiceCompanyState = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyGetStateAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyGetStateAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyGetStateAPI>
>('serviceCompanies/getServiceCompanyState', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getServiceCompanyState(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    serviceCompanyId: args.serviceCompanyId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateServiceCompanyGeneralSettings = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyUpdateGeneralSettingsAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyUpdateGeneralSettingsAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyUpdateGeneralSettingsAPI>
>(
    'serviceCompanies/updateServiceCompanyGeneralSettings',
    async (args, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .updateServiceCompanyGeneralSettings(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale
                    },
                    body: {data: {...args}}
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    void thunkAPI.dispatch(
                        notificationsActions.send({
                            title: 'Error',
                            message: 'Failed to update service company settings'
                        })
                    );
                    return thunkAPI.rejectWithValue(response);
                } else {
                    void thunkAPI.dispatch(
                        notificationsActions.send({
                            title: 'Success',
                            message:
                                'Service company settings updated successfully'
                        })
                    );
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const updateServiceCompanyProfile = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyUpdateProfileAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyUpdateProfileAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyUpdateProfileAPI>
>('serviceCompanies/updateServiceCompanyProfile', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateServiceCompanyProfile(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {data: {...args}}
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update user settings'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Success',
                        message: 'User settings updated successfully'
                    })
                );
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const listServiceCompanyConfigurationsTemplates = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesListConfigurationsTemplateAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesListConfigurationsTemplateAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesListConfigurationsTemplateAPI>
>(
    'serviceCompanies/listServiceCompanyConfigurationsTemplates',
    async (args, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .listServiceCompanyConfigurationsTemplates(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale,
                        serviceCompanyId: args.serviceCompanyId
                    }
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    return thunkAPI.rejectWithValue(response);
                } else {
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const getServiceCompanyTechnicalParametersTemplate = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesGetConfigurationsTemplateAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesGetConfigurationsTemplateAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesGetConfigurationsTemplateAPI>
>(
    'serviceCompanies/getServiceCompanyTechnicalParametersTemplate',
    async (args, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .getServiceCompanyTechnicalParametersTemplate(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale,
                        templateId: args.templateId,
                        serviceCompanyId: args.serviceCompanyId,
                        download: args.download
                    }
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    return thunkAPI.rejectWithValue(response);
                } else {
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const createServiceCompanyTechnicalParametersTemplates = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesUploadConfigurationsTemplateAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesUploadConfigurationsTemplateAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesUploadConfigurationsTemplateAPI>
>(
    'serviceCompanies/createServiceCompanyTechnicalParametersTemplates',
    async (args, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .createServiceCompanyTechnicalParametersTemplates(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale
                    },
                    body: {data: {...args}}
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    return thunkAPI.rejectWithValue(response);
                } else {
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const updateServiceCompanyTechnicalParametersTemplates = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesUpdateConfigurationTemplateAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesUpdateConfigurationTemplateAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesUpdateConfigurationTemplateAPI>
>(
    'serviceCompanies/updateServiceCompanyTechnicalParametersTemplates',
    async (args, thunkAPI) => {
        const user = getState(thunkAPI, 'user');

        return await services
            .updateServiceCompanyTechnicalParametersTemplates(
                {
                    query: {
                        locale: user.remotes.userGeneralSettings.current.locale
                    },
                    body: {data: {...args}}
                },
                thunkAPI.signal
            )
            .then((response) => {
                if (response.error) {
                    return thunkAPI.rejectWithValue(response);
                } else {
                    return thunkAPI.fulfillWithValue(response);
                }
            });
    }
);

const getServiceCompanyTeam = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyTeamGetAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyTeamGetAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyTeamGetAPI>
>('serviceCompanies/getServiceCompanyTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getServiceCompanyTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    serviceCompanyId: args.serviceCompanyId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                void thunkAPI.dispatch(
                    getUsersMemberships({
                        entityId: args.serviceCompanyId,
                        affinityId: args.serviceCompanyId,
                        members: response.data.map(({userId}) => userId)
                    })
                );

                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const updateServiceCompanyTeam = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyTeamUpdateAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyTeamUpdateAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyTeamUpdateAPI>
>('serviceCompanies/updateServiceCompanyTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .updateServiceCompanyTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        serviceCompanyId: args.serviceCompanyId,
                        userId: args.userId,
                        role: args.role
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const deleteServiceCompanyTeam = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyTeamDeleteAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyTeamDeleteAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyTeamDeleteAPI>
>('serviceCompanies/deleteServiceCompanyTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .deleteServiceCompanyTeam(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        serviceCompanyId: args.serviceCompanyId,
                        userId: args.userId
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const registerServiceCompany = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesRegisterAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesRegisterAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesRegisterAPI>
>('serviceCompanies/registerServiceCompany', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .registerServiceCompany(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const reviewServiceCompany = createAsyncThunk<
    TAPIResponseSuccess<TAdminServiceCompanyPendingReviewAPI>,
    TAPIAsyncThunkArgs<TAdminServiceCompanyPendingReviewAPI>,
    TAPIAsyncThunkConfig<TAdminServiceCompanyPendingReviewAPI>
>('serviceCompanies/deleteServiceCompanyTeam', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .reviewServiceCompany(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: {
                    data: {
                        serviceCompanyId: args.serviceCompanyId,
                        result: args.result
                    }
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                void thunkAPI.dispatch(
                    notificationsActions.send({
                        title: 'Error',
                        message: 'Failed to update user settings'
                    })
                );
                return thunkAPI.rejectWithValue(response);
            } else {
                if (args.result === EServiceCompanyReviewResult.Approved) {
                    void thunkAPI.dispatch(
                        notificationsActions.send({
                            title: 'Success',
                            message: 'Service company approved successfully'
                        })
                    );
                } else {
                    void thunkAPI.dispatch(
                        notificationsActions.send({
                            title: 'Success',
                            message: 'Service company rejected successfully'
                        })
                    );
                }
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const uploadServiceCompanyDocuments = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompaniesUploadDocumentsAPI>,
    TAPIAsyncThunkArgs<TServiceCompaniesUploadDocumentsAPI>,
    TAPIAsyncThunkConfig<TServiceCompaniesUploadDocumentsAPI>
>('serviceCompanies/uploadServiceCompanyDocuments', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .uploadServiceCompanyDocuments(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale
                },
                body: args
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

const getStats = createAsyncThunk<
    TAPIResponseSuccess<TServiceCompanyGetStatsAPI>,
    TAPIAsyncThunkArgs<TServiceCompanyGetStatsAPI>,
    TAPIAsyncThunkConfig<TServiceCompanyGetStatsAPI>
>('serviceCompanies/getStats', async (args, thunkAPI) => {
    const user = getState(thunkAPI, 'user');

    return await services
        .getStats(
            {
                query: {
                    locale: user.remotes.userGeneralSettings.current.locale,
                    serviceCompanyId: args.serviceCompanyId
                }
            },
            thunkAPI.signal
        )
        .then((response) => {
            if (response.error) {
                return thunkAPI.rejectWithValue(response);
            } else {
                return thunkAPI.fulfillWithValue(response);
            }
        });
});

export {
    createServiceCompanyTechnicalParametersTemplates,
    deleteServiceCompanyTeam,
    getServiceCompany,
    getServiceCompanyState,
    getServiceCompanyTeam,
    getServiceCompanyTechnicalParametersTemplate,
    getStats,
    listPendingServiceCompanies,
    listServiceCompanies,
    listServiceCompaniesAdmin,
    listServiceCompaniesPendingRecords,
    listServiceCompanyConfigurationsTemplates,
    registerServiceCompany,
    reviewServiceCompany,
    updateServiceCompanyGeneralSettings,
    updateServiceCompanyProfile,
    updateServiceCompanyTeam,
    updateServiceCompanyTechnicalParametersTemplates,
    uploadServiceCompanyDocuments
};
