import type {
    IElevatorSummary,
    IEntity,
    IEntityInviteEntry,
    IPlatformStatsAuth,
    IPlatformStatsElevators,
    IPlatformStatsServiceCompanies,
    IPlatformStatsSites,
    IResolvedPermissions,
    IServiceCompanyPendingRecord,
    IServiceCompanySummary,
    ISiteSummary,
    ITeamMembership,
    ITechnicianPendingRecord,
    ITechnicianProfile,
    IUpgradePackage,
    IUpgradePackageSummary,
    IUserMembership,
    IUserSummary,
    TFloorDistributionKPI,
    TMileageDensityKPI,
    TMileageTotalKPI
} from '@mcal/core';
import {
    EEntityStatus,
    EEntityType,
    EUpgradeChannel,
    EUpgradeState,
    PLATFORM_ID
} from '@mcal/core';

import type {ISliceRemote} from '../../defines/redux.types.js';
import {createRemote} from '../../utils/create-remote/create-remote.js';

type TPlatformSliceStatus = 'INIT' | 'IDLE' | 'LOADING' | 'FAILED';

type TTeam = ITeamMembership<EEntityType.Platform>[];

type TInvites = IEntityInviteEntry<EEntityType.Platform>[];

interface IPlatformSliceState {
    status: TPlatformSliceStatus;
    platformEntity: IEntity<EEntityType.Platform>;
    remotes: {
        sites: ISliceRemote<ISiteSummary[]>;
        serviceCompanies: ISliceRemote<IServiceCompanySummary[]>;
        pendingServiceCompanies: ISliceRemote<IServiceCompanySummary[]>;
        serviceCompaniesPendingRecords: ISliceRemote<
            IServiceCompanyPendingRecord[]
        >;
        upgrade: ISliceRemote<IUpgradePackage>;
        upgrades: ISliceRemote<IUpgradePackageSummary[]>;
        elevators: ISliceRemote<IElevatorSummary[]>;
        team: ISliceRemote<TTeam>;
        memberships: ISliceRemote<IUserMembership[]>;
        technicians: ISliceRemote<ITechnicianProfile[]>;
        pendingTechnicians: ISliceRemote<ITechnicianProfile[]>;
        techniciansPendingRecord: ISliceRemote<ITechnicianPendingRecord[]>;
        invites: ISliceRemote<TInvites>;
        kpis: ISliceRemote<{
            mileageTotal: TMileageTotalKPI | null;
            mileageDensity: TMileageDensityKPI | null;
            floorDistribution: TFloorDistributionKPI | null;
        }>;
        users: ISliceRemote<IUserSummary[]>;
        serviceCompaniesStats: ISliceRemote<IPlatformStatsServiceCompanies>;
        sitesStats: ISliceRemote<IPlatformStatsSites>;
        elevatorsStats: ISliceRemote<IPlatformStatsElevators>;
        authStats: ISliceRemote<IPlatformStatsAuth>;
        permissions: ISliceRemote<IResolvedPermissions>;
    };
}

const initialState = {
    status: 'INIT',
    platformEntity: {
        entityId: PLATFORM_ID,
        entityType: EEntityType.Platform,
        entityStatus: EEntityStatus.Active,
        team: [],
        parentId: PLATFORM_ID
    },
    remotes: {
        sites: createRemote([]),
        serviceCompanies: createRemote([]),
        pendingServiceCompanies: createRemote([]),
        serviceCompaniesPendingRecords: createRemote([]),
        elevators: createRemote([]),
        upgrade: createRemote({
            changelogId: '0',
            family: '',
            sequence: 0,
            publicationTimestamp: null,
            channel: EUpgradeChannel.None,
            status: EUpgradeState.Draft,
            createdAt: '0',
            updatedAt: '0',
            counter: [],
            upgradeId: '',
            content: [],
            issues: []
        }),
        upgrades: createRemote([]),
        team: createRemote([]),
        invites: createRemote([]),
        memberships: createRemote([]),
        technicians: createRemote([]),
        pendingTechnicians: createRemote([]),
        techniciansPendingRecord: createRemote([]),
        kpis: createRemote({
            mileageTotal: null,
            mileageDensity: null,
            floorDistribution: null
        }),
        users: createRemote([]),
        serviceCompaniesStats: createRemote({
            totalServiceCompanies: 0
        }),
        sitesStats: createRemote({
            totalSites: 0
        }),
        elevatorsStats: createRemote({
            totalElevatorGroups: 0,
            totalElevators: 0,
            totalElevatorEmergencies: 0,
            connectivityElevatorsOnline: 0,
            connectivityElevatorsOffline: 0,
            operationElevatorsNormal: 0,
            operationElevatorsService: 0,
            operationElevatorsSetup: 0,
            healthElevatorsHealthy: 0,
            healthElevatorsFaulty: 0
        }),
        authStats: createRemote({
            totalUsers: 0,
            totalTechnicians: 0,
            totalAdmins: 0
        }),
        permissions: createRemote({
            permissions: [],
            grants: [],
            access: [],
            isOwner: false,
            isPlatformMember: false,
            trace: []
        })
    }
} satisfies IPlatformSliceState as IPlatformSliceState;

export type {IPlatformSliceState, TInvites, TPlatformSliceStatus, TTeam};
export {initialState};
