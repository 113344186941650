import type {IConsole, IConsoleDispatchOptions, ILog} from '@mcal/core';

interface IArgs {
    str: string[];
    other: unknown[];
}

const browserConsole: IConsole = {
    dispatch(log: ILog, {styles}: IConsoleDispatchOptions) {
        const fn = console[log.method] || console.log;

        const colors = `color: ${log.color}; backgroundColor: ${log.backgroundColor}`;

        const args: IArgs = {
            str: [],
            other: []
        };

        log.message.forEach((message) => {
            if (typeof message === 'string') {
                args.str.push(message);
            } else {
                args.str.push('[object]');
                args.other.push(message);
                args.other.push('\n\n');
            }
        });

        if (styles) {
            return fn.bind(
                null,
                ...[
                    '%c' +
                        log.tag +
                        '%c' +
                        log.caller +
                        '%c' +
                        args.str.join(' '),
                    colors + ' font-family: monospace; white-space: pre;',
                    'color: #888888; font-family: monospace; white-space: pre;',
                    'color: #aaaaaa; font-family: monospace; white-space: pre;',
                    ...args.other
                ]
            );
        } else {
            return fn.bind(
                null,
                log.tag + log.caller + args.str.join(' '),
                ...args.other
            );
        }
    }
};

export {browserConsole};
