import type {EEntityType, TPermission} from '@mcal/core';
import {PermissionUtils} from '@mcal/core';
import type {ILink} from '@mcal/core-react';
import {usePermissions, useSelector, userSelectors} from '@mcal/core-react';
import type {FC} from 'react';
import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {Menu} from '../../../../components/menu/menu.js';
import {RouteWrapper} from '../../../../components/route-wrapper/route-wrapper.js';
import {SpinnerStore} from '../../../../contexts/spinner-store/spinner-store.js';
import {StyledOutletContainer, StyledRoot} from './menu-wrapper.styles.js';

interface IMenuLink extends ILink {
    permissions: TPermission[];
}

interface ILinkContainer {
    entityType:
        | EEntityType.Platform
        | EEntityType.ServiceCompany
        | EEntityType.Site
        | null;
    links: IMenuLink[];
}

interface IMenuWrapperProps {
    linksContainer: ILinkContainer;
}

const MenuWrapper: FC<IMenuWrapperProps> = ({linksContainer}) => {
    const isLogged = useSelector(userSelectors.selectUserIsLogged);
    const navigate = useNavigate();
    const {permissions} = usePermissions(linksContainer.entityType);

    useEffect(() => {
        if (!isLogged) {
            navigate('/log-in');
        }
    });

    const content = (
        <StyledRoot>
            <Menu
                links={linksContainer.links.filter((link) => {
                    return PermissionUtils.hasPermission(
                        permissions,
                        link.permissions,
                        'some'
                    );
                })}
            />
            <SpinnerStore component={StyledOutletContainer} suspense={true}>
                <Outlet />
            </SpinnerStore>
        </StyledRoot>
    );

    if (linksContainer.entityType) {
        return (
            <RouteWrapper entityType={linksContainer.entityType}>
                {content}
            </RouteWrapper>
        );
    }

    return content;
};

export type {ILinkContainer, IMenuLink, IMenuWrapperProps};
export {MenuWrapper};
