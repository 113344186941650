import type {IAddress, ITemplate} from './common.js';
import type {
    IConfigurationTemplate,
    IGetConfigurationTemplate
} from './elevator.js';
import {defaultConfigurationTemplate} from './elevator.js';
import type {IEntity} from './permissions.js';
import {EEntityStatus, EEntityType} from './permissions.js';

enum EServiceCompanyStatus {
    Normal = 'normal',
    Emergency = 'emergency'
}

enum EServiceCompanySize {
    S1 = '1-10',
    S2 = '11-50',
    S3 = '51-200',
    S4 = '201-500',
    S5 = '501-1000',
    S6 = '1001-5000',
    S7 = '5001-10000',
    S8 = '10001+'
}

enum EServiceCompanyReviewResult {
    Approved = 'approved',
    Rejected = 'rejected'
}

type TServiceCompanyScope = EEntityType.Platform | EEntityType.ServiceCompany;

interface IServiceCompanySummary extends IEntity<EEntityType.ServiceCompany> {
    status: EServiceCompanyStatus;
    logo: string | null;
    address: IAddress;
    fiscalId: string;
    fiscalName: string;
    createdAt: number;
    updatedAt: number;
}

const defaultServiceCompanySummary: IServiceCompanySummary = {
    entityId: '',
    entityType: EEntityType.ServiceCompany,
    entityStatus: EEntityStatus.Pending,
    parentId: '',
    team: [],
    status: EServiceCompanyStatus.Normal,
    logo: null,
    address: {
        state: null,
        country: null,
        city: null,
        street: null,
        number: null,
        zip: null,
        lat: null,
        lng: null
    },
    fiscalId: '',
    fiscalName: '',
    createdAt: 0,
    updatedAt: 0
};

interface IServiceCompanyState {
    serviceCompanyId: string;
    status: EServiceCompanyStatus;
}

const defaultServiceCompanyState: IServiceCompanyState = {
    serviceCompanyId: '',
    status: EServiceCompanyStatus.Normal
};

interface IServiceCompanyProfile {
    serviceCompanyId: string;
    fiscalName: string;
    fiscalId: string;
    description: string | null;
    banner: string | null;
    logo: string | null;
    favicon: string | null;
    address: IAddress;
    size: EServiceCompanySize;
    standardEmail: string | null;
    standardPhoneNumber: string | null;
    emergencyEmail: string | null;
    emergencyPhoneNumber: string | null;
}

const defaultServiceCompanyProfile: IServiceCompanyProfile = {
    serviceCompanyId: '',
    fiscalName: '',
    description: null,
    fiscalId: '',
    banner: null,
    logo: null,
    favicon: null,
    address: {
        country: null,
        city: null,
        state: null,
        street: null,
        number: null,
        zip: null,
        lat: null,
        lng: null
    },
    size: EServiceCompanySize.S1,
    standardEmail: null,
    standardPhoneNumber: null,
    emergencyEmail: null,
    emergencyPhoneNumber: null
};

interface IServiceCompanyGeneralSettings {
    serviceCompanyId: string;
    customColor: string | null;
}

const defaultServiceCompanyGeneralSettings: IServiceCompanyGeneralSettings = {
    serviceCompanyId: '',
    customColor: null
};

interface IServiceCompanyTemplate extends IConfigurationTemplate {
    serviceCompanyId: string;
}

type TTechnicalParametersTemplate = ITemplate<
    EEntityType.ServiceCompany,
    IConfigurationTemplate
>;

type TGetTechnicalParametersTemplate = ITemplate<
    EEntityType.ServiceCompany,
    IGetConfigurationTemplate
>;

const defaultServiceCompanyTemplate: TTechnicalParametersTemplate = {
    owner: '',
    ownerType: EEntityType.ServiceCompany,
    name: '',
    templateId: '',
    contentType: '',
    content: defaultConfigurationTemplate
};

const defaultServiceCompanyTemplates: TTechnicalParametersTemplate[] = [
    defaultServiceCompanyTemplate
];

interface IServiceCompanyFull {
    summary: IServiceCompanySummary;
    state: IServiceCompanyState;
    profile: IServiceCompanyProfile;
    generalSettings: IServiceCompanyGeneralSettings;
}

interface IServiceCompanyPendingRecord {
    pendingYear: number;
    pendingMonth: number;
    pendingCount: number;
}

interface IServiceCompanyStats {
    totalSites: number;
    totalElevators: number;
    totalElevatorEmergencies: number;

    // USED FOR "Connectivity" STATS
    connectivityElevatorsOnline: number;
    connectivityElevatorsOffline: number;

    // USED FOR "Operation" STATS
    operationElevatorsNormal: number;
    operationElevatorsService: number;
    operationElevatorsSetup: number;

    // USED FOR "Health" STATS
    healthElevatorsHealthy: number;
    healthElevatorsFaulty: number;
}

interface IPlatformStatsServiceCompanies {
    totalServiceCompanies: number;
}

export type {
    IPlatformStatsServiceCompanies,
    IServiceCompanyFull,
    IServiceCompanyGeneralSettings,
    IServiceCompanyPendingRecord,
    IServiceCompanyProfile,
    IServiceCompanyState,
    IServiceCompanyStats,
    IServiceCompanySummary,
    IServiceCompanyTemplate,
    TGetTechnicalParametersTemplate,
    TServiceCompanyScope,
    TTechnicalParametersTemplate
};
export {
    EServiceCompanyReviewResult,
    EServiceCompanySize,
    EServiceCompanyStatus,
    defaultServiceCompanyGeneralSettings,
    defaultServiceCompanyProfile,
    defaultServiceCompanyState,
    defaultServiceCompanySummary,
    defaultServiceCompanyTemplate,
    defaultServiceCompanyTemplates
};
